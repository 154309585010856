import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';

export type AppointmentStatus = NewAppointmentStatusRequest & Auditable;

export type NewAppointmentStatusRequest = {
  name: string;
  notes?: string;
  shortCode: string;
  color: string;
  emoji: string;
  decrementRemainingAppointments: boolean;
  triggerSmsReminder: boolean;
  isNote: boolean;
  isNonCompliance: boolean;
};

export const useGetAppointmentStatuses = () => {
  const { computeClient: client } = useApiClient();
  return useQuery(['appointment-status'], () =>
    client.get<AppointmentStatus[]>('/appointment-status').then(response => response.data),
  );
};

export const useGetAppointmentStatus = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['appointment-status', id], () =>
    client.get<AppointmentStatus>(`/appointment-status/${id}`).then(response => response.data),
  );
};

export const useCreateAppointmentStatus = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<AppointmentStatus, AxiosError, NewAppointmentStatusRequest>({
    mutationFn: async function (request: NewAppointmentStatusRequest) {
      const response = await client.post('/appointment-status', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['appointment-status']);
    },
  });
};

export const useUpdateAppointmentStatus = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<AppointmentStatus, AxiosError, AppointmentStatus>({
    mutationFn: request => client.put('/appointment-status', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['appointment-status']);
    },
  });
};

export const useDeleteAppointmentStatus = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/appointment-status/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['appointment-status']);
    },
  });
};
