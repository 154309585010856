import React, { Fragment } from 'react';
import { MultifieldOutput } from '../../../components/Forms/FieldHook';
import { FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { RepresentationRange } from './RepresentationRange';
import { SectionDivider } from '../../../components/PatientForm/SectionDivider';
import { RepresentationTimeRange } from '../../../api/RepresentationApi';
import classNames from 'classnames';
import { format } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';

export type DynamicRepresentationGroupInputProps = {
  ranges: MultifieldOutput;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  clearErrors: () => void;
};

export const DynamicRepresentationGroupInput = ({
  ranges,
  register,
  watch,
  setValue,
  clearErrors,
  getValues,
}: DynamicRepresentationGroupInputProps) => {
  const representationRef = React.createRef<HTMLDivElement>();

  const onAddRangeClick = () => {
    // set the value of the last range end date to now. Get via index.
    const index = ranges.fields.length - 1;
    const now = format(new Date(), 'yyyy-MM-dd');
    const yesterday = format(new Date(new Date().setDate(new Date().getDate() - 1)), 'yyyy-MM-dd');
    const lastValue = getValues(`ranges[${index}].end`);
    if (!lastValue) {
      setValue(`ranges[${index}].end`, yesterday);
    }
    const newRange: RepresentationTimeRange = {
      start: now,
      end: null,
      notes: '',
      lawFirmId: null,
      lawOfficeId: null,
      attorneyId: null,
      paralegalId: null,
    };
    ranges.append(newRange);
  };

  const onRemoveRangeClick = (index: number) => {
    clearErrors();
    ranges.remove(index);
  };

  return (
    <>
      <div ref={representationRef} className="space-y-2">
        {ranges.fields.map((field, index) => (
          <Fragment key={index}>
            <div className={classNames('flex flex-row w-full space-x-2  p-2 hover:bg-blue-300', index % 2 ? 'bg-gray-100' : '')}>
              <div className="grow">
                <RepresentationRange ranges={ranges} watch={watch} register={register} index={index} setValue={setValue} />
              </div>
              <div className="flex flex-row items-center">
                <FontAwesomeIcon
                  className="text-red-600 cursor-pointer ml-2 mt-4 hover:text-red-800 hover:scale-110 group"
                  icon={faTrashCan}
                  onClick={() => onRemoveRangeClick(index)}
                />
              </div>
            </div>
            {index < ranges.fields.length - 2 && (
              <div className="mt-2">
                <SectionDivider text="Then" />
              </div>
            )}
            {index === ranges.fields.length - 2 && (
              <div className="mt-2">
                <SectionDivider text="Currently" />
              </div>
            )}
          </Fragment>
        ))}
      </div>

      <div className="py-2">
        <div className="flex flex-row">
          <button
            type="button"
            onClick={onAddRangeClick}
            className="mr-2 whitespace-nowrap rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Representation
          </button>
        </div>
      </div>
    </>
  );
};
