import { createContext, useContext } from 'react';
import React from 'react';
import { ScheduleType, useGetScheduleTypes } from 'app/api/ScheduleTypeApi';
import { AppointmentType, useGetAppointmentTypes } from 'app/api/AppointmentTypeApi';
import { AppointmentStatus, useGetAppointmentStatuses } from 'app/api/AppointmentStatusApi';
import { ScheduleDefinition, useGetScheduleDefinitions } from 'app/api/ScheduleDefinitionApi';
import { ClinicLocation, useGetClinicLocations } from 'app/api/ClinicLocationApi';
import { Provider, useGetProviders } from 'app/api/ProviderApi';

export interface IFlexworxConfigContext {
  scheduleTypes: ScheduleType[] | null;
  appointmentTypes: AppointmentType[] | null;
  appointmentStatuses: AppointmentStatus[] | null;
  scheduleDefinitions: ScheduleDefinition[] | null;
  clinicLocations: ClinicLocation[] | null;
  providers: Provider[] | null;
  isLoading: boolean;
  isError: boolean;
  clinicLocationFromScheduleDefinitionId: (scheduleDefinitionId: string) => ClinicLocation | null;
  scheduleTypeFromScheduleDefinitionId: (scheduleDefinitionId: string) => ScheduleType | null;
  scheduleTypeFromAppointmentTypeId: (appointmentTypeId: string) => ScheduleType | null;
  appointmentStatusFromId: (appointmentStatusId: string) => AppointmentStatus | null;
  scheduleDefinitionFromId: (scheduleDefinitionId: string) => ScheduleDefinition | null;
  appointmentTypesFromScheduleTypeId: (scheduleTypeId: string | null) => AppointmentType[];
  getColorRgbForAppointmentStatus: (appointmentStatusId: string) => string | null;
  getColorHexForAppointmentStatus: (appointmentStatusId: string) => string | null;
  hexToRgb: (hex: string) => string;
  appointmentTypeFromId: (appointmentTypeId: string) => AppointmentType | null;
  appointmentStatusValid: (appointmentTypeId: string, appointmentStatusId: string | null | undefined) => boolean;
  getEmojiForAppointmentStatus: (appointmentStatusId: string | undefined) => string | undefined;
  getEmojiForAppointmentType: (appointmentTypeId: string | undefined) => string | undefined;
  clinicLocationFromId: (clinicLocationId: string) => ClinicLocation | null;
  scheduleTypeFromId: (scheduleTypeId: string) => ScheduleType | null;
}

const FlexworxConfigContext = createContext<IFlexworxConfigContext>({
  scheduleTypes: null,
  appointmentTypes: null,
  appointmentStatuses: null,
  scheduleDefinitions: null,
  clinicLocations: null,
  providers: null,
  isLoading: false,
  isError: false,
  clinicLocationFromScheduleDefinitionId: () => null,
  scheduleTypeFromScheduleDefinitionId: () => null,
  scheduleTypeFromAppointmentTypeId: () => null,
  appointmentStatusFromId: () => null,
  scheduleDefinitionFromId: () => null,
  getColorRgbForAppointmentStatus: () => null,
  getColorHexForAppointmentStatus: () => null,
  appointmentTypeFromId: () => null,
  appointmentStatusValid: () => false,
  getEmojiForAppointmentStatus: () => undefined,
  getEmojiForAppointmentType: () => undefined,
  appointmentTypesFromScheduleTypeId: () => [],
  clinicLocationFromId: () => null,
  scheduleTypeFromId: () => null,
  hexToRgb: () => '',
});

export function FlexworxConfigProvider({ children }) {
  const { data: scheduleTypes, isLoading: scheduleTypesIsLoading, isError: scheduleTypesIsError } = useGetScheduleTypes();
  const {
    data: appointmentTypes,
    isLoading: appointmentTypesIsLoading,
    isError: appointmentTypesIsError,
  } = useGetAppointmentTypes();
  const {
    data: appointmentStatuses,
    isLoading: appointmentStatusesIsLoading,
    isError: appointmentStatusesIsError,
  } = useGetAppointmentStatuses();
  const {
    data: scheduleDefinitions,
    isLoading: scheduleDefinitionsIsLoading,
    isError: scheduleDefinitionsIsError,
  } = useGetScheduleDefinitions();
  const { data: clinicLocations, isLoading: clinicLocationsIsLoading, isError: clinicLocationsIsError } = useGetClinicLocations();
  const { data: providers, isLoading: providersIsLoading, isError: providersIsError } = useGetProviders();

  const clinicLocationFromScheduleDefinitionId = (scheduleDefinitionId: string) => {
    if (!clinicLocations || !scheduleDefinitions) {
      return null;
    }
    const scheduleDefinition = scheduleDefinitions.find(sd => sd.id === scheduleDefinitionId);
    if (!scheduleDefinition) {
      return null;
    }
    const clinicLocation = clinicLocations.find(cl => cl.id === scheduleDefinition.clinicLocationId);
    return clinicLocation ?? null;
  };

  const scheduleTypeFromAppointmentTypeId = (appointmentTypeId: string) => {
    if (!scheduleTypes || !appointmentTypes) {
      return null;
    }
    const appointmentType = appointmentTypes.find(at => at.id === appointmentTypeId);
    if (!appointmentType) {
      return null;
    }
    const scheduleType = scheduleTypes.find(st => st.id === appointmentType.scheduleTypeId);
    return scheduleType ?? null;
  };

  const scheduleTypeFromScheduleDefinitionId = (scheduleDefinitionId: string) => {
    if (!scheduleDefinitions || !scheduleTypes) {
      return null;
    }
    const scheduleDefinition = scheduleDefinitions.find(sd => sd.id === scheduleDefinitionId);
    if (!scheduleDefinition) {
      return null;
    }
    const scheduleType = scheduleTypes.find(st => st.id === scheduleDefinition.scheduleTypeId);
    return scheduleType ?? null;
  };

  const appointmentStatusFromId = (appointmentStatusId: string) => {
    if (!appointmentStatuses) {
      return null;
    }
    const appointmentStatus = appointmentStatuses.find(as => as.id === appointmentStatusId);
    return appointmentStatus ?? null;
  };

  const appointmentTypeFromId = (appointmentTypeId: string) => {
    if (!appointmentTypes) {
      return null;
    }
    const appointmentType = appointmentTypes.find(at => at.id === appointmentTypeId);
    return appointmentType ?? null;
  };

  const scheduleDefinitionFromId = (scheduleDefinitionId: string) => {
    if (!scheduleDefinitions) {
      return null;
    }
    const scheduleDefinition = scheduleDefinitions.find(sd => sd.id === scheduleDefinitionId);
    return scheduleDefinition ?? null;
  };

  const getColorHexForAppointmentStatus = (appointmentStatusId: string) => {
    const appointmentStatus = appointmentStatusFromId(appointmentStatusId);
    if (!appointmentStatus) {
      return null;
    }

    return appointmentStatus.color;
  };

  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgb(${r}, ${g}, ${b})`;
  };

  const getColorRgbForAppointmentStatus = (appointmentStatusId: string) => {
    const appointmentStatus = appointmentStatusFromId(appointmentStatusId);
    if (!appointmentStatus) {
      return null;
    }
    return hexToRgb(appointmentStatus.color);
  };

  // Determines if a particular appointment status is valid for a particular appointment type
  const appointmentStatusValid = (appointmentTypeId: string, appointmentStatusId: string | null | undefined) => {
    const appointmentType = appointmentTypeFromId(appointmentTypeId);
    if (!appointmentType || !appointmentStatusId) {
      return false;
    }

    return appointmentType.appointmentStatusIds.includes(appointmentStatusId);
  };

  const getEmojiForAppointmentStatus = (appointmentStatusId: string | undefined) => {
    if (!appointmentStatusId) {
      return undefined;
    }
    const appointmentStatus = appointmentStatusFromId(appointmentStatusId);
    if (!appointmentStatus) {
      return undefined;
    }
    return appointmentStatus.emoji;
  };

  const getEmojiForAppointmentType = (appointmentTypeId: string | undefined) => {
    if (!appointmentTypeId) {
      return undefined;
    }
    const appointmentType = appointmentTypeFromId(appointmentTypeId);
    if (!appointmentType) {
      return undefined;
    }
    return appointmentType.emoji;
  };

  const appointmentTypesFromScheduleTypeId = (scheduleTypeId: string | null) => {
    if (!appointmentTypes || !scheduleTypeId) {
      return [];
    }
    return appointmentTypes.filter(t => t.scheduleTypeId === scheduleTypeId);
  };

  const clinicLocationFromId = (clinicLocationId: string) => {
    if (!clinicLocations) {
      return null;
    }
    const clinicLocation = clinicLocations.find(cl => cl.id === clinicLocationId);
    return clinicLocation ?? null;
  };

  const scheduleTypeFromId = (scheduleTypeId: string) => {
    if (!scheduleTypes) {
      return null;
    }
    const scheduleType = scheduleTypes.find(st => st.id === scheduleTypeId);
    return scheduleType ?? null;
  };

  const providerValue = {
    scheduleTypes: scheduleTypes ?? null,
    appointmentTypes: appointmentTypes ?? null,
    appointmentStatuses: appointmentStatuses ?? null,
    scheduleDefinitions: scheduleDefinitions ?? null,
    clinicLocations: clinicLocations ?? null,
    providers: providers ?? null,
    isLoading:
      scheduleDefinitionsIsLoading ||
      scheduleTypesIsLoading ||
      appointmentTypesIsLoading ||
      appointmentStatusesIsLoading ||
      clinicLocationsIsLoading ||
      providersIsLoading,
    isError:
      scheduleDefinitionsIsError ||
      scheduleTypesIsError ||
      appointmentTypesIsError ||
      appointmentStatusesIsError ||
      clinicLocationsIsError ||
      providersIsError,
    clinicLocationFromScheduleDefinitionId,
    scheduleTypeFromScheduleDefinitionId,
    scheduleTypeFromAppointmentTypeId,
    appointmentStatusFromId,
    appointmentTypeFromId,
    scheduleDefinitionFromId,
    getColorRgbForAppointmentStatus,
    getColorHexForAppointmentStatus,
    appointmentStatusValid,
    getEmojiForAppointmentStatus,
    getEmojiForAppointmentType,
    appointmentTypesFromScheduleTypeId,
    clinicLocationFromId: clinicLocationFromId,
    scheduleTypeFromId: scheduleTypeFromId,
    hexToRgb,
  };

  return <FlexworxConfigContext.Provider value={providerValue}>{children}</FlexworxConfigContext.Provider>;
}

export function useFlexworxConfig() {
  return useContext(FlexworxConfigContext);
}
