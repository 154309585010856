import React, { useState } from 'react';
import { TextAreaGroup } from 'app/components/Forms/TextAreaGroup';
import { FormActionProps } from 'app/components/Forms/FormActionProps';
import { InputGroup } from 'app/components/Forms/InputGroup';
import { FieldValues, useForm, ValidationRule } from 'react-hook-form';
import useField from 'app/components/Forms/FieldHook';
import { phoneNumberRegex } from 'app/components/Forms/PhoneMaskHook';
import {
  TreatmentCenter,
  useCreateTreatmentCenter,
  useDeleteTreatmentCenter,
  useUpdateTreatmentCenter,
} from 'app/api/TreatmentCenterApi';
import { ListBoxGroup } from '../Forms/ListBoxGroup';
import { GeographicalStateOptions } from '../Forms/Data';
import { PhoneInputGroup } from '../Forms/PhoneInputGroup';
import { EnterForm } from '../Forms/EnterForm';
import { ButtonRowV2 } from '../Forms/ButtonRowV2';

export type TreatmentCenterFormParams = FormActionProps<TreatmentCenter> & {
  defaultValues?: FieldValues | undefined;
};

export function TreatmentCenterForm({ onSuccess, onUnrecoverableError, defaultValues, onDeleted }: TreatmentCenterFormParams) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });

  const isNew = !defaultValues?.id;
  const createTreatmentCenter = useCreateTreatmentCenter();
  const updateCreateTreatmentCenter = useUpdateTreatmentCenter();
  const deleteTreatmentCenter = useDeleteTreatmentCenter();

  const apiAction = isNew ? createTreatmentCenter : updateCreateTreatmentCenter;

  const onSubmit = async data => {
    const treatmentCenter = await apiAction.mutateAsync(data);
    onSuccess(treatmentCenter);
    reset();
  };

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required };
  };

  const name = useField(fieldArguments('name', 'Name', undefined, 'Name is required'));
  const notes = useField(fieldArguments('notes', 'Notes'));
  const fax = useField(fieldArguments('fax', 'Fax', { value: phoneNumberRegex, message: 'Invalid Fax Number' }));
  const phone = useField(
    fieldArguments('phone', 'Phone', {
      value: phoneNumberRegex,
      message: 'Invalid Phone Number',
    }),
  );
  const addressLine1 = useField(fieldArguments('addressLine1', 'Address Line 1'));
  const addressLine2 = useField(fieldArguments('addressLine2', 'Address Line 2'));
  const city = useField(fieldArguments('city', 'City', undefined, 'City is required'));
  const state = useField(fieldArguments('state', 'State', undefined, 'State is required'));
  const zip = useField(fieldArguments('zip', 'Zip'));

  const header = isNew ? 'Create Treatment Center' : 'Update Treatment Center';
  const submitText = isNew ? 'Create' : 'Update';

  const onDelete = async () => {
    if (defaultValues?.id) {
      await deleteTreatmentCenter.mutateAsync(defaultValues.id);
      if (onDeleted) {
        onDeleted(defaultValues as TreatmentCenter);
      }
    }
  };

  const geographicalStateInitialValue = defaultValues?.state;

  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <EnterForm
      submitForm={handleSubmit(onSubmit)}
      setError={setError}
      onUnrecoverableError={onUnrecoverableError}
      className="space-y-8 divide-y divide-gray-200"
      setDisabled={setIsDisabled}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900 italic">{header}</h3>
            <p className="mt-1 text-sm text-gray-500">Please fill out as much information as possible</p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="sm:col-span-12">
              <InputGroup {...name} type="text" placeholder="St. Mary's Hospital" />
            </div>
            <div className="sm:col-span-6">
              <PhoneInputGroup {...phone} placeholder="(901) 555-5555" />
            </div>
            <div className="sm:col-span-6">
              <PhoneInputGroup {...fax} placeholder="(555) 555-5555" />
            </div>
            <div className="sm:col-span-12">
              <InputGroup {...addressLine1} type="text" placeholder="1234 Weatherby Drive" />
            </div>
            <div className="sm:col-span-12">
              <InputGroup {...addressLine2} type="text" placeholder="Suite 33" />
            </div>
            <div className="sm:col-span-6">
              <InputGroup {...city} type="text" placeholder="Memphis" />
            </div>
            <div className="sm:col-span-3">
              <ListBoxGroup
                options={GeographicalStateOptions}
                showCheckIcon={true}
                {...state}
                initialValue={geographicalStateInitialValue}
                formKey="0"
                absolutePositioning={true}
              />
            </div>
            <div className="sm:col-span-3">
              <InputGroup {...zip} type="text" placeholder="38112" />
            </div>
            <div className="sm:col-span-12">
              <TextAreaGroup {...notes} placeholder="Any additional Info?" />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <ButtonRowV2
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            onUnrecoverableError={onUnrecoverableError}
            onSubmitText={submitText}
            onDelete={isNew ? undefined : onDelete}
          />
        </div>
      </div>
    </EnterForm>
  );
}
