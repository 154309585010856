import { Appointment } from 'app/api/AppointmentApi';
import { BusinessHours, ScheduleDefinition } from 'app/api/ScheduleDefinitionApi';
import { TemporaryNote } from 'app/api/TemporaryNoteApi';
import { QuartersInADay } from 'app/components/Forms/Data';
import { addMinutes, format } from 'date-fns';
import { buildScheduleItemKey } from '../../../utils/schedule-item-key';

export type ClosestBucket = {
  bucket: Bucket;
  timeRepresentation: TimeRepresentation;
};

export type Eventable = Appointment | TemporaryNote;

const sortTemporaryNotes = (a: TemporaryNote, b: TemporaryNote) => {
  // first sort by the header, then by body
  if ((a.header || '') < (b.header || '')) {
    return -1;
  }
  if ((a.header || '') > (b.header || '')) {
    return 1;
  }
  if (a.body < b.body) {
    return -1;
  }
  if (a.body > b.body) {
    return 1;
  }
  return 0;
};

const sortAppointments = (a: Appointment, b: Appointment) => {
  // first sort by the appointment time
  if (a.appointmentTime < b.appointmentTime) {
    return -1;
  }
  if (a.appointmentTime > b.appointmentTime) {
    return 1;
  }
  // then sort by the patient name
  if (a.patientLastName < b.patientLastName) {
    return -1;
  }
  if (a.patientLastName > b.patientLastName) {
    return 1;
  }
  return 0;
};

export const breakdownAppointmentsIntoChunks = (appointments: Appointment[], interval: 15 | 30) => {
  return breakdownAppointmentsIntoChunksHelper(appointments, interval).sort(sortAppointments);
};

export const breakdownAppointmentsIntoChunksHelper: (appointments: Appointment[], interval: 15 | 30) => Appointment[] = (
  appointments: Appointment[],
  interval: 15 | 30,
) => {
  if (!appointments.length) {
    return [];
  }
  const head = appointments[0];
  const tail = appointments.slice(1);

  if (head.durationMinutes <= interval) {
    return [head, ...breakdownAppointmentsIntoChunksHelper(tail, interval)];
  }

  const startTime = head.appointmentTime.split(':').map(x => parseInt(x));
  const startDateTime = new Date(2021, 5, 5, startTime[0], startTime[1], 0, 0);

  // split the appointment into two appointments
  const appointment1 = { ...head, durationMinutes: interval };
  const appointment2 = {
    ...head,
    appointmentTime: format(addMinutes(startDateTime, interval), 'HH:mm'),
    durationMinutes: head.durationMinutes - interval,
  };

  tail.push(appointment2);

  return [appointment1, ...breakdownAppointmentsIntoChunksHelper(tail, interval)];
};

// Returns sorted chunks of temporary notes.
export const breakdownNotesIntoChunks = (notes: TemporaryNote[], interval: 15 | 30) => {
  return breakdownNotesIntoChunksHelper(notes, interval).sort(sortTemporaryNotes);
};

const breakdownNotesIntoChunksHelper: (notes: TemporaryNote[], interval: 15 | 30) => TemporaryNote[] = (
  notes: TemporaryNote[],
  interval: 15 | 30,
) => {
  if (!notes.length) {
    return [];
  }
  const head = notes[0];
  const tail = notes.slice(1);

  // get the minutes difference between start and stop
  const noteStartTime = head.startTime.split(':').map(x => parseInt(x));
  const noteStartDateTime = new Date(2021, 5, 5, noteStartTime[0], noteStartTime[1], 0, 0);
  const noteEndTime = head.endTime.split(':').map(x => parseInt(x));
  const noteEndDateTime = new Date(2021, 5, 5, noteEndTime[0], noteEndTime[1], 0, 0);
  const minutesDifference = (noteEndDateTime.getTime() - noteStartDateTime.getTime()) / 60000;
  if (minutesDifference <= interval) {
    return [head, ...breakdownNotesIntoChunksHelper(tail, interval)];
  }

  // split the note into two notes
  const note1EndTime = addMinutes(noteStartDateTime, interval);
  const note2StartTime = addMinutes(noteStartDateTime, interval);

  const note1 = { ...head, endTime: format(note1EndTime, 'HH:mm') };
  const note2 = { ...head, startTime: format(note2StartTime, 'HH:mm') };

  tail.push(note2);

  return [note1, ...breakdownNotesIntoChunksHelper(tail, interval)];
};

export class TimeRepresentation {
  constructor(public hour: number, public minute: number) {
    this.hour = hour;
    this.minute = minute;
  }

  public stringify = () => {
    const hour = this.hour.toString().padStart(2, '0');
    const minute = this.minute.toString().padStart(2, '0');
    return `${hour}:${minute}`;
  };

  public dateify = () => new Date(2021, 5, 5, this.hour, this.minute, 0, 0);
}

export const isOpen = (dateStr: string, scheduleDefinition: ScheduleDefinition) => {
  const dateParts = dateStr.split('-');
  const date = new Date(parseInt(dateParts[0]), parseInt(dateParts[1]) - 1, parseInt(dateParts[2]));
  const businessHours = getBusinessHours(scheduleDefinition, date);
  return businessHours?.isOpen ?? false;
};

export const getContrastYIQ = (hex: string) => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

export class Bucket {
  public isAppointment = () => {
    return (this.event as Appointment)?.appointmentTime !== undefined;
  };

  public isTemporaryNote = () => {
    return (this.event as TemporaryNote)?.body !== undefined;
  };

  public asAppointment = () => {
    if (!this.isAppointment()) {
      throw new Error('Bucket is not an appointment');
    }
    return this.event as Appointment;
  };

  public asTemporaryNote = () => {
    if (!this.isTemporaryNote()) {
      throw new Error('Bucket is not a temporary note');
    }
    return this.event as TemporaryNote;
  };

  public clone = () => {
    return new Bucket(this.interval, this.offset, this.label, this.event);
  };

  public time = () => {
    const time = new Date(2021, 5, 5, this.interval.hour, this.interval.minute, 0, 0);
    return format(time, 'HH:mm');
  };

  public formattedTime = () => {
    const time = new Date(2021, 5, 5, this.interval.hour, this.interval.minute, 0, 0);
    return format(time, 'h:mm a');
  };

  public description = () => {
    if (!this.event) {
      return '';
    }

    if (this.isAppointment()) {
      const appointment = this.event as Appointment;
      return appointment.patientDisplayLongReverse;
    }
  };

  public phone1 = () => {
    if (!this.event) {
      return '';
    }
    if (this.isAppointment()) {
      const appointment = this.event as Appointment;
      return appointment.patientPhone1 ?? '';
    }
  };

  public phone2 = () => {
    if (!this.event) {
      return '';
    }
    if (this.isAppointment()) {
      const appointment = this.event as Appointment;
      return appointment.patientPhone2 ?? '';
    }
  };

  public body = () => {
    if (!this.event) {
      return '';
    }
    if (this.isTemporaryNote()) {
      const temporaryNote = this.event as TemporaryNote;
      return temporaryNote.body ?? '';
    }
  };

  public notes = () => {
    if (!this.event) {
      return '';
    }
    if (this.isAppointment()) {
      const appointment = this.event as Appointment;
      return appointment.appointmentNote ?? '';
    }
  };

  // looks like 09:30 if no appointments or 09:30:1234 with an event or 9:30:1234:1 with an event and a chunk index
  public key: string;

  constructor(
    public interval: { hour: number; minute: number },
    public offset: number,
    public label?: string,
    public event?: Eventable,
  ) {
    const hour = this.interval.hour.toString().padStart(2, '0');
    const minute = this.interval.minute.toString().padStart(2, '0');
    this.key = buildScheduleItemKey(hour, minute, this.event?.id);
  }

  // Just returns the button bucket.
  // This function is obsolete and should be removed if possible.
  // It used to serve to ensure a click on a 15 or 45 minute bucket would be considered as a click on a 00 or 30 minute bucket.
  // But that was not asked for in the design and is not necessary and requirements have changed that makes this a bad idea.
  public addButtonFor: (buckets: Bucket[], date: string, scheduleDefinition: ScheduleDefinition) => ClosestBucket | null = (
    buckets,
    date,
    scheduleDefinition,
  ) => {
    const dateParts = date.split('-').map(part => parseInt(part));
    const businessHours = getBusinessHours(scheduleDefinition, new Date(dateParts[0], dateParts[1] - 1, dateParts[2]));
    if (!businessHours.isOpen) {
      return null;
    }

    const bucketIndex = buckets.findIndex(b => b.key === this.key);
    if (bucketIndex === -1) {
      console.log(`could not find bucket ${this.key}`);
      return null;
    }

    const isWithinBusinessHours = this.isWithinBusinessHours(businessHours);

    if (!isWithinBusinessHours) {
      return null;
    }

    return {
      bucket: this,
      timeRepresentation: new TimeRepresentation(this.interval.hour, this.interval.minute),
    };
  };

  public isWithinBusinessHours = (businessHours: BusinessHours) => {
    if (!businessHours.isOpen) {
      return false;
    }
    const businessStart = new Date(2021, 5, 5, 0, 0, 0, 0); // arbitrary date
    const businessEnd = new Date(2021, 5, 5, 23, 59, 0, 0); // arbitrary date
    if (businessHours && businessHours.timeStart) {
      const [businessStartHour, businessStartMinute] = businessHours.timeStart.split(':').map(x => parseInt(x));
      businessStart.setHours(businessStartHour);
      businessStart.setMinutes(businessStartMinute);
    }
    if (businessHours && businessHours.timeEnd) {
      const [businessEndHour, businessEndMinute] = businessHours.timeEnd.split(':').map(x => parseInt(x));
      businessEnd.setHours(businessEndHour);
      businessEnd.setMinutes(businessEndMinute);
    }
    const bucketTime = new Date(2021, 5, 5, this.interval.hour, this.interval.minute, 0, 0);

    const beforeStart = bucketTime < businessStart;
    const afterEnd = bucketTime > businessEnd;

    return !beforeStart && !afterEnd;
  };

  // let's define some bucket colors...
  public focusColor: (getColorHexForAppointmentStatus: (appointmentStatusId: string) => string | null) => string =
    getColorHexForAppointmentStatus => {
      if (this.isAppointment()) {
        const hex = getColorHexForAppointmentStatus(this.asAppointment().appointmentStatusId);
        if (!hex) {
          return 'text-gray-900';
        }
        const contrast = getContrastYIQ(hex);
        if (contrast === 'black') {
          return 'text-gray-900';
        }
        return 'text-white';
      }
      return 'text-gray-900';
    };

  public muteTextColor: (getColorHexForAppointmentStatus: (appointmentStatusId: string) => string | null) => string =
    getColorHexForAppointmentStatus => {
      if (this.isAppointment()) {
        const hex = getColorHexForAppointmentStatus(this.asAppointment().appointmentStatusId);
        if (!hex) {
          return 'text-gray-500';
        }
        const contrast = getContrastYIQ(hex);

        if (contrast === 'black') {
          return 'text-gray-500';
        }
        return 'text-gray-300';
      }
      return 'text-gray-500';
    };
}

const businessHoursStart = (businessHours: BusinessHours | undefined | null) =>
  businessHours?.timeStart?.split(':').map(x => parseInt(x)) ?? [0, 0];

const businessHoursEnd = (businessHours: BusinessHours | undefined | null) =>
  businessHours?.timeEnd?.split(':').map(x => parseInt(x)) ?? [23, 59];

const calculateStartTime = (businessHours: BusinessHours | undefined | null, appointments: Appointment[] | undefined | null) => {
  const [businessStartHour, businessStartMinute] = businessHoursStart(businessHours);
  if (!appointments || appointments?.length === 0) {
    return [businessStartHour, businessStartMinute];
  }

  const businessStart = new Date(2023, 5, 5, businessStartHour, businessStartMinute, 0, 0);

  const earliestTime = appointments.reduce((acc, curr) => {
    const [eventHour, eventMinute] = curr.appointmentTime.split(':').map(x => parseInt(x));
    const eventDate = new Date(2023, 5, 5, eventHour, eventMinute, 0, 0);
    if (eventDate < acc) {
      return eventDate;
    }
    return acc;
  }, businessStart);

  return [earliestTime.getHours(), earliestTime.getMinutes()];
};

// This function is used to calculate the end time of the schedule.
// There is no padding added to this value.
const calculateEndTime = (businessHours: BusinessHours | undefined | null, appointments: Appointment[] | undefined | null) => {
  const [businessEndHour, businessEndMinute] = businessHoursEnd(businessHours);
  if (!appointments || appointments?.length === 0) {
    return [businessEndHour, businessEndMinute];
  }

  const businessEnd = new Date(2023, 5, 5, businessEndHour, businessEndMinute, 0, 0);

  const latestTime = appointments.reduce((acc, curr) => {
    const [eventHour, eventMinute] = curr.appointmentTime.split(':').map(x => parseInt(x));
    const eventDate = new Date(2023, 5, 5, eventHour, eventMinute, 0, 0);
    if (eventDate > acc) {
      return eventDate;
    }
    return acc;
  }, businessEnd);

  return [latestTime.getHours(), latestTime.getMinutes()];
};

type Interval = { label?: string; hour: number; minute: number };

// If there is an event this outside the normal business hours, it should still be displayed.
// If this is the case, we expand the schedule start and end time in order to include the event.
const scheduleTimeReducer = (
  lineIntervalMinute: 15 | 30,
  businessHours: BusinessHours | undefined | null,
  appointments: Appointment[] | undefined | null,
) => {
  const minutes = lineIntervalMinute === 15 ? [0, 15, 30, 45] : [0, 30];

  const [startHour, startMinute] = calculateStartTime(businessHours, appointments);
  const [endHour, endMinute] = calculateEndTime(businessHours, appointments);

  const startTime = new Date(2021, 1, 1, startHour, startMinute);
  const endTime = new Date(2021, 1, 1, endHour, endMinute);

  return QuartersInADay.filter(x => {
    const time = new Date(2021, 1, 1, x.hour, x.minute);
    return time >= startTime && time < endTime;
  }).reduce((acc, curr) => {
    if (minutes.includes(curr.minute)) {
      const formattedTime = format(new Date(2021, 1, 1, curr.hour, curr.minute), 'h:mm a');
      acc.push({ label: formattedTime, hour: curr.hour, minute: curr.minute });
    }
    return acc;
  }, [] as Interval[]);
};

export const getBusinessHours = (scheduleDefinition: ScheduleDefinition, date: Date) => {
  const dayOfWeek = date.getDay();
  switch (dayOfWeek) {
    case 0:
      return scheduleDefinition.sunday;
    case 1:
      return scheduleDefinition.monday;
    case 2:
      return scheduleDefinition.tuesday;
    case 3:
      return scheduleDefinition.wednesday;
    case 4:
      return scheduleDefinition.thursday;
    case 5:
      return scheduleDefinition.friday;
    case 6:
      return scheduleDefinition.saturday;
    default:
      return scheduleDefinition.sunday;
  }
};

// If there are multiple buckets in a row that describe the same event, we want to deduplicate the text so that it's not repeated.
export const dedupeBuckets = (newBuckets: Bucket[]) => {
  return newBuckets.reduce((acc, curr) => {
    const previousBucket = acc[acc.length - 1];
    const previousId = previousBucket?.event?.id;
    const currId = curr.event?.id;

    // If this bucket has no event, we don't need to deduplicate
    if (!currId) {
      acc.push(curr);
      return acc;
    }

    // If this is the first time we're seeing the ID, add it to the list
    if (!acc.some(b => b.event?.id === currId)) {
      acc.push(curr);
      return acc;
    }

    // If this bucket has the same ID as the previous bucket, clear out the body and header because it's obvious they're together.
    if (previousId === currId) {
      if (curr.isTemporaryNote()) {
        const currEvent = curr.asTemporaryNote();
        const cleanedEvent: TemporaryNote = { ...currEvent, body: '', header: '' };
        const cleanedBucket = new Bucket(curr.interval, curr.offset, curr.label, cleanedEvent);
        acc.push(cleanedBucket);
        return acc;
      } else {
        const currEvent = curr.asAppointment();
        const cleanedEvent: Appointment = {
          ...currEvent,
          patientDisplayLongReverse: '',
          patientDisplayShort: '',
          patientDisplayLong: '',
          patientDisplayShortReverse: '',
          patientPhone1: '',
          patientPhone2: '',
          appointmentNote: '',
        };
        const cleanedBucket = new Bucket(curr.interval, curr.offset, curr.label, cleanedEvent);
        acc.push(cleanedBucket);
        return acc;
      }
    }

    // If we got here, that means this is an event we've seen before & it's not right below the other one.
    if (curr.isTemporaryNote()) {
      const continuation = curr.asTemporaryNote();
      const withContinuation: TemporaryNote = { ...continuation, body: `${curr.body()} (continued)` };
      const continuationBucket = new Bucket(curr.interval, curr.offset, curr.label, withContinuation);
      acc.push(continuationBucket);
      return acc;
    }
    const continuation = curr.asAppointment();
    const withContinuation: Appointment = {
      ...continuation,
      patientDisplayLongReverse: `${continuation.patientDisplayLongReverse} (continued)`,
      patientDisplayLong: `${continuation.patientDisplayLong} (continued)`,
      patientDisplayShortReverse: `${continuation.patientDisplayShortReverse} (continued)`,
      patientDisplayShort: `${continuation.patientDisplayShort} (continued)`,
      patientPhone1: '',
      patientPhone2: '',
      appointmentNote: '',
    };
    const continuationBucket = new Bucket(curr.interval, curr.offset, curr.label, withContinuation);
    acc.push(continuationBucket);
    return acc;
  }, [] as Bucket[]);
};

export const calendarLayoutParts: (
  appointments: Appointment[] | undefined | null,
  temporaryNotes: TemporaryNote[] | undefined | null,
  scheduleDefinition: ScheduleDefinition,
  currentDate?: Date,
) => {
  buckets: Bucket[];
  eventIntervalStartingSlots: number;
  slotsPerLineInterval: number;
} = (appointments, temporaryNotes, scheduleDefinition, currentDate) => {
  const businessHours = getBusinessHours(scheduleDefinition, currentDate!);
  const lineIntervals = scheduleTimeReducer(scheduleDefinition.timeslotInterval as 15 | 30, businessHours, appointments);
  const smallestIntervals = scheduleTimeReducer(15, businessHours, appointments);

  let buckets: Bucket[] = [];
  // this will break if the interval is 60 minutes
  let bucketOffset = 2;
  // How many slots are there per line interval. 30 minutes is 6 slots, 15 minutes is 3 slots.
  const slotsPerLineInterval = (scheduleDefinition.timeslotInterval as 15 | 30) / 5;
  const intervalToNextEvent = slotsPerLineInterval;

  if (scheduleDefinition.appointmentTakesUpSpace) {
    appointments = breakdownAppointmentsIntoChunks(appointments ?? [], scheduleDefinition.timeslotInterval as 15 | 30);
  }

  for (let intervalIndex = 0; intervalIndex < smallestIntervals.length; intervalIndex++) {
    const interval = smallestIntervals[intervalIndex];

    const intervalEvents = appointments?.filter(event => {
      const [eventHour, eventMinute] = event.appointmentTime.split(':').map(x => parseInt(x));
      return eventHour === interval.hour && eventMinute === interval.minute;
    });

    // if there are no events in this slot, and this slot should be open for an appointment
    if (
      intervalEvents?.length === 0 &&
      lineIntervals.some(
        x => x.hour === smallestIntervals[intervalIndex].hour && x.minute === smallestIntervals[intervalIndex].minute,
      )
    ) {
      const bucket = new Bucket(interval, bucketOffset, interval.label);
      buckets.push(bucket);
      bucketOffset += intervalToNextEvent;
      continue;
    }

    // otherwise, add a bucket for each event
    const eventCount = intervalEvents?.length || 0;

    for (let index = 0; index < eventCount; index++) {
      const event = intervalEvents![index];
      // event.durationMinutes = lineInterval;

      const bucket = new Bucket(interval, bucketOffset, interval.label, event);
      buckets.push(bucket);

      bucketOffset += intervalToNextEvent;
    }
  }

  const newBuckets: Bucket[] = [];
  const notesAddedToBucket: string[] = [];
  const chunkedNotes = breakdownNotesIntoChunks(temporaryNotes ?? [], scheduleDefinition.timeslotInterval as 15 | 30);
  // Notes are inserted above the bucket that matches the time of the note.
  // If there are multiple buckets with the same time, the note will be inserted above the first bucket.
  for (let index = 0; index < buckets.length; index++) {
    const bucket = buckets[index];
    const hasAddedNotesForTime = !!notesAddedToBucket[bucket.time()];
    if (hasAddedNotesForTime) {
      newBuckets.push(bucket);
      continue;
    }
    const notesToAdd = chunkedNotes
      ?.filter(note => {
        const [noteHour, noteMinute] = note.startTime.split(':').map(x => parseInt(x));
        return noteHour === bucket.interval.hour && noteMinute === bucket.interval.minute;
      })
      .sort(sortTemporaryNotes);

    for (let index = 0; index < notesToAdd!.length; index++) {
      const note = notesToAdd![index];
      const noteBucket = new Bucket(bucket.interval, bucket.offset, bucket.label, note);
      newBuckets.push(noteBucket);
    }
    notesAddedToBucket[bucket.time()] = true;

    // if we didn't add any notes, add the bucket
    if (notesToAdd?.length === 0) {
      newBuckets.push(bucket);
    }

    // if we added notes, add the bucket only if it's got an event
    if (notesToAdd?.length > 0 && bucket.event) {
      newBuckets.push(bucket);
    }
  }

  const cleanedBuckets = dedupeBuckets(newBuckets);

  // How many slots are there on the calendar? It varies depending on the hours and the number of appointments.
  // A bucket takes up either 3 or 6 slots depending on the interval--15 minutes is 3 slots, 30 minutes is 6 slots.
  // If there are 12 hours and 30 minute intervals, there are 24 buckets and 144 slots.
  // If there are 12 hours and 15 minute intervals, there are 48 buckets and 144 slots.
  // If there are 12 hours and 30 minute intervals and 3 appointments scheduled for the same period, there are 26 buckets and 156 slots.
  // - (24 buckets + 2 buckets for the appointments that duplicate the time slot)
  const eventIntervalStartingSlots = cleanedBuckets.length * slotsPerLineInterval;

  return {
    buckets: cleanedBuckets,
    eventIntervalStartingSlots,
    slotsPerLineInterval,
  };
};
