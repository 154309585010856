import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';

export type ScheduleType = NewScheduleTypeRequest & Auditable;

export type NewScheduleTypeRequest = {
  name: string;
  externalName: string;
  notes?: string;
};

export const useGetScheduleTypes = () => {
  const { computeClient: client } = useApiClient();
  return useQuery(['schedule-type'], () => client.get<ScheduleType[]>('/schedule-type').then(response => response.data));
};

export const useGetScheduleType = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['schedule-type', id], () => client.get<ScheduleType>(`/schedule-type/${id}`).then(response => response.data));
};

export const useCreateScheduleType = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<ScheduleType, AxiosError, NewScheduleTypeRequest>({
    mutationFn: async function (request: NewScheduleTypeRequest) {
      const response = await client.post('/schedule-type', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-type']);
    },
  });
};

export const useUpdateScheduleType = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<ScheduleType, AxiosError, ScheduleType>({
    mutationFn: request => client.put('/schedule-type', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-type']);
    },
  });
};

export const useDeleteScheduleType = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/schedule-type/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-type']);
    },
  });
};
