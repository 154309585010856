import { useState, createContext, useEffect, useContext } from 'react';
import React from 'react';
import { useAuth } from 'utils/auth';
import { ICapabilities, useProfile } from 'utils/profile';
import {
  faIdCard,
  faUsers,
  faUpRightFromSquare,
  faDoorOpen,
  faScrewdriverWrench,
  faFolderTree,
  faPhone,
  faSink,
  faClock,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faHeart, faUser, faChartBar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useScheduleQueueContext } from 'utils/scheduleQueueHook';
import { useTimeSlotOverflowApprovalContext } from 'utils/timeslotOverflowApprovalHook';
import { Patient } from '../../api/PatientApi';
import { useLocation, useNavigate } from 'react-router-dom';

export interface INavBarLinks {
  navItems: INavigationItem[];
  userNavItems: INavigationItem[];
  configNavItems: INavigationItem[];
  queueNavItems: INavigationItem[];
  addPatientTab: (patient: Patient) => void;
  removePatientTab: (patientId: string) => void;
  patientItems: IPatientNavigationItem[];
}

const NavBarLinksContext = createContext<INavBarLinks>({
  navItems: [],
  userNavItems: [],
  configNavItems: [],
  queueNavItems: [],
  addPatientTab: () => {},
  removePatientTab: () => {},
  patientItems: [],
});

export interface INavigationItem {
  name: string;
  href: string;
  isExternal: boolean;
  newTab?: boolean;
  icon: React.JSX.Element | null;
  key: string;
}

export interface IPatientNavigationItem extends INavigationItem {
  additionalActivePaths: string[];
  onClickX: () => void;
}

class PatientNavItem implements IPatientNavigationItem {
  constructor(
    public name: string,
    public href: string,
    public icon: JSX.Element | null,
    public key: string,
    public additionalActivePaths: string[] = [],
    public onClickX: () => void = () => {},
  ) {}

  isExternal = false;
}

class NavItem implements INavigationItem {
  name: string;
  href: string;
  isExternal = false;
  icon: React.JSX.Element | null;
  newTab?: boolean | undefined;
  key: string;

  constructor(name: string, href: string, icon: JSX.Element | null = null) {
    this.name = name;
    this.href = href;
    this.icon = icon;
    this.key = name;
  }
}

class SignInNavItem implements INavigationItem {
  constructor(signInUrl: string) {
    this.href = signInUrl;
  }

  name = 'Sign In';
  key = 'sign-in';
  href: string;
  isExternal = true;
  icon = (<FontAwesomeIcon icon={faDoorOpen} />);
}

class SignOutNavItem implements INavigationItem {
  constructor(signOutUrl: string) {
    this.href = signOutUrl;
  }

  name = 'Sign Out';
  key = 'sign-out';
  href: string;
  isExternal = true;
  icon = (<FontAwesomeIcon icon={faUpRightFromSquare} />);
}

const authenticatedNavItems = (capabilities: ICapabilities) => {
  const navItems: INavigationItem[] = [];

  if (capabilities.canViewSchedule()) {
    navItems.push(new NavItem('Schedule', '/schedules', <FontAwesomeIcon icon={faCalendar} />));
  }

  if (capabilities.canViewPatientDirectory()) {
    navItems.push(new NavItem('Intake', '/patients/new', <FontAwesomeIcon icon={faHeart} />));
    navItems.push(new NavItem('Search', '/patients/search', <FontAwesomeIcon icon={faSearch} />));
  }

  if (capabilities.canViewReports()) {
    navItems.push(new NavItem('Reports', '/reports', <FontAwesomeIcon icon={faChartBar} />));
  }

  return navItems;
};

const unauthenticatedNavItems = (loginUrl: string) => [new SignInNavItem(loginUrl)];

const authenticatedConfigNavItems = (capabilities: ICapabilities) => {
  const navItems: INavigationItem[] = [];
  if (capabilities.canManageUsers()) {
    navItems.push(new NavItem('Users', '/user-management', <FontAwesomeIcon icon={faUsers} />));
  }
  if (capabilities.canManageConfiguration()) {
    navItems.push(new NavItem('Configuration', '/config', <FontAwesomeIcon icon={faScrewdriverWrench} />));
    navItems.push(new NavItem('Ancillary Data', '/ancillary', <FontAwesomeIcon icon={faFolderTree} />));
  }
  if (capabilities.canViewKitchenSink()) {
    navItems.push(new NavItem('Kitchen Sink', '/kitchen-sink', <FontAwesomeIcon icon={faSink} />));
  }
  return navItems;
};

const initialAppointmentIcon = (
  <span className="fa-layers fa-lg">
    <FontAwesomeIcon icon={faCalendar} />
    <FontAwesomeIcon icon={faPhone} transform="shrink-8 down-2" />
  </span>
);

const timeslotOverrideIcon = (
  <span className="fa-layers fa-lg">
    <FontAwesomeIcon icon={faCalendar} />
    <FontAwesomeIcon icon={faClock} transform="shrink-8 down-2" />
  </span>
);

const authenticatedQueueNavItems = (
  capabilities: ICapabilities,
  initialAppointmentQueueCount: number | undefined,
  initialTimeslotOverflowRequestCount: number | undefined,
) => {
  const navItems: INavigationItem[] = [];

  if (capabilities.canViewInitialScheduleAppointmentQueue()) {
    const count = initialAppointmentQueueCount ? ` (${initialAppointmentQueueCount})` : '';
    navItems.push(new NavItem(`Initial Appointment Queue ${count}`, '/schedule-queues', initialAppointmentIcon));
  }

  if (capabilities.canApproveTimeslotOverflowRequests()) {
    const count = initialTimeslotOverflowRequestCount ? ` (${initialTimeslotOverflowRequestCount})` : '';
    navItems.push(new NavItem(`Timeslot Overflow Requests ${count}`, '/timeslot-overflow-requests', timeslotOverrideIcon));
  }

  return navItems;
};

const authenticatedUserNavItems = (logoutUrl: string) => [
  new NavItem('Your Profile', '/profile', <FontAwesomeIcon icon={faIdCard} />),
  new SignOutNavItem(logoutUrl),
];

const unauthenticatedUserNavItems = (loginUrl: string) => [new SignInNavItem(loginUrl)];

export function NavBarLinksProvider({ children }) {
  const auth = useAuth()!;
  const profile = useProfile()!;
  const { initialAppointmentTasks } = useScheduleQueueContext();
  const { items: timeSlotOverflowRequests } = useTimeSlotOverflowApprovalContext();

  const [navItems, setNavItems] = useState<INavigationItem[]>(() =>
    profile.capabilities ? authenticatedNavItems(profile.capabilities) : unauthenticatedNavItems(auth.loginUrl),
  );

  const [patientItems, setPatientItems] = useState<IPatientNavigationItem[]>([]);

  const [userNavItems, setUserNavItems] = useState<INavigationItem[]>(() =>
    auth.user ? authenticatedUserNavItems(auth.logoutUrl) : unauthenticatedUserNavItems(auth.loginUrl),
  );
  const [configNavItems, setConfigNavItems] = useState<INavigationItem[]>(() =>
    profile.capabilities ? authenticatedConfigNavItems(profile.capabilities) : [],
  );
  const [queueNavItems, setQueueNavItems] = useState<INavigationItem[]>(() =>
    profile.capabilities
      ? authenticatedQueueNavItems(profile.capabilities, initialAppointmentTasks?.length, timeSlotOverflowRequests?.length)
      : [],
  );

  const location = useLocation();
  const navigate = useNavigate();

  const addPatientTab = (patient: Patient) => {
    const path = `/patients/${patient.id}`;
    if (patientItems.some(tab => tab.href === path)) {
      return;
    }
    const newTab = new PatientNavItem(
      patient.displayLongReverse,
      path,
      <FontAwesomeIcon icon={faUser} />,
      patient.id,
      [`/patients/${patient.id}/*`],
      () => removePatientTab(patient.id),
    );
    setPatientItems([...patientItems, newTab]);
  };

  const removePatientTab = (patientId: string) => {
    const index = patientItems.findIndex(tab => tab.href === `/patients/${patientId}`);
    if (index === -1) {
      return;
    }

    // remove the tab from the list of tabs
    const newTabs = patientItems.filter(tab => tab.href !== `/patients/${patientId}`);
    setPatientItems(newTabs);

    // if we were on the tab we are removing, navigate to the next tab or to search.
    const shouldNavigateAway = location.pathname.includes(patientItems[index].href);
    if (!shouldNavigateAway) {
      return;
    }

    // if there are no more tabs, navigate to the search page
    if (newTabs.length === 0) {
      navigate('/patients/search');
      return;
    }

    // if there is a tab at newTabs[index], navigate to that tab.
    if (newTabs.length > index) {
      navigate(newTabs[index].href);
      return;
    }
    // if there is not a tab at newTabs[index], navigate to the previous tab.
    else {
      navigate(newTabs[index - 1].href);
      return;
    }
  };

  useEffect(() => {
    if (auth.user && profile.capabilities) {
      setNavItems(authenticatedNavItems(profile.capabilities));
      setUserNavItems(authenticatedUserNavItems(auth.logoutUrl));
      setConfigNavItems(authenticatedConfigNavItems(profile.capabilities));
      setQueueNavItems(
        authenticatedQueueNavItems(profile.capabilities, initialAppointmentTasks?.length, timeSlotOverflowRequests?.length),
      );
    } else {
      setNavItems(unauthenticatedNavItems(auth.loginUrl));
      setUserNavItems(unauthenticatedUserNavItems(auth.loginUrl));
      setConfigNavItems([]);
      setQueueNavItems([]);
    }
  }, [
    profile.capabilities,
    auth.loginUrl,
    auth.logoutUrl,
    auth.user,
    initialAppointmentTasks?.length,
    timeSlotOverflowRequests?.length,
  ]);

  return (
    <NavBarLinksContext.Provider
      value={{ navItems, userNavItems, configNavItems, queueNavItems, patientItems, addPatientTab, removePatientTab }}
    >
      {children}
    </NavBarLinksContext.Provider>
  );
}

export const useNavBarLinks = () => {
  return useContext(NavBarLinksContext);
};
