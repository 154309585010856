import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';

export type ClinicLocation = NewClinicLocationRequest & Auditable;

export type NewClinicLocationRequest = {
  name: string;
  externalName: string;
  notes?: string;
  phone: string;
  timeZone: string;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
};

export const useGetClinicLocations = () => {
  const { computeClient: client } = useApiClient();
  return useQuery(['clinic-location'], () => client.get<ClinicLocation[]>('/clinic-location').then(response => response.data));
};

export const useGetClinicLocation = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['clinic-location', id], () =>
    client.get<ClinicLocation>(`/clinic-location/${id}`).then(response => response.data),
  );
};

export const useCreateClinicLocation = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<ClinicLocation, AxiosError, NewClinicLocationRequest>({
    mutationFn: async function (request: NewClinicLocationRequest) {
      const response = await client.post('/clinic-location', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['clinic-location']);
    },
  });
};

export const useUpdateClinicLocation = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<ClinicLocation, AxiosError, ClinicLocation>({
    mutationFn: request => client.put('/clinic-location', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['clinic-location']);
    },
  });
};

export const useDeleteClinicLocation = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/clinic-location/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['clinic-location']);
    },
  });
};
