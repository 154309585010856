import classNames from 'classnames';
import { flexRender, Table } from '@tanstack/react-table';
import { Filter } from '../../pages/PatientPage/AppointmentListTable';
import { SimpleTableRow } from './SimpleTableRow';
import React from 'react';

export type SimpleTableParams<T> = {
  table: Table<T> | undefined;
  onClick: (item: T) => void;
  wrapLines?: boolean;
};

export function SimpleTable<T>({ table, onClick, wrapLines }: SimpleTableParams<T>) {
  return (
    <table className="min-w-full border-separate border-spacing-0">
      <thead>
        {table!.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th
                key={header.id}
                scope="col"
                className={classNames(
                  'sticky top-0 z-10 border-b border-gray-300 bg-white py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8',
                  header.column.getCanSort() ? 'cursor-pointer select-none' : 'cursor-default',
                )}
              >
                {header.isPlaceholder ? null : (
                  <div onClick={header.column.getToggleSortingHandler()} className="cursor-pointer">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {{
                      asc: ' 🔼',
                      desc: ' 🔽',
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                )}
                {header.column.getCanFilter() ? (
                  <div>
                    <Filter column={header.column} table={table!} />
                  </div>
                ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody>
        {table!.getRowModel().rows.map((row, index) => (
          <SimpleTableRow
            key={row.id}
            row={row}
            isLastRow={index === table!.getRowModel().rows.length - 1}
            onClick={onClick}
            wrapLines={wrapLines}
          />
        ))}
      </tbody>
    </table>
  );
}
