import { Appointment } from '../../api/AppointmentApi';
import { format } from 'date-fns';
import React from 'react';
import { useFlexworxConfig } from '../../../utils/flexworx-config';

export const AppointmentListNotes = ({ appointment }: { appointment: Appointment | null }) => {
  if (!appointment) {
    return null;
  }

  return <AppointmentListNotesRender appointment={appointment} />;
};

const AppointmentListNotesRender = ({ appointment }: { appointment: Appointment }) => {
  const {
    appointmentTypeFromId,
    appointmentStatusFromId,
    clinicLocationFromScheduleDefinitionId,
    scheduleTypeFromScheduleDefinitionId,
  } = useFlexworxConfig();

  const date = new Date(
    parseInt(appointment.appointmentDate.split('-')[0]),
    parseInt(appointment.appointmentDate.split('-')[1]) - 1,
    parseInt(appointment.appointmentDate.split('-')[2]),
  );

  const formattedTime = format(new Date(`2020-01-01T${appointment.appointmentTime}`), 'h:mm a');
  const formattedDate = format(date, 'MMM d, yyyy');
  const dayOfWeek = format(date, 'EEEE');
  const appointmentType = appointmentTypeFromId(appointment.appointmentTypeId);
  const appointmentStatus = appointmentStatusFromId(appointment.appointmentStatusId);
  const clinicLocation = clinicLocationFromScheduleDefinitionId(appointment.scheduleDefinitionId);
  const scheduleType = scheduleTypeFromScheduleDefinitionId(appointment.scheduleDefinitionId);

  return (
    <div className="print:hidden sticky bottom-0 bg-white">
      <div className="pt-4">
        <div>
          <span className="font-bold">Notes</span> for {scheduleType?.name} -{' '}
          <span className={'font-bold'}>{clinicLocation?.name}</span> at <span className={'font-bold'}>{formattedTime}</span>,{' '}
          {dayOfWeek} <span className={'font-bold'}>{formattedDate}</span>, {appointmentType?.name} - {appointmentStatus?.name}
        </div>
      </div>
      <div className="relative mt-1 rounded-md shadow-sm pb-10">
        <textarea
          className="block w-full rounded-md border-gray-300 pr-10 text-gray-900 placeholder-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          rows={4}
          readOnly={true}
          value={appointment.appointmentNote ?? 'Empty'}
        />
      </div>
    </div>
  );
};
