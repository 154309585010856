import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import React from 'react';

export type MultiselectSelectedItemsRowsProps<T> = {
  items: T[];
  removeSelected: (value: T) => void;
  getName: (value: T) => string;
  getKey: (value: T) => string;
};

export function MultiselectSelectedItemsRows<T>({
  items,
  getKey,
  removeSelected,
  getName,
}: MultiselectSelectedItemsRowsProps<T>) {
  return (
    <div className="flex flex-wrap">
      {items.map(v => (
        <div key={getKey(v)} className="group">
          <button
            onClick={() => removeSelected(v)}
            type="button"
            className="rounded mt-2 mr-2 bg-white py-1 px-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 group-hover:bg-gray-50"
          >
            <FontAwesomeIcon icon={faXmark} className=" h-3 w-3 group-hover:text-gray-900 text-gray-400" />
            {getName(v)}
          </button>
        </div>
      ))}
    </div>
  );
}
