import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import { useAuth } from './auth';
import { useWebsockets } from './websocket';
import { TimeSlotOverflowApproval, useGetUnapprovedTimeSlotOverflowRequests } from 'app/api/TimeSlotOverflowApprovalApi';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from '@tanstack/react-query';

const sortByCreated = (a: TimeSlotOverflowApproval, b: TimeSlotOverflowApproval) => {
  if (new Date(a.createdAt) > new Date(b.createdAt)) {
    return -1;
  }
  if (new Date(a.createdAt) < new Date(b.createdAt)) {
    return 1;
  }
  return 0;
};

export interface ITimeSlotOverflowApprovalContext {
  items: TimeSlotOverflowApproval[] | null;
  isLoading: boolean;
  isError: boolean;
  refetch: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<TimeSlotOverflowApproval[], unknown>>;
}

const TimeSlotOverflowApprovalContext = createContext<ITimeSlotOverflowApprovalContext>({
  items: null,
  isLoading: false,
  isError: false,
  refetch: () => {
    throw new Error('refetch function not implemented');
  },
});

export function TimeSlotOverflowApprovalProvider({ children }) {
  const { user } = useAuth();

  const [fetchData, setFetchData] = useState(false);
  const { lastTimeSlotOverflowApprovalMessage } = useWebsockets();

  const { data: overflowApprovalRequests, isLoading, isError, refetch } = useGetUnapprovedTimeSlotOverflowRequests(fetchData);

  useEffect(() => {
    if (user) {
      setFetchData(true);
    }
  }, [user]);

  useEffect(() => {
    if (!lastTimeSlotOverflowApprovalMessage) {
      return;
    }
    console.log('We are not handling this now', lastTimeSlotOverflowApprovalMessage);
  }, [lastTimeSlotOverflowApprovalMessage]);

  const providerValue = {
    items: overflowApprovalRequests?.sort(sortByCreated) ?? [],
    isLoading,
    isError,
    refetch,
  };

  return <TimeSlotOverflowApprovalContext.Provider value={providerValue}>{children}</TimeSlotOverflowApprovalContext.Provider>;
}

export function useTimeSlotOverflowApprovalContext() {
  return useContext(TimeSlotOverflowApprovalContext);
}
