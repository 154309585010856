import React, { forwardRef } from 'react';
import { GenericInputGroupProps, InputGroupProps } from '../InputGroup';
import { Modal } from '../../Modal';
import { TreatmentCenterInputTextField } from './TreatmentCenterInputTextField';
import { TreatmentCenter } from '../../../api/TreatmentCenterApi';
import { TreatmentCenterList } from '../../../pages/AncillaryDataPage/TreatmentCenterList';

export type TreatmentCenterGroupProps = GenericInputGroupProps<HTMLInputElement, string> & {
  defaultModel?: TreatmentCenter;
};

export const TreatmentCenterInputGroup = forwardRef<HTMLInputElement, TreatmentCenterGroupProps>(
  (
    { label, name, errors, onBlur, clearErrors, setValue, tooltip, defaultModel }: TreatmentCenterGroupProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const args: InputGroupProps = {
      label,
      type: 'text',
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      tooltip,
      placeholder: '',
    };

    const [model, setModel] = React.useState<TreatmentCenter | null>(defaultModel ?? null);
    const [showSelectionModal, setShowSelectionModal] = React.useState<boolean>(false);

    const onSelection = (treatmentCenter: TreatmentCenter) => {
      clearErrors();
      setModel(treatmentCenter);
      setValue(treatmentCenter.id);
      setShowSelectionModal(false);
    };

    const clear = () => {
      setValue('');
      setModel(null);
    };

    return (
      <>
        <Modal
          width="sm:max-w-screen-xl"
          open={showSelectionModal}
          closeModal={() => setShowSelectionModal(false)}
          closeModalText="Cancel"
        >
          <TreatmentCenterList onSelect={onSelection} allowNew={false} />
        </Modal>

        <div className="flex flex-col">
          <div className="w-full">
            <TreatmentCenterInputTextField
              {...args}
              ref={ref}
              model={model}
              onClear={clear}
              onClick={() => setShowSelectionModal(true)}
            />
          </div>
        </div>
      </>
    );
  },
);
