import React from 'react';
import { Row, flexRender } from '@tanstack/react-table';
import classNames from 'classnames';

type SimpleFormRowItemParams<T> = {
  row: Row<T>;
  isLastRow: boolean;
  onClick: (item: T) => void;
  wrapLines?: boolean;
};

// todo: this can change to a SimpleFormListRow
export function SimpleTableRow<T>({ row, isLastRow, onClick, wrapLines }: SimpleFormRowItemParams<T>) {
  return (
    <tr key={row.id} className="hover:bg-sky-100 cursor-pointer" onClick={() => onClick(row.original)}>
      {row.getVisibleCells().map(cell => (
        <td
          key={cell.id || cell.column.id}
          className={classNames(
            !isLastRow ? 'border-b border-gray-200' : '',
            'py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
            !wrapLines ? 'whitespace-nowrap' : '',
          )}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      ))}
    </tr>
  );
}
