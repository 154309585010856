import React from 'react';
import { PageContainer } from '../../components/PageContainer';
import { capitalize } from 'lodash';
import { useProfile } from '../../../utils/profile';

export const EnvironmentExplanationPage = () => {
  const environment = capitalize(process.env.REACT_APP_ENVIRONMENT);
  const profile = useProfile();

  return (
    <div className="mt-4">
      <PageContainer title="Environment" description="Environments Explained">
        <div className="text-lg text-indigo-900 mt-6">
          <p>
            Development of this application requires multiple instances of the app. Some instances serve particular purposes, such
            as testing. These instances are called environments.
          </p>
          <p>There are two accessible environments:</p>
          <ul className="ml-4">
            <li>
              Staging <a href="https://app.flexworxhealth.com">(https://app.flexworxhealth.com)</a>
            </li>

            <li>
              Production <a href="https://app.flexworxmedical.com">(https://app.flexworxmedical.com)</a>
            </li>
          </ul>
          <p className="mt-8">
            You are currently in the <strong>{environment}</strong> environment.
          </p>
          {process.env.REACT_APP_ENVIRONMENT === 'production' && (
            <p>
              <strong>Warning:</strong> This is the production environment. Data integrity is important. Please be careful when
              making changes.
            </p>
          )}
          {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
            <>
              <p>
                <strong>Warning:</strong> This environment is for testing. There is no guarantee of data integrity in this
                environment. Your data could be lost at any point in time.
              </p>
              <p className="mt-4">
                It is likely you should not be here. Please visit the{' '}
                <i>
                  <a href="https://app.flexworxmedical.com">
                    <button
                      type="button"
                      className="rounded-md bg-white px-3 py-2 font-semibold  shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      production environment
                    </button>
                  </a>
                </i>{' '}
                instead.
              </p>
              <p>
                Your username in production is <span className="italic">{profile.username}</span>. Your password is not
                necessarily the same.
              </p>
            </>
          )}
        </div>
      </PageContainer>
    </div>
  );
};
