import { Transition } from '@headlessui/react';
import React, { ChangeEventHandler, forwardRef, useId, useMemo } from 'react';
import { GenericInputGroupProps } from './InputGroup';
import { InfoTip } from '../Tooltip';

export type CheckboxProps = GenericInputGroupProps<HTMLInputElement, boolean> & {
  initialValue: boolean;
  children?: React.ReactNode;
};

export const CheckboxGroup = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { label, name, errors, onBlur, clearErrors, setValue, initialValue, children, tooltip }: CheckboxProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [checked, setChecked] = React.useState(initialValue);

    const onChange: ChangeEventHandler<HTMLInputElement> = event => {
      clearErrors();
      setChecked(event.target.checked);
      setValue(event.target.checked);
    };

    const id = useId();

    const iterableErrorMessages = useMemo(() => {
      if (errors?.message) {
        if (Array.isArray(errors.message)) {
          return errors.message;
        }
        return [errors.message];
      }
      return [];
    }, [errors]);

    return (
      <>
        <div className="relative flex items-start">
          <div className="flex h-5 items-center">
            <input
              id={id}
              name={name}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              checked={checked}
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              {...(errors && { 'aria-invalid': 'true' })}
              {...(errors && { 'aria-describedby': `${id}-error` })}
              autoComplete="off"
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor={id} className="font-medium text-gray-700">
              {label}
              {tooltip && <InfoTip content={tooltip} />}
            </label>
          </div>
          {}
        </div>
        {iterableErrorMessages.map((message, index) => (
          <p key={index} className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {message}
          </p>
        ))}
        {children && (
          <Transition
            show={checked}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-75 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-300 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-75 opacity-0"
          >
            {children}
          </Transition>
        )}
      </>
    );
  },
);
