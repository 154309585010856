interface FunctionWithArguments {
  (...args: any): any;
}

interface ThrottledFunction<F extends FunctionWithArguments> {
  (...args: Parameters<F>): Promise<ReturnType<F>>;
}

interface ThrottleReturn<F extends FunctionWithArguments> extends Array<ThrottledFunction<F> | (() => void)> {
  0: (...args: Parameters<F>) => Promise<ReturnType<F>>;
  1: () => void;
}

// todo: need some tests for this
export default function throttle<F extends FunctionWithArguments>(fn: F, ms: number): ThrottleReturn<F> {
  let cooldown: ReturnType<typeof setTimeout> | null;

  const throttleFunc: ThrottledFunction<F> = (...args) =>
    new Promise((resolve, reject) => {
      if (cooldown) {
        return reject('throttled');
      }
      cooldown = setTimeout(() => {
        cooldown = null;
      }, ms);

      resolve(fn(...(args as unknown[])));
    });

  const teardown = () => {
    if (cooldown) {
      clearTimeout(cooldown);
    }
  };

  return [throttleFunc, teardown];
}
