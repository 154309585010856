import React, { forwardRef } from 'react';
import { GenericInputGroupProps, InputGroupProps } from '../InputGroup';
import { PatientInputTextField } from './PatientInputTextField';
import { Patient } from 'app/api/PatientApi';
import { PatientDirectoryModal } from './PatientDirectoryModal';

export type PatientInputGroupProps = GenericInputGroupProps<HTMLInputElement, string> & {
  patient: Patient;
  setPatient: (patient: Patient) => void;
};

export const PatientInputGroup = forwardRef<HTMLInputElement, PatientInputGroupProps>(
  (
    { label, name, errors, onBlur, clearErrors, setValue, tooltip, patient, setPatient }: PatientInputGroupProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const args: InputGroupProps = {
      label,
      type: 'text',
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      tooltip,
      placeholder: '',
    };

    const [showPatientDirectory, setShowPatientDirectory] = React.useState<boolean>(false);

    const onDirectoryRowClick = (patient: Patient) => {
      setPatient(patient);
      setValue(patient.id);
      setShowPatientDirectory(false);
    };

    return (
      <>
        <PatientDirectoryModal
          onDirectoryRowClick={onDirectoryRowClick}
          open={showPatientDirectory}
          setOpen={setShowPatientDirectory}
        />
        <div className="flex flex-col">
          <div className="w-full">
            <PatientInputTextField {...args} ref={ref} patient={patient} onClick={() => setShowPatientDirectory(true)} />
          </div>
        </div>
      </>
    );
  },
);
