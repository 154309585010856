import React, { useState } from 'react';
import { FieldValues, useForm, ValidationRule } from 'react-hook-form';
import { Patient, useUpdatePatient } from '../../api/PatientApi';
import { useToast } from '../../../utils/toast';
import { ButtonRowV2 } from '../Forms/ButtonRowV2';
import { EnterForm } from '../Forms/EnterForm';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { SectionDemographics } from './SectionDemographics';

export type EditPatientDemographicsFormProps = {
  defaultValues: FieldValues;
};

export const EditPatientDemographicsForm = ({ defaultValues }: EditPatientDemographicsFormProps) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
    watch,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });
  const { toastError } = useToast();
  const onCancel = () => {
    reset();
  };
  const onSuccess = (patient: Patient) => {};
  const onUnrecoverableError = (error: any) => toastError(error);

  const updatePatient = useUpdatePatient(defaultValues.id);

  const onSubmit = async (data: any) => {
    const patient = await updatePatient.mutateAsync(data);
    onSuccess(patient);
  };

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
    setValueAs?: ((value: any) => any) | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required, setValueAs };
  };

  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <EnterForm
      className="space-y-8 divide-y divide-gray-200"
      submitForm={handleSubmit(onSubmit)}
      setError={setError}
      onUnrecoverableError={onUnrecoverableError}
      setDisabled={setIsDisabled}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <SectionDemographics fieldArguments={fieldArguments} watch={watch} setValue={setValue} />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <ButtonRowV2
            onCancel={onCancel}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            onUnrecoverableError={onUnrecoverableError}
            onCancelText={'Discard Changes'}
            onCancelIcon={faUndo}
          />
        </div>
      </div>
    </EnterForm>
  );
};
