import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import throttle from 'utils/throttle';

const resource = `${process.env.REACT_APP_AUTHZ_HOST}/token`;

type TokenPayload = {
  access_token: string;
  id_token: string;
  expires_in: number;
};

export const useTokenFromCode = (code: string | null, redirectUri: string) =>
  useQuery(['token-from-code'], async () => {
    if (!code) {
      return null;
    }
    const res = await axios.get(resource, {
      params: { code: code, redirect_uri: redirectUri },
    });
    return res.data;
  });

const wait = (ms: number) => new Promise(r => setTimeout(r, ms));

const retryRefreshToken = (operation: () => Promise<any>, delay: number, retries: number) =>
  new Promise((resolve, reject) => {
    return operation()
      .then(resolve)
      .catch(reason => {
        if (reason.response) {
          if (reason.response.status === 400) {
            return reject('token_expired');
          }
        }

        if (retries > 0) {
          return (
            wait(delay)
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              .then(retryRefreshToken.bind(null, operation, delay, retries - 1))
              .then(resolve)
              .catch(reject)
          );
        }
        return reject(reason);
      });
  });

const refreshWithRetry: (refreshToken: string) => TokenPayload = (refreshToken: string) =>
  retryRefreshToken(
    async () => {
      const res = await axios.post<TokenPayload>(resource, {
        refresh_token: refreshToken,
      });
      return res.data;
    },
    300,
    2,
  );

const [throttledRefresh] = throttle(refreshWithRetry, 1000);

export const getNewTokens = throttledRefresh;
