import React from 'react';
import { LoadingAnimated } from '../app/components/LoadingAnimated';

export const LoadingWrapper = ({ isLoading, isError, children }) => {
  return (
    <>
      {isError && <div>Error fetching data</div>}
      {!isError && (
        <>
          {isLoading && <LoadingAnimated />}
          {!isLoading && children}
        </>
      )}
    </>
  );
};
