import React from 'react';
import { CommunicationListTable } from './communicationListTable';
import { Patient } from 'app/api/PatientApi';
import { Link } from 'react-router-dom';
import { SimpleItemNewButton } from '../../components/Forms/SimpleItemNewButton';
import { SmsMessageForm } from '../../components/Forms/SmsMessageForm';

export type CommunicationListProps = {
  patient: Patient;
  smsTemplate?: string;
};

export const CommunicationList = ({ patient, smsTemplate }: CommunicationListProps) => {
  const patientCanReceiveSMS = patient.phone1 && patient.phone1CanText;

  const demographicLink = `/patients/${patient.id}/demographics`;

  return (
    <div>
      <div>
        <h1 className="text-2xl font-semibold text-indigo-900 ">Communication</h1>
        <div>
          {patientCanReceiveSMS && (
            <>
              Patient Can Receive SMS. Has primary phone & consented to receive SMS reminders. Visit{' '}
              <Link to={demographicLink}>Demographics</Link> to update.
            </>
          )}
          {!patientCanReceiveSMS && (
            <>
              Patient Cannot Receive SMS. {patient.phone1 && <>Has primary phone</>}
              {!patient.phone1 && <>No primary phone</>} &{patient.phone1CanText && <>has consented to receive SMS reminders</>}
              {!patient.phone1CanText && <> not consented to receive SMS reminders.</>} Visit{' '}
              <Link to={demographicLink}>Demographics</Link> to update.
            </>
          )}
        </div>

        <div>
          {patient.phone1 && (
            <>
              <div>
                Phone: <span className="hidden md:inline font-extrabold text-lg">{patient.phone1}</span>
                <span className="md:hidden font-extrabold text-lg">
                  <a href={`tel:${patient.phone1}`}>{patient.phone1}</a>
                </span>
              </div>
              <div>
                <SimpleItemNewButton headerSingular="Text Message" getName={() => `SMS Message`}>
                  {({ onSuccess, onCancel, onUnrecoverableError }) => (
                    <SmsMessageForm
                      onCancel={onCancel}
                      patient={patient}
                      onSuccess={onSuccess}
                      smsTemplate={smsTemplate}
                      onUnrecoverableError={onUnrecoverableError}
                    />
                  )}
                </SimpleItemNewButton>
              </div>
            </>
          )}
        </div>

        <CommunicationListTable patient={patient} />
      </div>
    </div>
  );
};
