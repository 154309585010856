import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ChangeEventHandler, FocusEventHandler, forwardRef, useId, useMemo } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { InfoTip } from '../Tooltip';

export type GenericInputGroupProps<T, TS> = {
  label: string;
  name: string;
  errors?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  onBlur?: FocusEventHandler<T> | undefined;
  clearErrors: () => void;
  setValue: (value: TS) => void;
  tooltip?: string;
  readonly?: boolean;
  bgColor?: 'white' | 'gray-100';
};

export type InputGroupProps = GenericInputGroupProps<HTMLInputElement, string> & {
  type: string;
  placeholder: string;
};

export const InputGroup = forwardRef<HTMLInputElement, InputGroupProps>(
  (
    { label, type, placeholder, name, errors, onBlur, clearErrors, setValue, tooltip, readonly, bgColor }: InputGroupProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    var baseClass = classNames(
      'block w-full rounded-md border-gray-300 text-gray-900 placeholder-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm',
      !!bgColor ? `bg-${bgColor}` : 'bg-white',
    );
    var invalidClass = classNames(
      baseClass,
      'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500',
    );

    const onChange: ChangeEventHandler<HTMLInputElement> = event => {
      clearErrors();
      setValue(event.target.value);
    };

    const id = useId();

    const iterableErrorMessages = useMemo(() => {
      if (errors?.message) {
        if (Array.isArray(errors.message)) {
          return errors.message;
        }
        return [errors.message];
      }
      return [];
    }, [errors]);

    return (
      <>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
          {tooltip && <InfoTip content={tooltip} />}
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            readOnly={readonly}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            ref={ref}
            type={type}
            id={id}
            className={errors ? invalidClass : baseClass}
            placeholder={placeholder}
            {...(errors && { 'aria-invalid': 'true' })}
            {...(errors && { 'aria-describedby': `${id}-error` })}
            autoComplete="off"
          />
          {errors && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <FontAwesomeIcon className="h-5 w-5 text-red-500" icon={faTriangleExclamation} aria-hidden="true" />
            </div>
          )}
        </div>
        {iterableErrorMessages.map((message, index) => (
          <p key={index} className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {message}
          </p>
        ))}
      </>
    );
  },
);
