import React, { Fragment } from 'react';
import { useMultiField } from '../Forms/FieldHook';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHospital, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { SectionTreatment } from './SectionTreatment';

export type DynamicSectionTreatmentProps = {
  watch: any;
  setValue: any;
  control: any;
  errors: any;
  clearErrors: any;
  getValues: any;
  register: any;
};

export const DynamicSectionTreatment = ({
  watch,
  setValue,
  control,
  errors,
  clearErrors,
  getValues,
  register,
}: DynamicSectionTreatmentProps) => {
  const treatments = useMultiField({
    control,
    name: 'treatments',
    errors,
    clearErrors,
  });

  const onAddTreatment = () => {
    const newTreatment = {
      treatmentCenterId: null,
      treatmentDate: null,
      isEmergencyRoom: null,
      treatmentNote: '',
    };
    treatments.append(newTreatment);
  };

  const onRemoveClick = (index: number) => {
    clearErrors();
    treatments.remove(index);
  };

  return (
    <div className="gap-y-2 gap-x-2 flex flex-col">
      {treatments.fields.map((field, index) => (
        <Fragment key={index}>
          <div className={classNames('flex flex-row w-full space-x-2 p-2 hover:bg-blue-300', index % 2 ? 'bg-gray-100' : '')}>
            <div className="grow">
              <SectionTreatment treatments={treatments} watch={watch} register={register} index={index} setValue={setValue} />
            </div>
            <div className="flex flex-row items-center">
              <FontAwesomeIcon
                className="text-red-600 cursor-pointer ml-2 mt-4 hover:text-red-800 hover:scale-110 group"
                icon={faTrashCan}
                onClick={() => onRemoveClick(index)}
              />
            </div>
          </div>
        </Fragment>
      ))}
      <div>
        <button
          type="button"
          onClick={onAddTreatment}
          className="mr-2 whitespace-nowrap rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <FontAwesomeIcon icon={faHospital} className="mr-2" />
          Add Treatment
        </button>
      </div>
    </div>
  );
};
