import React from 'react';
import { InputGroup } from '../Forms/InputGroup';
import useField from '../Forms/FieldHook';
import { TextAreaGroup } from '../Forms/TextAreaGroup';
import { SectionDivider } from './SectionDivider';
import { OptionFactory } from '../Forms/Data';
import { ListBoxGroupV2 } from '../Forms/ListBoxGroupV2';

export type SectionIntakeProps = {
  fieldArguments: any;
  watch: any;
  setValue: any;
};

export const SectionIntake = ({ fieldArguments, watch, setValue }: SectionIntakeProps) => {
  const intakeInitiatedBy = useField(fieldArguments('intakeInitiatedBy', 'Intake Initiated By'));
  const callDate = useField(
    fieldArguments('intakeDate', 'Intake Date', {
      value: new RegExp('^(19|20)?[0-9]{2}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$'),
      message: 'Invalid Date',
    }),
  );
  const note = useField(fieldArguments('intakeNote', 'Intake Notes'));

  const watchIntakeInitiatedBy = watch('intakeInitiatedBy');

  const intakeInitiationOptions = [
    OptionFactory.createOption('not-specified', 'Not Specified', 'not-specified'),
    OptionFactory.createOption('patient', 'Patient Called', 'patientCall'),
    OptionFactory.createOption('attorney', 'Attorney Called', 'attorneyCall'),
  ];

  return (
    <div className="gap-y-2 gap-x-2">
      <div className="grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-9">
        <div className="sm:col-span-4">
          <div className="grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-9">
            <div className="sm:col-span-4">
              <ListBoxGroupV2
                {...intakeInitiatedBy}
                options={intakeInitiationOptions}
                value={watchIntakeInitiatedBy}
                absolutePositioning={true}
                tooltip="How did the intake process start?"
              />
            </div>
            <div className="sm:col-span-4">
              <InputGroup
                {...callDate}
                type="date"
                placeholder=""
                tooltip="When was the conversation about this injury/patient? Most likely today."
              />
            </div>
            <div className="sm:col-span-8">
              <SectionDivider className="mt-2" />
            </div>
          </div>
        </div>

        <div className="sm:col-span-5">
          <div className="grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-1">
            <div className="sm:col-span-1">
              <TextAreaGroup {...note} placeholder="Something about the intake..." rows={3} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
