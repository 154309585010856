import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';

export type InitialAppointmentScheduleQueueTask = {
  id: string;
  patientId: string;
  createdAt: string;
  organizationId: string;
  priorityTimestamp: string;
  patientFirstName: string;
  patientLastName: string;
  patientBirthDate: string;
  patientPhone1: string;
  patientPhone2: string;
  patientDisplayLongReverse: string;
  patientDisplayLong: string;
  patientDisplayShortReverse: string;
  patientDisplayShort: string;
  updatedAt?: string;
  patientClinicLocationId: string;
  patientLawFirmId: string;
};

export const useGetInitialAppointmentQueueTasks = (enabled: boolean) => {
  const { computeClient: client } = useApiClient();
  return useQuery(
    ['schedule-queue/initial-appointments'],
    () =>
      client
        .get<InitialAppointmentScheduleQueueTask[]>(`/schedule-queue/initial-appointments`, {})
        .then(response => response.data),
    { enabled: enabled },
  );
};

export const useDeprioritizeInitialAppointmentQueueTask = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, string>({
    mutationFn: async function (taskId: string) {
      await client.post(`/schedule-queue/initial-appointments/${taskId}/deprioritize`);
    },
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-queue/initial-appointments']);
    },
  });
};

export const useReprioritizeInitialAppointmentQueueTask = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, string>({
    mutationFn: async function (taskId: string) {
      await client.post(`/schedule-queue/initial-appointments/${taskId}/reprioritize`);
    },
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-queue/initial-appointments']);
    },
  });
};

export const useDeleteInitialAppointmentQueueTask = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, string>({
    mutationFn: async function (taskId: string) {
      await client.delete(`/schedule-queue/initial-appointments/${taskId}`);
    },
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-queue/initial-appointments']);
    },
  });
};

export const useInvalidateInitialApptTasks = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries(['schedule-queue/initial-appointments']);
  };
};

export const useAddAppointmentTaskInCache = () => {
  const queryClient = useQueryClient();
  return (task: InitialAppointmentScheduleQueueTask) => {
    queryClient.setQueryData<InitialAppointmentScheduleQueueTask[]>(['schedule-queue/initial-appointments'], old => {
      if (!old) {
        return [task];
      }
      if (old.find(t => t.id === task.id)) {
        return old;
      }
      return [...old, task];
    });
  };
};

export const useUpdateAppointmentTaskInCache = () => {
  const queryClient = useQueryClient();
  return (task: InitialAppointmentScheduleQueueTask) => {
    queryClient.setQueryData<InitialAppointmentScheduleQueueTask[]>(['schedule-queue/initial-appointments'], old => {
      if (!old) {
        return old;
      }
      const index = old.findIndex(t => t.id === task.id);
      if (index === -1) {
        return old;
      }
      if (!old[index].updatedAt || new Date(old[index].updatedAt!) < new Date(task.updatedAt!)) {
        const newTasks = [...old];
        newTasks[index] = task;
        return newTasks;
      }
      console.log('Not replacing!!!');
      return old;
    });
  };
};

export const useDeleteAppointmentTaskInCache = () => {
  const queryClient = useQueryClient();
  return (task: { id: string }) => {
    queryClient.setQueryData<InitialAppointmentScheduleQueueTask[]>(['schedule-queue/initial-appointments'], old => {
      if (!old) {
        return old;
      }
      const index = old.findIndex(t => t.id === task.id);
      if (index === -1) {
        return old;
      }
      const newTasks = [...old];
      newTasks.splice(index, 1);
      return [...newTasks];
    });
  };
};
