import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useGetAppointmentReminders } from 'app/api/AppointmentReminderApi';
import { Patient } from 'app/api/PatientApi';
import { LoadingAnimated } from 'app/components/LoadingAnimated';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { SmsMessage, useGetOneOffSMSMessages } from '../../api/SmsApi';

export type CommunicationListTableProps = {
  patient: Patient;
};
export const CommunicationListTable = ({ patient }: CommunicationListTableProps) => {
  const { data: reminders, isLoading: remindersIsLoading } = useGetAppointmentReminders(patient);
  const { data: oneOffs, isLoading: oneOffsIsLoading } = useGetOneOffSMSMessages(patient.id);

  const data: SmsMessage[] = useMemo(() => [...(reminders ?? []), ...(oneOffs ?? [])], [reminders, oneOffs]);
  const isLoading = useMemo(() => remindersIsLoading && oneOffsIsLoading, [remindersIsLoading, oneOffsIsLoading]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const columnHelper = createColumnHelper<SmsMessage>();
  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row.appointmentDate, {
        id: 'appointmentDate',
        cell: info => info.getValue(),
        header: () => <span>Appointment Date</span>,
      }),
      columnHelper.accessor(row => row.sentAt, {
        id: 'sentAt',
        cell: info => {
          const val = info.getValue();
          if (!val) {
            return null;
          }
          return new Date(val).toLocaleString();
        },
        header: () => <span>Sent At</span>,
      }),
      columnHelper.accessor(row => row.toNumber, {
        id: 'to',
        cell: info => info.getValue(),
        header: () => <span>To</span>,
      }),
      columnHelper.accessor(row => row.body, {
        id: 'body',
        cell: info => info.getValue(),
        header: () => <span>Body</span>,
      }),
      columnHelper.accessor(row => row.status, {
        id: 'status',
        cell: info => info.getValue(),
        header: () => <span>Status</span>,
      }),
      columnHelper.accessor(row => row.errorMsg, {
        id: 'error',
        cell: info => {
          const val = info.getValue();
          if (val?.startsWith('http')) {
            return (
              <>
                <a target="_blank" href={val} rel="noreferrer">
                  {val} <FontAwesomeIcon icon={faExternalLink} />
                </a>
              </>
            );
          }
          return val;
        },
        header: () => <span>Error</span>,
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      {isLoading && <LoadingAnimated size={32} />}
      {!isLoading && data && data.length === 0 && <div className="mt-2 italic">No records</div>}
      {!isLoading && data && data.length > 0 && (
        <>
          <div className="mt-4 flow-root">
            <div className="inline-block min-w-full py-2 align-middle">
              <table className="min-w-full border-separate border-spacing-0">
                <thead>
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th
                          key={header.id}
                          scope="col"
                          className={classNames(
                            'sticky top-0 z-10 border-b border-gray-300 bg-white  py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8',
                            header.column.getCanSort() ? 'cursor-pointer select-none' : 'cursor-default',
                          )}
                        >
                          {header.isPlaceholder ? null : (
                            <div onClick={header.column.getToggleSortingHandler()}>
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {{
                                asc: ' 🔼',
                                desc: ' 🔽',
                              }[header.column.getIsSorted() as string] ?? null}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody>
                  {table.getRowModel().rows.map((row, index) => (
                    <tr key={row.id} className="hover:bg-sky-100 cursor-pointer">
                      {row.getVisibleCells().map(cell => (
                        <td
                          key={cell.id}
                          className={classNames(
                            index !== table.getRowModel().rows.length - 1 ? 'border-b border-gray-200' : '',
                            'whitespace-pre-wrap py-1 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                          )}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
