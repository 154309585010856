import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  ColumnFiltersState,
  Table,
  ColumnSort,
} from '@tanstack/react-table';
import { LawOffice } from 'app/api/LawOfficeApi';
import { LawOfficeForm } from 'app/components/Auxillary/LawOfficeForm';
import { FormActionProps } from 'app/components/Forms/FormActionProps';
import React, { useMemo, useState } from 'react';
import { useFlexworxAnciallry } from 'utils/flexworx-ancillary';
import { SimpleItemNewButton } from '../../components/Forms/SimpleItemNewButton';
import { SimpleItemEditModal } from '../../components/Forms/SimpleItemEditModal';
import { SimpleItemList } from '../../components/Forms/SimpleItemList';
import { fuzzyFilter } from './TreatmentCenterList';

type HydratedLawOffice = LawOffice & {
  lawFirmName: string;
  address: string;
};

export const LawOfficeList = ({
  onSelect,
  allowNew,
  initialLawFirmId,
}: {
  onSelect?: (lawOffice: LawOffice) => void;
  allowNew: boolean;
  initialLawFirmId?: string | null;
}) => {
  const { lawOffices: _lawOffices, lawFirms, isError, isLoading, lawFirmFromId } = useFlexworxAnciallry();
  const [sorting, setSorting] = useState<ColumnSort[]>(() => {
    return [{ id: 'lawFirmName', desc: false }];
  });

  const columnHelper = createColumnHelper<HydratedLawOffice>();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(() => {
    const initialState: ColumnFiltersState = [];
    if (initialLawFirmId) {
      const initialLawFirm = lawFirmFromId(initialLawFirmId);
      initialState.push({
        id: 'lawFirmName',
        value: initialLawFirm?.name ?? '',
      });
    }
    return initialState;
  });

  const lawOffices: null | HydratedLawOffice[] = useMemo(() => {
    if (!_lawOffices) return null;
    return _lawOffices.map(lawOffice => {
      const lawFirm = lawFirmFromId(lawOffice.lawFirmId);
      let address = lawOffice.addressLine1 ?? '';
      if (lawOffice.addressLine2) {
        address += '\n' + lawOffice.addressLine2;
      }

      const hydratedLawOffice: HydratedLawOffice = {
        ...lawOffice,
        address,
        lawFirmName: lawFirm?.name ?? '',
      };
      return hydratedLawOffice;
    });
  }, [_lawOffices, lawFirmFromId]);

  const getLawOfficeDescription = (lawOffice: LawOffice) => {
    const lawFirm = lawFirms?.find(lawFirm => lawFirm.id === lawOffice.lawFirmId);
    if (!lawFirm) return '';
    return `${lawFirm.name} - ${lawOffice.city}, ${lawOffice.state}`;
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row.lawFirmName, {
        id: 'lawFirmName',
        cell: info => info.getValue(),
        header: () => <span>Name</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.address, {
        id: 'address',
        cell: info => <div className="whitespace-pre-wrap">{info.getValue()}</div>,
        header: () => <span>Address</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.city, {
        id: 'city',
        cell: info => info.getValue(),
        header: () => <span>City</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.state, {
        id: 'state',
        cell: info => info.getValue(),
        header: () => <span>State</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.phone, {
        id: 'phone',
        cell: info => info.getValue(),
        header: () => <span>Phone</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
    ],
    [columnHelper],
  );

  const table: Table<HydratedLawOffice> = useReactTable({
    data: lawOffices ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const createForm = (props: FormActionProps<LawOffice>) => <LawOfficeForm {...props} />;
  const editForm = (item: LawOffice, props: FormActionProps<LawOffice>) => <LawOfficeForm defaultValues={item} {...props} />;

  const [editModalIsVisible, setEditModalIsVisible] = useState(false);
  const [editModalItem, setEditModalItem] = useState<LawOffice | null>(null);
  const onClick = (item: LawOffice) => {
    if (onSelect) {
      onSelect(item);
      return;
    }
    setEditModalItem(item);
    setEditModalIsVisible(true);
  };
  return (
    <>
      {allowNew && (
        <SimpleItemNewButton children={createForm} headerSingular="Law Office" getName={item => getLawOfficeDescription(item)} />
      )}

      <SimpleItemEditModal
        children={props => editForm(editModalItem!, props)}
        headerSingular="Law Office"
        getName={(item: LawOffice) => getLawOfficeDescription(item)}
        closeModal={() => setEditModalIsVisible(false)}
        isVisible={editModalIsVisible}
      />

      <SimpleItemList table={table} isError={isError} isLoading={isLoading} headerPlural="Law Offices" onClick={onClick} />
    </>
  );
};
