import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useMemo } from 'react';
import throttle from './throttle';
import { useToast } from './toast';

export function QueryClientWrapper({ children }) {
  const { toastError } = useToast();

  const toast401 = useMemo(() => {
    return throttle((main: string, secondary: string) => toastError(main, secondary), 10000)[0];
  }, [toastError]);

  const toast412 = useMemo(() => {
    return throttle((main: string, secondary: string) => toastError(main, secondary), 10000)[0];
  }, [toastError]);

  const onError = useMemo(() => {
    return async (error: AxiosError | string | unknown) => {
      if ((error as AxiosError)?.response?.status === 401) {
        await toast401('Not authenticated.', 'Please sign in again.');
      }
      if ((error as AxiosError)?.response?.status === 412) {
        await toast412('User setup error', 'Ensure the administrator has assigned your organization.');
      }
    };
  }, [toast401, toast412]);

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError: async error => {
              try {
                await onError(error);
              } catch (e) {
                if (e === 'throttled') {
                  return;
                }
                throw e;
              }
            },
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
