import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import {
  TemporaryNote,
  useAddTemporaryNoteInCache,
  useDeleteTemporaryNoteInCache,
  useGetTemporaryNotesBySchedule,
  useUpdateTemporaryNoteInCache,
} from 'app/api/TemporaryNoteApi';
import { useWebsockets } from 'utils/websocket';
import { useAuth } from 'utils/auth';

export interface ITemporaryNotesContext {
  temporaryNotes: TemporaryNote[] | null;
  isLoading: boolean;
  isError: boolean;
}

const TemporaryNotesContext = createContext<ITemporaryNotesContext>({
  temporaryNotes: null,
  isLoading: false,
  isError: false,
});

const sortByTime = (a: TemporaryNote, b: TemporaryNote) => {
  // sort baed on a string field called "time" formatted like HH:mm
  const aTime = a.startTime.split(':');
  const bTime = b.startTime.split(':');

  if (aTime[0] > bTime[0]) {
    return 1;
  }
  if (aTime[0] < bTime[0]) {
    return -1;
  }
  if (aTime[1] > bTime[1]) {
    return 1;
  }
  if (aTime[1] < bTime[1]) {
    return -1;
  }
  if (a.header ?? '' > (b.header ?? '')) {
    return 1;
  }
  if (a.header ?? '' < (b.header ?? '')) {
    return -1;
  }
  if (a.body > b.body) {
    return 1;
  }
  if (a.body < b.body) {
    return -1;
  }
  return 0;
};

export function TemporaryNotesProvider({
  scheduleDefinitionId,
  date,
  children,
}: {
  scheduleDefinitionId: string | null | undefined;
  date: string | null | undefined;
  children: JSX.Element;
}) {
  const { user } = useAuth();

  const [fetchData, setFetchData] = useState(false);
  const { lastTemporaryNoteMessage } = useWebsockets();

  const {
    data: temporaryNotes,
    isLoading: temporaryNotesIsLoading,
    isError: temporaryNotesIsError,
  } = useGetTemporaryNotesBySchedule(fetchData, scheduleDefinitionId, date);

  const addTemporaryNote = useAddTemporaryNoteInCache();
  const updateTemporaryNote = useUpdateTemporaryNoteInCache();
  const deleteTemporaryNote = useDeleteTemporaryNoteInCache();

  useEffect(() => {
    if (!lastTemporaryNoteMessage) {
      return;
    }
    switch (lastTemporaryNoteMessage.action) {
      case 'create':
        addTemporaryNote(lastTemporaryNoteMessage.model);
        break;
      case 'update':
        updateTemporaryNote(lastTemporaryNoteMessage.model);
        break;
      case 'delete':
        deleteTemporaryNote(lastTemporaryNoteMessage.model);
        break;
      case 'unknown':
      default:
        console.error('Unknown appointment action type');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTemporaryNoteMessage]);

  useEffect(() => {
    if (user) {
      setFetchData(true);
    }
  }, [user]);

  useEffect(() => {
    if (!lastTemporaryNoteMessage) {
      return;
    }

    console.log('Handling Temporary Note Message in the hook', lastTemporaryNoteMessage);
    // if (temporaryNotes && scheduleDefinitionId && date) {
    //   const indexOfLastProcessed = temporaryNoteLedger.findIndex(x => x.offset === temporaryNoteLedgerOffset);
    //   const unprocessedLedgerEntries = temporaryNoteLedger.slice(indexOfLastProcessed + 1);
    //   const unprocessedTasks = unprocessedLedgerEntries.map(x => x.event);
    //   const appts = processTemporaryNotes(scheduleDefinitionId, date, temporaryNotes, unprocessedTasks);
    //   setTemporaryNotes(appts);
    //   if (unprocessedLedgerEntries.length === 0) {
    //     return;
    //   }
    //   const newOffset = unprocessedLedgerEntries[unprocessedLedgerEntries.length - 1].offset;
    //   setTemporaryNoteLedgerOffset(newOffset);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastTemporaryNoteMessage]);

  const providerValue = {
    temporaryNotes: temporaryNotes?.sort(sortByTime) ?? [],
    isLoading: temporaryNotesIsLoading,
    isError: temporaryNotesIsError,
  };

  return <TemporaryNotesContext.Provider value={providerValue}>{children}</TemporaryNotesContext.Provider>;
}

export function useTemporaryNotesContext() {
  return useContext(TemporaryNotesContext);
}
