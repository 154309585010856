import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getFilteredRowModel,
  ColumnFiltersState,
  ColumnSort,
} from '@tanstack/react-table';
import { LawFirm } from 'app/api/LawFirmApi';
import { LawFirmForm } from 'app/components/Auxillary/LawFirmForm';
import { FormActionProps } from 'app/components/Forms/FormActionProps';
import { PagedItemList } from 'app/components/Forms/PagedItemList';
import React, { useMemo, useState } from 'react';
import { useFlexworxAnciallry } from 'utils/flexworx-ancillary';
import { fuzzyFilter } from './TreatmentCenterList';
import { SimpleItemNewButton } from '../../components/Forms/SimpleItemNewButton';
import { SimpleItemEditModal } from '../../components/Forms/SimpleItemEditModal';

type HydratedLawFirm = LawFirm & {
  lawOfficeNames: string;
};

export const LawFirmList = ({ onSelect, allowNew }: { onSelect?: (lawFirm: LawFirm) => void; allowNew: boolean }) => {
  const { lawOffices, lawFirms: _lawfirms, lawOfficesFromIds, isError, isLoading } = useFlexworxAnciallry();

  const [sorting, setSorting] = useState<ColumnSort[]>(() => {
    return [{ id: 'name', desc: false }];
  });

  const lawFirms = useMemo(() => {
    if (!_lawfirms || !lawOffices) {
      return [];
    }

    return _lawfirms.map(lawFirm => {
      const lawOffices = lawOfficesFromIds(lawFirm.lawOfficeIds);
      const lawOfficeNames = lawOffices
        .map(lawOffice => `${lawOffice.city}, ${lawOffice.state}`)
        .sort()
        .join('\n');
      return { ...lawFirm, lawOfficeNames };
    });
  }, [_lawfirms, lawOffices, lawOfficesFromIds]);

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

  const columnHelper = createColumnHelper<HydratedLawFirm>();
  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row.name, {
        id: 'name',
        cell: info => info.getValue(),
        header: () => <span>Name</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.lawOfficeNames, {
        id: 'lawOfficeNames',
        cell: info => <div className="whitespace-pre">{info.getValue()}</div>,
        header: () => <span>Locations</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: lawFirms ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const createForm = (props: FormActionProps<LawFirm>) => <LawFirmForm {...props} />;
  const editForm = (item: LawFirm, props: FormActionProps<LawFirm>) => <LawFirmForm defaultValues={item} {...props} />;

  const [editModalIsVisible, setEditModalIsVisible] = useState(false);
  const [editModalItem, setEditModalItem] = useState<LawFirm | null>(null);
  const onClick = (item: LawFirm) => {
    if (onSelect) {
      onSelect(item);
      return;
    }
    setEditModalItem(item);
    setEditModalIsVisible(true);
  };
  return (
    <>
      {allowNew && <SimpleItemNewButton children={createForm} headerSingular="Law Firm" getName={(item: LawFirm) => item.name} />}
      <SimpleItemEditModal
        children={props => editForm(editModalItem!, props)}
        headerSingular="Law Firm"
        getName={(item: LawFirm) => item.name}
        closeModal={() => setEditModalIsVisible(false)}
        isVisible={editModalIsVisible}
      />
      <PagedItemList table={table} isError={isError} isLoading={isLoading} headerPlural="Law Firms" onClick={onClick} />
    </>
  );
};
