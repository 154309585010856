import classNames from 'classnames';
import React, { useId } from 'react';
import { Tooltip as Tooltipp } from 'react-tooltip';

export type TooltipProps = {
  children: JSX.Element;
  content: string;
  className?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};

const defaultPosition = 'left';

// For some reason, when a tooltip is on a table header, it will be on top of the headers to the left,
// but behind the headers to the right. So we should just avoid tooltips on table headers for now.
export const Tooltip = ({ children, content, className, placement = defaultPosition }: TooltipProps) => {
  const id = useId();
  return (
    <>
      <span className={classNames('', className)}>
        <span
          data-tooltip-id={id}
          data-tooltip-content={content}
          data-tooltip-place={placement}
          data-tooltip-position-strategy={'fixed'}
        >
          {children}
        </span>
      </span>
      <Tooltipp id={id} />
    </>
  );
};

export const InfoTip = ({ content, className }: { content: string; className?: string }) => {
  return (
    // <Tooltip className={className} content={content}>
    //   <span className="p-1">
    //     <FontAwesomeIcon className="h-4 w-4 text-indigo-900" icon={faInfoCircle} aria-hidden="true" />
    //   </span>
    // </Tooltip>
    <div className="text-xs text-gray-500 font-light">{content}</div>
  );
};
