import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import { useLocationStorage } from '../../../utils/useLocationStorage';
import { ScheduleState, useSessionStorage } from '../../../utils/sessionStorage';
import { ScheduleDefinition } from '../../api/ScheduleDefinitionApi';
import { Appointment } from '../../api/AppointmentApi';

export interface IScheduleDisplayLaunchPadContext {
  activeBucketKey: string | null;
  setActiveBucketKey: (key: string | null) => void;
  date: string; // YYYY-MM-DD
  setDate: (date: string) => void;
  scheduleDefinitionId: string | null;
  setScheduleDefinition: (scheduleDefinition: ScheduleDefinition | null) => void;
  time: string;
  setTime: (time: string) => void;
  appointmentId: string | null;
  setAppointment: (appointment: Appointment) => void;
}

const ScheduleDisplayLaunchPadContext = createContext<IScheduleDisplayLaunchPadContext>({
  date: '',
  setDate: () => {},
  scheduleDefinitionId: null,
  setScheduleDefinition: () => {},
  time: '',
  setTime: () => {},
  activeBucketKey: '',
  setActiveBucketKey: () => {},
  appointmentId: '',
  setAppointment: () => {},
});

export type ScheduleDisplayLaunchPadProviderProps = {
  children: React.ReactNode;
};

export function ScheduleDisplayLaunchPadProvider({ children }: ScheduleDisplayLaunchPadProviderProps) {
  const [hashParams, setHashParam, setHashParams] = useLocationStorage();
  const { getScheduleState: getStateFromStorage, setScheduleState: persistStateInStorage } = useSessionStorage();

  const [activeBucketKey, setActiveBucketKey] = useState<string | null>(null);

  const [scheduleState, setScheduleState] = useState(() => {
    // On render, we're going to first check the query params, then the storage.
    const state = getStateFromStorage();
    state.appointmentId = hashParams['appointment'] ?? state.appointmentId;
    state.time = hashParams['time'] ?? state.time;
    state.date = hashParams['date'] ?? state.date;
    state.scheduleDefinitionId = hashParams['schedule'] ?? state.scheduleDefinitionId;

    return state;
  });

  const updateState = (update: (state: ScheduleState) => void) => {
    // update persisted state
    const state = getStateFromStorage();
    update(state);
    persistStateInStorage(state);

    // update local state
    setScheduleState(prev => {
      update(prev);
      return prev;
    });
  };

  const setScheduleDefinition = (scheduleDefinition: ScheduleDefinition | null) => {
    setHashParam('schedule', scheduleDefinition?.id ?? null);
    updateState(s => (s.scheduleDefinitionId = scheduleDefinition?.id ?? null));
  };

  const setDate = (date: string) => {
    setHashParam('date', date);
    updateState(s => (s.date = date));
  };

  const setTime = (time: string) => {
    delete hashParams['appointment'];
    hashParams['time'] = time;
    setHashParams(hashParams);

    updateState(s => {
      s.time = time;
      s.appointmentId = null;
    });
  };

  const setAppointment = (appointment: Appointment) => {
    hashParams['appointment'] = appointment.id;
    hashParams['time'] = appointment.appointmentTime;
    hashParams['date'] = appointment.appointmentDate;
    hashParams['schedule'] = appointment.scheduleDefinitionId;
    setHashParams(hashParams);

    updateState(s => {
      s.appointmentId = appointment.id;
      s.time = appointment.appointmentTime;
      s.date = appointment.appointmentDate;
      s.scheduleDefinitionId = appointment.scheduleDefinitionId;
    });
  };

  useEffect(() => {
    hashParams['appointment'] = scheduleState.appointmentId ?? '';
    hashParams['time'] = scheduleState.time;
    hashParams['date'] = scheduleState.date;
    hashParams['schedule'] = scheduleState.scheduleDefinitionId ?? '';
    setHashParams(hashParams);
    updateState(s => {
      s.appointmentId = scheduleState.appointmentId;
      s.time = scheduleState.time;
      s.date = scheduleState.date;
      s.scheduleDefinitionId = scheduleState.scheduleDefinitionId;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ScheduleDisplayLaunchPadContext.Provider
      value={{
        date: scheduleState.date,
        setDate: setDate,
        activeBucketKey: activeBucketKey,
        setActiveBucketKey: setActiveBucketKey,
        scheduleDefinitionId: scheduleState.scheduleDefinitionId,
        setScheduleDefinition: setScheduleDefinition,
        time: scheduleState.time,
        setTime: setTime,
        appointmentId: scheduleState.appointmentId,
        setAppointment: setAppointment,
      }}
    >
      {children}
    </ScheduleDisplayLaunchPadContext.Provider>
  );
}

export const useScheduleLaunchPadProvider = () => {
  return useContext(ScheduleDisplayLaunchPadContext);
};
