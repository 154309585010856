import { PatientFilteredQuery } from 'app/api/PatientApi';
import { createContext, useContext, useState } from 'react';
import React from 'react';

export interface IPatientDirectoryContext {
  patientFilter: PatientFilteredQuery;
  setPatientFilter: (patientFilter: PatientFilteredQuery) => void;
  resetPatientFilter: () => void;
}

const PatientDirectoryContext = createContext<IPatientDirectoryContext>({
  patientFilter: {},
  setPatientFilter: () => {},
  resetPatientFilter: () => {},
});

export function PatientDirectoryProvider({ children }: { children: React.ReactNode }) {
  const [patientFilter, setPatientFilter] = useState<PatientFilteredQuery>({});

  return (
    <PatientDirectoryContext.Provider
      value={{
        patientFilter,
        setPatientFilter,
        resetPatientFilter: () => setPatientFilter({}),
      }}
    >
      {children}
    </PatientDirectoryContext.Provider>
  );
}

export const usePatientDirectoryProvider = () => {
  return useContext(PatientDirectoryContext);
};
