import React, { MouseEvent } from 'react';
import classNames from 'classnames';
import { flexRender, Row } from '@tanstack/react-table';
import { Appointment } from '../../api/AppointmentApi';
import { getContrastYIQ } from '../../components/Schedule/BucketOperations';
import { useFlexworxConfig } from '../../../utils/flexworx-config';

export type AppointmentListRowProps = {
  appointment: Appointment;
  renderPrintLayout: boolean;
  onRightClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, appointment: Appointment) => void;
  onSingleClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, appointment: Appointment) => void;
  onDoubleClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, appointment: Appointment) => void;
  row: Row<Appointment>;
  isLast: boolean;
  isActive: boolean;
  onGoToSchedule: (appointment: Appointment) => void;
};

export const AppointmentListRow = ({
  appointment,
  renderPrintLayout,
  onRightClick,
  row,
  isLast,
  onSingleClick,
  isActive,
  onGoToSchedule,
}: AppointmentListRowProps) => {
  const { getColorRgbForAppointmentStatus, getColorHexForAppointmentStatus } = useFlexworxConfig();

  // const rowRef = useRef<HTMLTableRowElement>(null);

  // useDoubleClick({
  //   onSingleClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>) => {
  //     onSingleClick(e, appointment);
  //   },
  //   onDoubleClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>) => {
  //     onDoubleClick(e, appointment);
  //   },
  //   ref: rowRef,
  //   latency: 250,
  // });

  const getRowStyle = (appointment: Appointment) => {
    const rbg = getColorRgbForAppointmentStatus(appointment.appointmentStatusId);
    return {
      backgroundColor: rbg ?? '',
    };
  };

  const getRowTextColor = (appointment: Appointment) => {
    const hex = getColorHexForAppointmentStatus(appointment.appointmentStatusId);
    if (!hex) {
      return 'text-gray-900';
    }
    const contrast = getContrastYIQ(hex);
    if (contrast === 'black') {
      return 'text-gray-900';
    }
    return 'text-white';
  };

  const useActiveStyle = !renderPrintLayout && isActive;

  const goToSchedule = (e: MouseEvent<HTMLTableRowElement>) => {
    onGoToSchedule(appointment);
  };

  return (
    <>
      <tr
        className="group hover:bg-sky-100 cursor-pointer"
        onDoubleClick={e => goToSchedule(e)}
        onContextMenu={e => onRightClick(e, row.original)}
        // ref={rowRef}
        onClick={e => onSingleClick(e, row.original)}
      >
        {useActiveStyle &&
          row.getVisibleCells().map(cell => (
            <td
              key={cell.id}
              className={classNames(
                isLast ? '' : 'border-b border-gray-200',
                'whitespace-nowrap p-1 text-sm font-medium ',
                'text-white bg-gray-600',
              )}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        {!useActiveStyle &&
          row.getVisibleCells().map(cell => (
            <td
              key={cell.id}
              style={getRowStyle(row.original)}
              className={classNames(
                isLast ? '' : 'border-b border-gray-200',
                'whitespace-nowrap p-1 text-sm font-medium text-gray-900',
                getRowTextColor(row.original),
              )}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
      </tr>
    </>
  );
};
