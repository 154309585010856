import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment } from 'react';

type BaseModalProps = {
  children: JSX.Element;
  width: string;
  closeModalText: string;
  closeModal: () => void;
  header?: string | JSX.Element;
};

export type ModalProps = BaseModalProps & {
  open: boolean;
};

type ModalHelperProps = BaseModalProps & {
  afterLeave: () => void;
  isOpen: boolean;
};

const ModalHelper = ({
  children,
  width,
  closeModalText,
  isOpen: open,
  closeModal,
  afterLeave,
  header: _header,
}: ModalHelperProps) => {
  const panelClass = classNames(
    'relative flex flex-col h-full transform overflow-y-scroll rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6',
    width, // ? 'sm:max-w-7xl' : 'sm:max-w-sm',
  );

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={afterLeave}>
      <Dialog as="div" className="relative z-modal" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-modal overflow-y-auto md:mt-12">
          <div className="flex md:6xl md:h-[calc(100%-4rem)] items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={panelClass}>
                <div className={classNames('px-1 min-h-0 flex-1 flex-col overflow-y-auto bg-white')}>{children}</div>
                <div className={classNames('flex justify-end bottom-0 mt-4 bg-white w-xl')}>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={closeModal}
                  >
                    {closeModalText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export const Modal = ({ children, width, closeModalText, open, closeModal, header }: ModalProps) => {
  const afterLeave = () => {};
  return (
    <ModalHelper
      {...{
        width,
        closeModalText,
        isOpen: open,
        closeModal,
        afterLeave,
        header,
      }}
    >
      {children}
    </ModalHelper>
  );
};
