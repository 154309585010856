import { useLocation } from 'react-router-dom';
import { useState } from 'react';

type RouteHashDictionary = {
  [key: string]: string;
};

const parseHash: (hash: string) => RouteHashDictionary = hash => {
  if (hash[0] === '#') {
    hash = hash.substring(1);
  }

  if (hash === '') {
    return {};
  }

  const pieces = hash.split('&');
  const dictionary: RouteHashDictionary = {};
  for (const piece of pieces) {
    const parts = piece.split('=');
    dictionary[parts[0]] = parts[1];
  }
  return dictionary;
};

const stringifyHash: (hash: RouteHashDictionary) => string = hash => {
  let hashString = '';
  for (const key in hash) {
    if (hashString !== '') {
      hashString += '&';
    }
    hashString += `${key}=${hash[key]}`;
  }
  if (hashString.length > 0) {
    hashString = '#' + hashString;
  }
  return hashString;
};

// We're going to read and write to hash values on the url.
// Setting the value will not change the route.
export const useLocationStorage = () => {
  const location = useLocation();

  const [hashParams, _setHashParams] = useState<RouteHashDictionary>(parseHash(location.hash));

  const setHashParam = (key: string, value: string | null) => {
    if (value) {
      hashParams[key] = value;
    } else {
      delete hashParams[key];
    }
    setHashParams(hashParams);
  };

  const setHashParams = (params: RouteHashDictionary) => {
    for (const key in params) {
      if (params[key] === '') {
        delete params[key];
      }
    }
    window.location.replace(stringifyHash(params));
    _setHashParams(params);
  };

  return [hashParams, setHashParam, setHashParams] as const;
};
