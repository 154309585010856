import React from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import classnames from 'classnames';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type NotificationProps = {
  startPause: () => void;
  endPause: () => void;
  message: string | JSX.Element | null;
  icon: IconDefinition;
  accentColor: string;
};

export function BuildNotificationMessage(main: JSX.Element | string, secondary?: JSX.Element | string) {
  return (
    <div className="ml-3 w-0 flex-1 pt-0.5">
      <p className="text-sm font-medium text-gray-900">{main}</p>
      {secondary && <p className="mt-1 text-sm text-gray-500">{secondary}</p>}
    </div>
  );
}

export function Notification({ startPause, endPause, message, icon, accentColor }: NotificationProps) {
  const [show, setShow] = useState(true);

  return (
    <>
      {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
      <Transition
        show={show}
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          onMouseEnter={startPause}
          onMouseLeave={endPause}
          className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div className="p-4">
            <div className="flex items-start">
              <div className="flex-shrink-0">
                <FontAwesomeIcon icon={icon} className={classnames('h-6 w-6', accentColor)} aria-hidden="true" />
              </div>
              <>{message}</>
              <div className="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <FontAwesomeIcon icon={faXmark} className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}
