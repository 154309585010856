import React from 'react';
import { ValidationFailure } from './Validation';
import { FieldValues, UseFormSetError } from 'react-hook-form';

export type EnterFormProps = {
  children: JSX.Element | JSX.Element[];
  className?: string;
  submitForm?: () => Promise<void>;
  setError: UseFormSetError<FieldValues>;
  onUnrecoverableError: (message: string | JSX.Element) => void;
  setDisabled: (disabled: boolean) => void;
  onSubmit?: () => void;
};

export const getClosestLabel: (element: HTMLElement) => HTMLLabelElement | undefined = element => {
  let node = element.parentElement;
  const max = 10;
  let remaining = max;

  while (node && remaining > 0) {
    if (node.getElementsByTagName('label')[0]) {
      return node.getElementsByTagName('label')[0];
    }
    node = node.parentElement;
    remaining -= 1;
  }
};

export const EnterForm = ({
  children,
  className,
  submitForm: _submitForm,
  setError,
  onUnrecoverableError,
  setDisabled: setIsDisabled,
  onSubmit,
}: EnterFormProps) => {
  const submitForm = async e => {
    e.preventDefault();
    onSubmit?.();
    e.stopPropagation();
    setIsDisabled(true);
    try {
      if (_submitForm) {
        await _submitForm();
      }
    } catch (error: any) {
      if (error.response && error.response.status === 422) {
        const validationFailure = error.response.data as ValidationFailure;
        validationFailure?.errors?.forEach(fieldError => {
          setError(fieldError.id, { type: 'manual', message: fieldError.description });
        });
        if (validationFailure?.errors?.length) {
          const error = validationFailure.errors[0];
          const byname = document.getElementsByName(error.id)[0];
          const byid = document.getElementById(error.id);
          if (byname || byid) {
            const label = getClosestLabel(byname || byid);
            if (label) {
              label.scrollIntoView({ behavior: 'smooth' });
            } else {
              (byname || byid).scrollIntoView({ behavior: 'smooth' });
            }
          }
        }
      } else if (error.response && error.response.data && error.response.data.detail && error.response.data.title) {
        const message = `${error.response.data.title}: ${error.response.data.detail}`;
        onUnrecoverableError(message);
      } else {
        try {
          Object.keys(error).forEach(key => {
            setError(key, error[key]);
          });

          // if you want to scroll on front-end validation, you need to add an error handler to the handleSubmit function
          // that throws the error. See AppointmentForm.tsx.
          const key = Object.keys(error)[0];
          const byname = document.getElementsByName(key)[0];
          const byid = document.getElementById(key);

          console.log(key);

          if (byname || byid) {
            const label = getClosestLabel(byname || byid);
            if (label) {
              label.scrollIntoView({ behavior: 'smooth' });
            } else {
              (byname || byid).scrollIntoView({ behavior: 'smooth' });
            }
          }
        } catch (error: any) {
          onUnrecoverableError('Unknown error');
        }
      }
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <form className={className} onSubmit={submitForm}>
      {children}
    </form>
  );
};
