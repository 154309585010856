import classnames from 'classnames';
import React from 'react';
import { useAuth } from 'utils/auth';
import { useWebsockets } from 'utils/websocket';
import { Tooltip } from '../Tooltip';

export function WebsocketStatus() {
  const { isConnecting, isOpen, isClosing, isClosed, isUninstantiated } = useWebsockets();
  const { user } = useAuth();

  let color = 'bg-gray-500';
  let tooltipText = 'Unknown Status';

  if (isConnecting) {
    color = 'bg-yellow-500';
    tooltipText = 'Connecting to real-time server...';
  } else if (isOpen) {
    color = 'bg-green-500 shadow-green-500';
    tooltipText = 'Connected to real-time server.';
  } else if (isClosing) {
    color = 'bg-yellow-500';
    tooltipText = 'Closing connection to real-time server...';
  } else if (isClosed) {
    color = 'bg-red-500';
    tooltipText = 'Not connected to real-time server.';
  } else if (isUninstantiated) {
    tooltipText = 'Starting to connect to real-time server...';
    color = 'bg-gray-500';
  }

  return (
    user && (
      <Tooltip content={tooltipText}>
        <div className={classnames('w-2 h-2 pb-1 rounded-full inline-block mx-2 shadow-glow', color)}></div>
      </Tooltip>
    )

    // <FontAwesomeIcon icon={faCircle} className={classnames('mr-2 text-xs', shadow, color)} />
  );
}
