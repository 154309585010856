import React from 'react';
import { useAuth } from 'utils/auth';
import { useMetadata } from './MetadataApi';

export function Footer() {
  const { user } = useAuth();
  const year = new Date().getFullYear();

  return (
    <footer className="container mx-auto px-4 py-4 print:hidden">
      <div className="flex flex-wrap items-center justify-center">
        <div className="text-sm py-1 px-16 text-center border-t border-t-gray-200 ">
          <div className="text-gray-700">© {year} Flexworx</div>
          <div className=" text-gray-300">
            ui version {process.env.REACT_APP_COMMIT_HASH}
            {user && <ApiCommitHash />}
          </div>
        </div>
      </div>
    </footer>
  );
}

export const ApiCommitHash = () => {
  const { data: metadata } = useMetadata();

  return <>{metadata && <div>api version {metadata.commitHash}</div>}</>;
};
