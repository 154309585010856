import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import { Bucket, calendarLayoutParts } from '../../components/Schedule/BucketOperations';
import { useAppointmentsContext } from '../../components/Schedule/AppointmentsHook';
import { useTemporaryNotesContext } from '../../components/Schedule/TemporaryNotesHook';
import { useFlexworxConfig } from '../../../utils/flexworx-config';
import { buildRowId } from '../../components/Schedule/DayTimeTable';
import debounce from 'lodash/debounce';
import { Appointment } from '../../api/AppointmentApi';

export interface IActiveBucketContext {
  activeBucket: Bucket | null;
  setActiveBucket: (bucket: Bucket, scrollTo: boolean) => void;
  buckets: Bucket[];
  appointmentsIsLoading: boolean;
  scrollToActiveBucket: () => void;
}

const ActiveBucketContext = createContext<IActiveBucketContext>({
  activeBucket: null,
  setActiveBucket: () => {},
  buckets: [],
  appointmentsIsLoading: false,
  scrollToActiveBucket: () => {},
});

export type ActiveBucketProviderProps = {
  children: React.ReactNode;
  onSetActive: (bucket: Bucket) => void;
  time: string | null;
  setTime: (time: string) => void;
  appointmentId: string | null;
  date: string;
  scheduleDefinitionId: string | null;
  setAppointment: (appointment: Appointment) => void;
};

export function ActiveBucketProvider({
  children,
  onSetActive,
  time,
  setTime,
  appointmentId,
  date,
  scheduleDefinitionId,
  setAppointment,
}: ActiveBucketProviderProps) {
  const { appointments, isLoading: appointmentsIsLoading } = useAppointmentsContext();
  const { temporaryNotes } = useTemporaryNotesContext();
  const { scheduleDefinitionFromId } = useFlexworxConfig();
  const ref = React.useRef<HTMLDivElement>(null);

  const [buckets, setBuckets] = useState(() => {
    if (!scheduleDefinitionId) {
      return [];
    }

    const scheduleDefinition = scheduleDefinitionFromId(scheduleDefinitionId)!;

    const selectedDate = new Date(parseInt(date.split('-')[0]), parseInt(date.split('-')[1]) - 1, parseInt(date.split('-')[2]));
    const { buckets } = calendarLayoutParts(appointments, temporaryNotes, scheduleDefinition, selectedDate);
    return buckets;
  });

  // update the buckets when certain params change
  useEffect(() => {
    if (!scheduleDefinitionId) {
      setBuckets([]);
      return;
    }

    const scheduleDefinition = scheduleDefinitionFromId(scheduleDefinitionId)!;
    const selectedDate = new Date(parseInt(date.split('-')[0]), parseInt(date.split('-')[1]) - 1, parseInt(date.split('-')[2]));
    const { buckets } = calendarLayoutParts(appointments, temporaryNotes, scheduleDefinition, selectedDate);
    setBuckets(buckets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments, temporaryNotes, scheduleDefinitionId, date]);

  const [activeBucket, _setActiveBucket] = useState<Bucket | null>(() => {
    if (appointmentId) {
      const appointmentBucket = buckets.filter(x => x.isAppointment()).find(x => x.asAppointment().id === appointmentId);
      if (appointmentBucket) {
        return appointmentBucket;
      }
    }

    if (time) {
      const timeBucket = buckets.find(x => x.time() === time);
      if (timeBucket) {
        return timeBucket;
      }
    }

    return null;
  });

  // update the active bucket when buckets changes
  useEffect(() => {
    if (appointmentId) {
      const appointmentBucket = buckets.filter(x => x.isAppointment()).find(x => x.asAppointment().id === appointmentId);
      if (appointmentBucket) {
        _setActiveBucket(appointmentBucket);
        scrollToBucket(appointmentBucket);
      }
      return;
    }

    if (time) {
      const timeBucket = buckets.find(x => x.time() === time);
      if (timeBucket) {
        _setActiveBucket(timeBucket);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buckets]);

  useEffect(() => {
    if (activeBucket?.time() === time) {
      return;
    }

    if (time) {
      const timeBucket = buckets.find(x => x.time() === time);
      if (timeBucket) {
        _setActiveBucket(timeBucket);
        scrollToBucket(timeBucket);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  const setActiveBucket = (bucket: Bucket, scrollTo: boolean) => {
    _setActiveBucket(bucket);

    if (onSetActive) {
      onSetActive(bucket);
    }

    if (bucket.isAppointment()) {
      setAppointment(bucket.asAppointment());
      return;
    }

    setTime(bucket.time());

    if (scrollTo) {
      scrollToBucket(bucket);
    }
  };

  const [initialLoad, setInitialLoad] = React.useState(true);

  // Scroll to the active bucket when the component mounts
  useEffect(() => {
    if (!activeBucket) {
      return;
    }

    if (initialLoad) {
      const id = buildRowId(activeBucket.key);
      const activeElement = ref.current?.querySelector(`#${id}`);
      if (activeElement) {
        if (document.body.contains(activeElement)) {
          scrollToElement(activeElement);
          setInitialLoad(false);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buckets]);

  useEffect(() => {
    scrollToActiveBucket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleDefinitionId, date, buckets]);

  const _scrollToElement = (element: HTMLElement | Element | null | undefined) => {
    if (element) {
      // if element is not in view, scroll to it
      if (
        element.getBoundingClientRect().top ||
        element.getBoundingClientRect().bottom > window.innerHeight ||
        element.getBoundingClientRect().left < 0 ||
        element.getBoundingClientRect().right > window.innerWidth
      ) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };
  const scrollToElement = debounce(_scrollToElement, 200);

  const scrollToBucket = (bucket: Bucket | null) => {
    if (bucket && buckets.length > 0) {
      const id = buildRowId(bucket.key);
      const activeElement = ref.current?.querySelector(`#${id}`);
      if (activeElement) {
        setInitialLoad(false);
        scrollToElement(activeElement);
      }
    }
  };

  const scrollToActiveBucket = () => {
    scrollToBucket(activeBucket);
  };

  return (
    <ActiveBucketContext.Provider
      value={{
        activeBucket,
        setActiveBucket,
        buckets,
        appointmentsIsLoading,
        scrollToActiveBucket,
      }}
    >
      <div ref={ref} className="w-full">
        {children}
      </div>
    </ActiveBucketContext.Provider>
  );
}

export const useActiveBucketProvider = () => {
  return useContext(ActiveBucketContext);
};
