export type Option<T> = {
  key: string;
  description: string;
  value: T | null;
};

export class OptionFactory {
  static createOption<T>(key: string, description: string, value: T | null): Option<T> {
    return { key, description, value };
  }
}

export const BooleanOptions = [
  OptionFactory.createOption<string>('0', 'No Selection', null),
  OptionFactory.createOption<string>('1', 'Yes', 'true'),
  OptionFactory.createOption<string>('2', 'No', 'false'),
];

export const GeographicalStateOptions = [
  OptionFactory.createOption<string>('0', 'No Selection', null),
  OptionFactory.createOption<string>('1', 'Alabama', 'AL'),
  OptionFactory.createOption<string>('2', 'Alaska', 'AK'),
  OptionFactory.createOption<string>('3', 'Arizona', 'AZ'),
  OptionFactory.createOption<string>('4', 'Arkansas', 'AR'),
  OptionFactory.createOption<string>('5', 'California', 'CA'),
  OptionFactory.createOption<string>('6', 'Colorado', 'CO'),
  OptionFactory.createOption<string>('7', 'Connecticut', 'CT'),
  OptionFactory.createOption<string>('8', 'Delaware', 'DE'),
  OptionFactory.createOption<string>('9', 'District of Columbia', 'DC'),
  OptionFactory.createOption<string>('10', 'Florida', 'FL'),
  OptionFactory.createOption<string>('11', 'Georgia', 'GA'),
  OptionFactory.createOption<string>('12', 'Hawaii', 'HI'),
  OptionFactory.createOption<string>('13', 'Idaho', 'ID'),
  OptionFactory.createOption<string>('14', 'Illinois', 'IL'),
  OptionFactory.createOption<string>('15', 'Indiana', 'IN'),
  OptionFactory.createOption<string>('16', 'Iowa', 'IA'),
  OptionFactory.createOption<string>('17', 'Kansas', 'KS'),
  OptionFactory.createOption<string>('18', 'Kentucky', 'KY'),
  OptionFactory.createOption<string>('19', 'Louisiana', 'LA'),
  OptionFactory.createOption<string>('20', 'Maine', 'ME'),
  OptionFactory.createOption<string>('21', 'Maryland', 'MD'),
  OptionFactory.createOption<string>('22', 'Massachusetts', 'MA'),
  OptionFactory.createOption<string>('23', 'Michigan', 'MI'),
  OptionFactory.createOption<string>('24', 'Minnesota', 'MN'),
  OptionFactory.createOption<string>('25', 'Mississippi', 'MS'),
  OptionFactory.createOption<string>('26', 'Missouri', 'MO'),
  OptionFactory.createOption<string>('27', 'Montana', 'MT'),
  OptionFactory.createOption<string>('28', 'Nebraska', 'NE'),
  OptionFactory.createOption<string>('29', 'Nevada', 'NV'),
  OptionFactory.createOption<string>('30', 'New Hampshire', 'NH'),
  OptionFactory.createOption<string>('31', 'New Jersey', 'NJ'),
  OptionFactory.createOption<string>('32', 'New Mexico', 'NM'),
  OptionFactory.createOption<string>('33', 'New York', 'NY'),
  OptionFactory.createOption<string>('34', 'North Carolina', 'NC'),
  OptionFactory.createOption<string>('35', 'North Dakota', 'ND'),
  OptionFactory.createOption<string>('36', 'Ohio', 'OH'),
  OptionFactory.createOption<string>('37', 'Oklahoma', 'OK'),
  OptionFactory.createOption<string>('38', 'Oregon', 'OR'),
  OptionFactory.createOption<string>('39', 'Pennsylvania', 'PA'),
  OptionFactory.createOption<string>('40', 'Rhode Island', 'RI'),
  OptionFactory.createOption<string>('41', 'South Carolina', 'SC'),
  OptionFactory.createOption<string>('42', 'South Dakota', 'SD'),
  OptionFactory.createOption<string>('43', 'Tennessee', 'TN'),
  OptionFactory.createOption<string>('44', 'Texas', 'TX'),
  OptionFactory.createOption<string>('45', 'Utah', 'UT'),
  OptionFactory.createOption<string>('46', 'Vermont', 'VT'),
  OptionFactory.createOption<string>('47', 'Virginia', 'VA'),
  OptionFactory.createOption<string>('48', 'Washington', 'WA'),
  OptionFactory.createOption<string>('49', 'West Virginia', 'WV'),
  OptionFactory.createOption<string>('50', 'Wisconsin', 'WI'),
  OptionFactory.createOption<string>('51', 'Wyoming', 'WY'),
];

export const MonthOptions = [
  OptionFactory.createOption<string>('0', 'No Selection', null),
  OptionFactory.createOption<string>('1', 'January', '01'),
  OptionFactory.createOption<string>('2', 'February', '02'),
  OptionFactory.createOption<string>('3', 'March', '03'),
  OptionFactory.createOption<string>('4', 'April', '04'),
  OptionFactory.createOption<string>('5', 'May', '05'),
  OptionFactory.createOption<string>('6', 'June', '06'),
  OptionFactory.createOption<string>('7', 'July', '07'),
  OptionFactory.createOption<string>('8', 'August', '08'),
  OptionFactory.createOption<string>('9', 'September', '09'),
  OptionFactory.createOption<string>('10', 'October', '10'),
  OptionFactory.createOption<string>('11', 'November', '11'),
  OptionFactory.createOption<string>('12', 'December', '12'),
];

export const YearOptions = () => {
  const firstYear = new Date().getFullYear() - 2;
  const lastYear = new Date().getFullYear();

  const yearOptions = [OptionFactory.createOption<string>('0', 'No Selection', null)];

  for (let i = firstYear; i <= lastYear; i++) {
    yearOptions.push(OptionFactory.createOption<string>(i.toString(), i.toString(), i.toString()));
  }

  return yearOptions;
};

export const QuartersInADay = [
  { hour: 0, minute: 0 },
  { hour: 0, minute: 15 },
  { hour: 0, minute: 30 },
  { hour: 0, minute: 45 },
  { hour: 1, minute: 0 },
  { hour: 1, minute: 15 },
  { hour: 1, minute: 30 },
  { hour: 1, minute: 45 },
  { hour: 2, minute: 0 },
  { hour: 2, minute: 15 },
  { hour: 2, minute: 30 },
  { hour: 2, minute: 45 },
  { hour: 3, minute: 0 },
  { hour: 3, minute: 15 },
  { hour: 3, minute: 30 },
  { hour: 3, minute: 45 },
  { hour: 4, minute: 0 },
  { hour: 4, minute: 15 },
  { hour: 4, minute: 30 },
  { hour: 4, minute: 45 },
  { hour: 5, minute: 0 },
  { hour: 5, minute: 15 },
  { hour: 5, minute: 30 },
  { hour: 5, minute: 45 },
  { hour: 6, minute: 0 },
  { hour: 6, minute: 15 },
  { hour: 6, minute: 30 },
  { hour: 6, minute: 45 },
  { hour: 7, minute: 0 },
  { hour: 7, minute: 15 },
  { hour: 7, minute: 30 },
  { hour: 7, minute: 45 },
  { hour: 8, minute: 0 },
  { hour: 8, minute: 15 },
  { hour: 8, minute: 30 },
  { hour: 8, minute: 45 },
  { hour: 9, minute: 0 },
  { hour: 9, minute: 15 },
  { hour: 9, minute: 30 },
  { hour: 9, minute: 45 },
  { hour: 10, minute: 0 },
  { hour: 10, minute: 15 },
  { hour: 10, minute: 30 },
  { hour: 10, minute: 45 },
  { hour: 11, minute: 0 },
  { hour: 11, minute: 15 },
  { hour: 11, minute: 30 },
  { hour: 11, minute: 45 },
  { hour: 12, minute: 0 },
  { hour: 12, minute: 15 },
  { hour: 12, minute: 30 },
  { hour: 12, minute: 45 },
  { hour: 13, minute: 0 },
  { hour: 13, minute: 15 },
  { hour: 13, minute: 30 },
  { hour: 13, minute: 45 },
  { hour: 14, minute: 0 },
  { hour: 14, minute: 15 },
  { hour: 14, minute: 30 },
  { hour: 14, minute: 45 },
  { hour: 15, minute: 0 },
  { hour: 15, minute: 15 },
  { hour: 15, minute: 30 },
  { hour: 15, minute: 45 },
  { hour: 16, minute: 0 },
  { hour: 16, minute: 15 },
  { hour: 16, minute: 30 },
  { hour: 16, minute: 45 },
  { hour: 17, minute: 0 },
  { hour: 17, minute: 15 },
  { hour: 17, minute: 30 },
  { hour: 17, minute: 45 },
  { hour: 18, minute: 0 },
  { hour: 18, minute: 15 },
  { hour: 18, minute: 30 },
  { hour: 18, minute: 45 },
  { hour: 19, minute: 0 },
  { hour: 19, minute: 15 },
  { hour: 19, minute: 30 },
  { hour: 19, minute: 45 },
  { hour: 20, minute: 0 },
  { hour: 20, minute: 15 },
  { hour: 20, minute: 30 },
  { hour: 20, minute: 45 },
  { hour: 21, minute: 0 },
  { hour: 21, minute: 15 },
  { hour: 21, minute: 30 },
  { hour: 21, minute: 45 },
  { hour: 22, minute: 0 },
  { hour: 22, minute: 15 },
  { hour: 22, minute: 30 },
  { hour: 22, minute: 45 },
  { hour: 23, minute: 0 },
  { hour: 23, minute: 15 },
  { hour: 23, minute: 30 },
  { hour: 23, minute: 45 },
];
