import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Appointment } from 'app/api/AppointmentApi';
import { useGetPatient, useGetPatientAppointments } from 'app/api/PatientApi';
import { LoadingAnimated } from 'app/components/LoadingAnimated';
import React, { useId, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { AppointmentListTable } from './AppointmentListTable';

type PatientParams = {
  patientId: string;
};

export const appointmentAfterDate = (appointment: Appointment, date: Date) => {
  const [year, month, day] = appointment.appointmentDate.split('-');
  const [hour, minute] = appointment.appointmentTime.split(':');
  const appointmentDate = new Date();
  appointmentDate.setFullYear(parseInt(year));
  appointmentDate.setMonth(parseInt(month) - 1);
  appointmentDate.setDate(parseInt(day));
  appointmentDate.setHours(parseInt(hour));
  appointmentDate.setMinutes(parseInt(minute));
  return appointmentDate > date;
};

export const appointmentFilter = (appointment: Appointment, showPastAppointments: boolean) => {
  if (showPastAppointments) {
    return true;
  }
  const now = new Date();
  return appointmentAfterDate(appointment, now);
};

export const pastAppointmentCount = (appointments: Appointment[]) => {
  const now = new Date();
  return appointments.filter(appointment => !appointmentAfterDate(appointment, now)).length;
};

export const PatientAppointmentPrintableList = () => {
  const { patientId } = useParams<PatientParams>();

  const { data: patient, isLoading: patientIsLoading } = useGetPatient(patientId!);
  const { data: appointments, isLoading: isLoadingAppointments } = useGetPatientAppointments(patientId!);

  const [showPastAppointments, setShowPastAppointments] = React.useState(false);

  const id = useId();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowPastAppointments(e.target.checked);
  };

  const filteredAppointments = appointments?.filter(appointment => appointmentFilter(appointment, showPastAppointments));

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl">
      {patientIsLoading && <LoadingAnimated />}
      {patient && (
        <button
          onClick={() => window.print()}
          className="print:hidden bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
        >
          <FontAwesomeIcon icon={faPrint} className="mr-2" />
          Print
        </button>
      )}
      {patient && (
        <>
          <div>
            <div className="inline-flex justify-between">
              <h1 className="text-2xl font-semibold text-gray-900 mb-4">Appointments for {patient.displayShort}</h1>
            </div>
          </div>
          {isLoadingAppointments && <LoadingAnimated />}
          {appointments && pastAppointmentCount(appointments) > 0 && (
            <div className="relative flex items-start my-4 print:hidden">
              <div className="flex h-5 items-center">
                <input
                  id={id}
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={showPastAppointments}
                  onChange={onChange}
                  autoComplete="off"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor={id} className="font-medium text-gray-700">
                  Show Past Appointments
                </label>
              </div>
            </div>
          )}
          {appointments && appointments.length === 0 && <div className="italic">No appointments yet</div>}
          {appointments && !showPastAppointments && pastAppointmentCount(appointments) > 0 && (
            <div className="italic">{pastAppointmentCount(appointments)} past appointments not shown</div>
          )}
          <div ref={containerRef}>
            <AppointmentListTable
              appointments={filteredAppointments ?? []}
              containerRef={containerRef}
              renderPrintLayout={true}
              onGoToSchedule={() => {}}
              tableContainerClass={''}
            />
          </div>
        </>
      )}
    </div>
  );
};
