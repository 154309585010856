import { TemporaryNote } from 'app/api/TemporaryNoteApi';
import React from 'react';
import { useToast } from 'utils/toast';
import { TemporaryNoteForm } from './TemporaryNoteForm';

export type TemporaryNoteAreaProps = {
  targetId: string | null;
  setModalOpen: (val: boolean) => void;
  defaultValues?: TemporaryNote;
};

export const TemporaryNoteArea = ({ targetId, setModalOpen, defaultValues }: TemporaryNoteAreaProps) => {
  const { toastError, toastInfo } = useToast();

  const onUnrecoverableError = (message: string | JSX.Element) => {
    // todo: we would probably want to log this error to Sentry or something
    toastError('Error', message);
  };

  const isNew = !defaultValues?.id;

  const onSuccess = (temporaryNote: TemporaryNote) => {
    setModalOpen(false);
    if (isNew) {
    } else {
    }
  };

  const onCancel = () => {
    setModalOpen(false);
    toastInfo('Cancelled', `Nothing changed!`);
  };

  const onDeleted = () => {
    setModalOpen(false);
  };

  const allDataLoaded = !!targetId;

  if (!defaultValues) {
    defaultValues = {
      temporaryNoteType: 'ScheduleDefinition',
    } as TemporaryNote;
  }

  return (
    <>
      {allDataLoaded && (
        <TemporaryNoteForm
          onCancel={onCancel}
          onSuccess={onSuccess}
          onDeleted={onDeleted}
          onUnrecoverableError={onUnrecoverableError}
          defaultValues={defaultValues}
        />
      )}
    </>
  );
};
