import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetValue,
  ValidationRule,
  RefCallBack,
  FieldError,
  Merge,
  Validate,
  Control,
  useFieldArray,
  UseFieldArrayReturn,
} from 'react-hook-form';

type Field = {
  register: UseFormRegister<FieldValues>;
  name: string;
  label: string;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  pattern?: ValidationRule<RegExp> | undefined;
  required?: string | ValidationRule<boolean> | undefined;
  setValueAs?: ((value: any) => any) | undefined;
  validate?: Validate<any, FieldValues> | Record<string, Validate<any, FieldValues>> | undefined;
};

export type FormField<T> = {
  ref: RefCallBack;
  name: string;
  label: string;
  errors: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  clearErrors: () => void;
  setValue: (value: T) => void;
};

const getErrors: (
  name: string,
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >,
) => FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined = (name, _errors) => {
  const parts = name.split('.');

  if (parts.length === 1) {
    return _errors[name];
  }

  if (parts.length === 2) {
    const [parent, child] = parts;
    return _errors[parent]?.[child];
  }

  if (parts.length === 3) {
    const [parent, child, grandchild] = parts;
    return _errors[parent]?.[child]?.[grandchild];
  }

  throw new Error('Too many parts in name');
};

export type MyField = Field & {
  errorName?: string;
};

export default function useField<T>({
  register,
  name,
  label,
  errors: _errors,
  clearErrors: _clearErrors,
  setValue: _setValue,
  pattern,
  required,
  setValueAs,
  validate,
  errorName,
}: MyField) {
  const { ref } = register(name, { pattern: pattern, required: required, validate: validate, setValueAs: setValueAs });
  const clearErrors = () => {
    _clearErrors(name);
    _clearErrors(errorName ?? name);
  };
  const errors = getErrors(errorName ?? name, _errors);
  const setValue = (value: T) => _setValue(name, value);
  return { ref, name, label, errors, clearErrors, setValue };
}

export type MultiField = {
  control: Control;
  // in the case of approvedNumbers[0].number, this would be approvedNumbers
  // in the case of ranges[0].paralegalId & ranges[0].attorneyId, this would be ranges
  name: string;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  clearErrors: UseFormClearErrors<FieldValues>;
};

export type MultifieldOutput = UseFieldArrayReturn<FieldValues, string, 'id'> & {
  errors: (index: number, propertyName: string) => FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  clearErrors: (index: number, propertyName: string) => () => void;
};

export function useMultiField({ control, name, errors: _errors, clearErrors }: MultiField) {
  const f = useFieldArray({
    control,
    name: name,
  });
  const fieldArray: MultifieldOutput = {
    ...f,
    errors: (index: number, propertyName: string) => {
      const key = `${name}_${index}_${propertyName}`;
      return _errors[key];
    },
    clearErrors: (index: number, propertyName: string) => () => {
      const key = `${name}_${index}_${propertyName}`;
      clearErrors(key);
    },
  };
  return fieldArray;
}
