import { createContext, useContext } from 'react';
import React from 'react';
import { format } from 'date-fns';

// This represents the state of a schedule.
export class ScheduleState {
  private defaultTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = Math.floor(now.getMinutes() / 30) * 30;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  private defaultDate = () => format(new Date(), 'yyyy-MM-dd');

  constructor() {
    this.date = this.defaultDate();
    this.time = this.defaultTime();
  }

  public scheduleDefinitionId: string | null = null;
  public date: string;
  public time: string;
  public appointmentId: string | null = null;
}

export interface ISessionStorageContext {
  setScheduleState: (scheduleState: ScheduleState) => void;
  getScheduleState: () => ScheduleState;
}

const SessionStorageContext = createContext<ISessionStorageContext>({
  setScheduleState: () => {},
  getScheduleState: () => new ScheduleState(),
});

export function SessionStorageProvider({ children }) {
  const getScheduleState = () => {
    const item = sessionStorage.getItem('scheduleState');
    if (!item) {
      return new ScheduleState();
    }
    return JSON.parse(item) as ScheduleState;
  };

  const setScheduleState = (scheduleState: ScheduleState) => {
    sessionStorage.setItem('scheduleState', JSON.stringify(scheduleState));
  };

  return (
    <SessionStorageContext.Provider
      value={{
        getScheduleState,
        setScheduleState,
      }}
    >
      <>{children}</>
    </SessionStorageContext.Provider>
  );
}

export const useSessionStorage = () => {
  return useContext(SessionStorageContext);
};
