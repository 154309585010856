import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';

export type Provider = NewProviderRequest & Auditable;

export type NewProviderRequest = {
  firstName: string;
  lastName: string;
};

export const useGetProviders = () => {
  const { computeClient: client } = useApiClient();
  return useQuery(['provider'], () => client.get<Provider[]>('/provider').then(response => response.data));
};

export const useGetProvider = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['provider', id], () => client.get<Provider>(`/provider/${id}`).then(response => response.data));
};

export const useCreateProvider = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Provider, AxiosError, NewProviderRequest>({
    mutationFn: async function (request: NewProviderRequest) {
      const response = await client.post('/provider', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['provider']);
    },
  });
};

export const useUpdateProvider = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Provider, AxiosError, Provider>({
    mutationFn: request => client.put('/provider', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['provider']);
    },
  });
};

export const useDeleteProvider = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/provider/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['provider']);
    },
  });
};
