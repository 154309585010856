/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { GlobalStyle } from 'styles/global-styles';
import { AuthProvider } from 'utils/auth';
import { LocalStorageProvider } from 'utils/localStorage';
import { ToastProvider } from 'utils/toast';
import { SessionStorageProvider } from '../utils/sessionStorage';

export function App() {
  return (
    <ToastProvider>
      <GlobalStyle />
      <LocalStorageProvider>
        <SessionStorageProvider>
          <AuthProvider />
        </SessionStorageProvider>
      </LocalStorageProvider>
    </ToastProvider>
  );
}
