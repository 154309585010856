import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ChangeEventHandler, forwardRef, useId, useMemo } from 'react';
import { InfoTip } from '../Tooltip';
import { GenericInputGroupProps, InputGroup } from './InputGroup';

export type AgeInputGroupProps = GenericInputGroupProps<HTMLInputElement, string> & {
  value: string;
};

export const AgeInpusdftGroup = forwardRef<HTMLInputElement, AgeInputGroupProps>(
  (props: AgeInputGroupProps, ref: React.Ref<HTMLInputElement>) => {
    return <InputGroup {...props} type="date" placeholder="05/01/1964" ref={ref} />;
  },
);

const getAgeYears = (value: string | null | undefined) => {
  if (!value) return null;
  const date = new Date(value);
  const diff = Date.now() - date.getTime();
  // years alive can be before 1970
  const ageDate = new Date(diff);
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);
  return age;
};

export const AgeInputGroup = forwardRef<HTMLInputElement, AgeInputGroupProps>(
  (
    { label, name, errors, onBlur, clearErrors, setValue, tooltip, value }: AgeInputGroupProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    var baseClass =
      'block w-full rounded-md border-gray-300 text-gray-900 placeholder-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm';
    var invalidClass = classNames(
      baseClass,
      'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500',
    );

    const onChange: ChangeEventHandler<HTMLInputElement> = event => {
      clearErrors();
      setValue(event.target.value);
    };

    const id = useId();

    const iterableErrorMessages = useMemo(() => {
      if (errors?.message) {
        if (Array.isArray(errors.message)) {
          return errors.message;
        }
        return [errors.message];
      }
      return [];
    }, [errors]);

    const ageText = getAgeYears(value);

    return (
      <>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label} {ageText && <span className="font-extrabold italic text-indigo-900">({ageText})</span>}
          {tooltip && <InfoTip content={tooltip} />}
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            ref={ref}
            type="date"
            id={id}
            className={errors ? invalidClass : baseClass}
            placeholder="05/01/1964"
            {...(errors && { 'aria-invalid': 'true' })}
            {...(errors && { 'aria-describedby': `${id}-error` })}
            autoComplete="off"
          />
          {errors && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <FontAwesomeIcon className="h-5 w-5 text-red-500" icon={faTriangleExclamation} aria-hidden="true" />
            </div>
          )}
        </div>
        {iterableErrorMessages.map((message, index) => (
          <p key={index} className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {message}
          </p>
        ))}
      </>
    );
  },
);
