import { ScheduleDefinition } from 'app/api/ScheduleDefinitionApi';
import React, { useRef, useState } from 'react';
import { Bucket } from './BucketOperations';
import { Appointment } from 'app/api/AppointmentApi';
import classNames from 'classnames';
import { MouseEvent } from 'react';
import { DayTimeTableContextMenu } from './ContextMenu/DayTimeTableContextMenu';
import { AppointmentModalEdit } from './layout/AppointmentModalEdit';
import { AppointmentModalNew } from './layout/AppointmentModalNew';
import { useProfile } from 'utils/profile';
import { DayTimeRow } from './DayTimeRow';
import { TemporaryNoteModalNew } from './layout/TemporaryNoteModalNew';
import { TemporaryNote } from 'app/api/TemporaryNoteApi';
import { TemporaryNoteModalEdit } from './layout/TemporaryNoteModalEdit';
import { PasteableAppointment } from './PasteableAppointment';
import { Patient } from '../../api/PatientApi';
import { PasteableTemporaryNote } from './PasteableTemporaryNote';
import { useActiveBucketProvider } from '../../pages/SchedulePage/ActiveBucketProvider';
import { useScheduleLaunchPadProvider } from '../../pages/SchedulePage/ScheduleDisplayLaunchPadProvider';
import { LoadingWrapper } from '../../../utils/loadingWrapper';

export type DayTimeTableProps<T extends HTMLElement = HTMLElement> = {
  scheduleDefinition: ScheduleDefinition;
  date: string;
  isLoading: boolean;
  allowRightClick: boolean;
  allowAddNotes?: boolean;
  reduceColumns: boolean;
  time: string | null;
  containerRef: React.RefObject<T>;
  hasAppointmentsProvider: boolean;
  headerClearance?: number; //used as header 'sticky top-<headerClearance>
};

export const buildRowId = (key: string) => `row-${key.replaceAll(':', '-')}`;

const initialContextMenu = {
  show: false,
  x: 0,
  y: 0,
};

// This is some bs here. I don't like it. For some reason,
// the tailwind "top" class is not working. So I'm just re-implementing
// this using straight css. Whatever...
export const buildTopStyle = (headerClearance?: number) => {
  return { top: `${(headerClearance ?? 0) * 0.25}rem` };
};

export const DayTimeTable = ({
  scheduleDefinition,
  date,
  allowRightClick,
  reduceColumns,
  time,
  containerRef,
  isLoading: _isLoading,
  hasAppointmentsProvider,
  allowAddNotes,
  headerClearance,
}: DayTimeTableProps) => {
  const topStyle = buildTopStyle(headerClearance);

  const { activeBucket, setActiveBucket, buckets, appointmentsIsLoading } = useActiveBucketProvider();

  const [newAppointmentModalIsOpen, setNewAppointmentModalIsOpen] = React.useState(false);
  const [editAppointmentModalIsOpen, _setEditAppointmentModalIsOpen] = React.useState(false);

  const [newNoteModalIsOpen, setNewNoteModalIsOpen] = React.useState(false);
  const [editNoteModalIsOpen, _setEditNoteModalIsOpen] = React.useState(false);
  const [pasteableAppointmentModalIsOpen, setPasteableAppointmentModalIsOpen] = React.useState(false);
  const [pasteableTemporaryNoteModalIsOpen, setPasteableTemporaryNoteModalIsOpen] = React.useState(false);
  const [editModalKey, setEditModalKey] = useState(0);

  const tableRef = useRef<HTMLTableElement>(null);

  const setEditAppointmentModalIsOpen = (val: boolean) => {
    if (!val) {
      setEditModalKey(x => x + 1);
    }

    _setEditAppointmentModalIsOpen(val);
  };

  const setEditNoteModalIsOpen = (val: boolean) => {
    if (!val) {
      setEditModalKey(x => x + 1);
    }

    _setEditNoteModalIsOpen(val);
  };

  const profile = useProfile()!;

  const [contextMenu, setContextMenu] = React.useState(initialContextMenu);

  const stickyHeader = classNames(
    // `top-${headerClearance ?? 0}`, // this is not working. See 'buildTopStyle'
    `sticky z-9  border-gray-900 bg-gray-100 text-gray-900 border-b-2 text-center`,
  );

  const firstColHeaderStyle = 'whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0';
  const otherColHeaderStyle = 'whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900';

  const onBucketClick = (bucket: Bucket) => {
    setActiveBucket(bucket, false);
  };

  const onSingleClick = (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, bucket: Bucket) => {
    onBucketClick(bucket);
  };

  const onRightClick = (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, bucket: Bucket) => {
    if (!allowRightClick || !rightClickEnabled) {
      return;
    }
    onBucketClick(bucket);
    setNewAppointmentTime(bucket.time());
    setNewNoteTime(bucket.time());
    e.preventDefault();
    setContextMenu({ show: true, x: e.pageX, y: e.pageY });
  };

  const { setAppointment } = useScheduleLaunchPadProvider();

  // useEffect(() => {
  //   if (shouldSetActiveBucketOnBucketsUpdate) {
  //     if (nextActiveBucketCandidateKey) {
  //       const bucket = buckets.find(b => b.key === nextActiveBucketCandidateKey);
  //       if (bucket && bucket.key !== activeBucket?.key) {
  //         setActiveBucket(bucket, true);
  //         setNextActiveBucketCandidateKey(null);
  //         setShouldSetActiveBucketOnBucketsUpdate(false);
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [buckets]);

  const onDoubleClick = (e: { preventDefault: () => void }, bucket: Bucket) => {
    if (!allowRightClick || !rightClickEnabled) {
      return;
    }
    e.preventDefault();

    if (!bucket.event || bucket.isTemporaryNote()) {
      const closestBucket = bucket.addButtonFor(buckets, date, scheduleDefinition)!;
      onBucketClick(closestBucket.bucket);
      onNewAppointmentClick(closestBucket.timeRepresentation.stringify());
      return;
    }

    if (bucket.event) {
      onBucketClick(bucket);
      if (bucket.isAppointment()) {
        onEditAppointmentClick(bucket.asAppointment());
      }
      return;
    }
  };

  const onNewNoteClick = (time: string) => {
    if (!allowRightClick || !rightClickEnabled) {
      return;
    }
    setNewNoteTime(time);
    setNewNoteModalIsOpen(true);
  };

  const onNewNoteSingleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, bucket: Bucket) => {
    if (!allowRightClick || !rightClickEnabled) {
      return;
    }
    setNewNoteTime(bucket.time());
    e.preventDefault();
    setNewNoteModalIsOpen(true);
  };

  const onEditNoteClick = (note: TemporaryNote) => {
    if (!allowRightClick || !rightClickEnabled) {
      return;
    }
    setEditNoteModalIsOpen(true);
  };

  const onEditNoteSingleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, bucket: Bucket) => {
    if (!allowRightClick || !rightClickEnabled) {
      return;
    }
    e.preventDefault();
    onBucketClick(bucket);
    if (bucket.isTemporaryNote()) {
      onEditTemporaryNoteClick(bucket.asTemporaryNote());
    }
  };

  const contextMenuClose = () => {
    setContextMenu({
      show: false,
      x: 0,
      y: 0,
    });
  };

  const [newAppointmentTime, setNewAppointmentTime] = useState<string | null>(time);
  const [newNoteTime, setNewNoteTime] = useState<string | null>(time);

  const onNewAppointmentClick = (time: string) => {
    setNewAppointmentTime(time);
    setNewAppointmentModalIsOpen(true);
  };

  const onEditAppointmentClick = (appointment: Appointment) => {
    setEditAppointmentModalIsOpen(true);
  };

  const onEditTemporaryNoteClick = (note: TemporaryNote) => {
    setEditNoteModalIsOpen(true);
  };

  const [rightClickEnabled, setRightClickEnabled] = useState(true);
  const toggleRightClick = () => {
    setRightClickEnabled(!rightClickEnabled);
  };

  const isLoading = () => {
    return appointmentsIsLoading || _isLoading;
  };

  const [pastedPatient, setPastedPatient] = useState<Patient>();
  const [pastedAppointment, setPastedAppointment] = useState<Appointment>();
  const [pastedTemporaryNote, setPastedTemporaryNote] = useState<TemporaryNote>();

  const onTemporaryNotePaste = (note: TemporaryNote) => {
    setPastedTemporaryNote(note);
    setPasteableTemporaryNoteModalIsOpen(true);
  };

  const onAppointmentPaste = (appointment: Appointment, patient: Patient) => {
    setPastedAppointment(appointment);
    setPastedPatient(patient);
    setPasteableAppointmentModalIsOpen(true);
  };

  const canShowPastedAppointmentModal = (appointment?: Appointment, patient?: Patient) => appointment && patient;

  const onAppointmentSubmitSuccess = (appointment: Appointment) => {
    setTimeout(() => setAppointment(appointment), 300);
  };

  return (
    <>
      {allowRightClick && (
        <>
          <AppointmentModalNew
            key={'appt' + newAppointmentTime}
            isOpen={newAppointmentModalIsOpen}
            closeModal={() => setNewAppointmentModalIsOpen(false)}
            time={newAppointmentTime!}
            date={date!}
            scheduleDefinitionId={scheduleDefinition.id}
            hasAppointmentsProvider={hasAppointmentsProvider}
            onAppointmentSubmitSuccess={onAppointmentSubmitSuccess}
          />

          {activeBucket?.isAppointment() && (
            <AppointmentModalEdit
              key={'appt' + editModalKey}
              isOpen={editAppointmentModalIsOpen}
              closeModal={() => setEditAppointmentModalIsOpen(false)}
              appointmentId={activeBucket?.asAppointment().id}
              hasAppointmentsProvider={hasAppointmentsProvider}
              onAppointmentSubmitSuccess={onAppointmentSubmitSuccess}
            />
          )}
          {canShowPastedAppointmentModal(pastedAppointment, pastedPatient) && (
            <PasteableAppointment
              key={'pasteable_appt' + newAppointmentTime}
              open={pasteableAppointmentModalIsOpen}
              setOpen={setPasteableAppointmentModalIsOpen}
              time={newAppointmentTime!}
              date={date!}
              scheduleDefinitionId={scheduleDefinition.id}
              appointment={pastedAppointment!}
              patient={pastedPatient!}
            />
          )}

          <TemporaryNoteModalNew
            key={'note' + newNoteTime}
            open={newNoteModalIsOpen}
            setOpen={setNewNoteModalIsOpen}
            time={newNoteTime!}
            date={date!}
            targetId={scheduleDefinition.id}
          />

          {activeBucket?.isTemporaryNote() && (
            <TemporaryNoteModalEdit
              key={'note' + editModalKey}
              open={editNoteModalIsOpen}
              setOpen={setEditNoteModalIsOpen}
              temporaryNote={activeBucket?.asTemporaryNote()}
            />
          )}

          {pastedTemporaryNote && (
            <PasteableTemporaryNote
              key={'pasteable_note' + newNoteTime}
              open={pasteableTemporaryNoteModalIsOpen}
              setOpen={setPasteableTemporaryNoteModalIsOpen}
              time={newNoteTime!}
              date={date!}
              scheduleDefinitionId={scheduleDefinition.id}
              temporaryNote={pastedTemporaryNote}
            />
          )}
        </>
      )}

      <LoadingWrapper isLoading={isLoading()} isError={false}>
        {contextMenu.show && (
          <DayTimeTableContextMenu
            x={contextMenu.x}
            y={contextMenu.y}
            bucket={activeBucket!}
            close={contextMenuClose}
            container={containerRef}
            addButtonFor={(bucket: Bucket) => bucket.addButtonFor(buckets, date, scheduleDefinition)}
            onNewAppointmentClick={onNewAppointmentClick}
            onEditAppointmentClick={onEditAppointmentClick}
            onNewNoteClick={onNewNoteClick}
            onEditNoteClick={onEditNoteClick}
            onPasteAppointment={onAppointmentPaste}
            onPasteNote={onTemporaryNotePaste}
          />
        )}
        <div className="w-full" data-testid="day-time-table">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full divide-y divide-gray-300" ref={tableRef}>
              <thead>
                <tr>
                  {allowAddNotes && (
                    <th scope="col" className={classNames(stickyHeader, otherColHeaderStyle, 'w-4')} style={topStyle}></th>
                  )}
                  <th scope="col" className={classNames(stickyHeader, firstColHeaderStyle, 'w-16')} style={topStyle}>
                    Time
                  </th>
                  <th scope="col" className={classNames(stickyHeader, otherColHeaderStyle)} style={topStyle}>
                    Description
                  </th>
                  {!reduceColumns && (
                    <>
                      <th scope="col" className={classNames(stickyHeader, otherColHeaderStyle)} style={topStyle}>
                        Phone 1
                      </th>

                      <th scope="col" className={classNames(stickyHeader, otherColHeaderStyle)} style={topStyle}>
                        Phone 2
                      </th>
                    </>
                  )}
                  <th scope="col" className={classNames(stickyHeader, otherColHeaderStyle)} style={topStyle}>
                    Notes
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {buckets.map((bucket, index) => (
                  <DayTimeRow
                    key={bucket.key}
                    bucket={bucket}
                    isActive={activeBucket?.key === bucket.key}
                    onRightClick={onRightClick}
                    onSingleClick={onSingleClick}
                    onDoubleClick={onDoubleClick}
                    date={date}
                    scheduleDefinition={scheduleDefinition}
                    reduceColumns={reduceColumns}
                    buildRowId={buildRowId}
                    onNewNoteClick={onNewNoteSingleClick}
                    onEditNoteClick={onEditNoteSingleClick}
                    allowAddNotes={allowAddNotes}
                    disableDoubleClick={!allowRightClick}
                    index={index}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {allowRightClick && (
            <>
              {profile.capabilities.canToggleRightClick() && (
                <div className="mt-4 mb-6">
                  <button
                    type="button"
                    onClick={toggleRightClick}
                    className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    {rightClickEnabled ? 'Disable' : 'Enable'} Right Click
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </LoadingWrapper>
    </>
  );
};
