import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { PagedQueryRequest, PagedQueryResponse } from './Pagination';
import { Auditable } from './Auditable';

export type LawOffice = NewLawOfficeRequest & Auditable;

export type NewLawOfficeRequest = {
  city: string;
  state: string;
  notes?: string;
  phone?: string;
  fax?: string;
  lawFirmId: string;
  addressLine1?: string;
  addressLine2?: string;
  zip?: string;
  description: string;
};

export const useGetLawOffices = (request?: PagedQueryRequest) => {
  const { computeClient: client } = useApiClient();
  const requestOverride = {} as PagedQueryRequest;
  if (request) {
    requestOverride.pageSize = request.pageSize || 50;
    requestOverride.lastKeyEvaluated = request.lastKeyEvaluated;
  }
  return useQuery(['law-office', request], async () => {
    let list: LawOffice[] = [];
    while (true) {
      const response = await client.post<PagedQueryResponse<LawOffice>>('/law-office/list', request || null);
      list = list.concat(response.data.items);
      if (Object.keys(response.data.lastKeyEvaluated as {}).length > 0) {
        requestOverride.lastKeyEvaluated = response.data.lastKeyEvaluated;
      } else {
        break;
      }
    }
    return list;
  });
};

export const useGetLawOffice = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['law-office', id], () => client.get<LawOffice>(`/law-office/${id}`).then(response => response.data));
};

export const useCreateLawOffice = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<LawOffice, AxiosError, NewLawOfficeRequest>({
    mutationFn: async function (request: NewLawOfficeRequest) {
      const response = await client.post('/law-office', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['law-office']);
    },
  });
};

export const useUpdateLawOffice = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<LawOffice, AxiosError, LawOffice>({
    mutationFn: request => client.put('/law-office', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['law-office']);
    },
  });
};

export const useDeleteLawOffice = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/law-office/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['law-office']);
    },
  });
};
