import React, { Fragment, useRef, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faSort, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useMultiField } from '../../Forms/FieldHook';
import { useAppointmentFormContext } from '../../Schedule/AppointmentFormHook';
import { AppointmentMultiSection } from './AppointmentMultiSection';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import { format } from 'date-fns';
import { Disclosure } from '@headlessui/react';
import { MultiAppointmentWizard } from './MultiAppointmentWizard';

export type DynamicAppointmentMultiSectionProps = {
  fieldName: string;
};

const getDate = (date: string | null) => {
  if (!date) return null;
  const year = parseInt(date.split('-')[0]);
  const month = parseInt(date.split('-')[1]);
  const day = parseInt(date.split('-')[2]);
  return new Date(year, month - 1, day);
};

export const DynamicAppointmentMultiSection = ({ fieldName }: DynamicAppointmentMultiSectionProps) => {
  const {
    watch,
    setValue,
    control,
    errors,
    clearErrors,
    register,
    getValues,
    sourceTime,
    sourceDate,
    dateWatch,
    timeWatch,
    scheduleDefinition,
    setAdditionalAppointmentIndexFocus,
    additionalAppointmentIndexFocus,
  } = useAppointmentFormContext();

  const appointments = useMultiField({
    control,
    name: fieldName,
    errors,
    clearErrors,
  });

  const onAddAppointment = () => {
    let date = dateWatch;
    if (appointments.fields.length) {
      const lastDateString = getValues(`${fieldName}[${appointments.fields.length - 1}].date`);
      const lastDate = getDate(lastDateString);
      if (lastDate) {
        lastDate.setDate(lastDate.getDate() + 1);
        date = format(lastDate, 'yyyy-MM-dd');
      }
    } else if (date) {
      const dateObj = getDate(date);
      if (dateObj) {
        dateObj.setDate(dateObj.getDate() + 1);
        date = format(dateObj, 'yyyy-MM-dd');
      }
    }

    const newAppointment = {
      date: date,
      time: timeWatch,
    };
    appointments.append(newAppointment);
    const repeatedAppointments = document.getElementsByClassName('repeated-appointment');
    if (repeatedAppointments.length) {
      repeatedAppointments[repeatedAppointments.length - 1].scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onRemoveClick = (index: number) => {
    setAdditionalAppointmentIndexFocus(null);
    clearErrors();
    appointments.remove(index);
  };

  const clearAll = () => {
    setAdditionalAppointmentIndexFocus(null);
    clearErrors();
    setValue(fieldName, []);
  };

  const sortAdditionalAppointments = () => {
    let indexesToRemove = 0;
    const unsorted: { date: string; time: string }[] = [];
    for (let i = 0; i < appointments.fields.length; i++) {
      const date = getValues(`${fieldName}[${i}].date`);
      const time = getValues(`${fieldName}[${i}].time`);
      if (date && time) {
        unsorted.push({ date, time });
      } else {
        indexesToRemove++;
      }
    }

    const sorted = unsorted.sort((a, b) => {
      if (a.date === b.date) {
        return a.time < b.time ? -1 : 1;
      }
      return a.date < b.date ? -1 : 1;
    });

    for (let i = 0; i < sorted.length; i++) {
      setValue(`${fieldName}[${i}].date`, sorted[i].date);
      setValue(`${fieldName}[${i}].time`, sorted[i].time);
    }

    for (let i = 0; i < indexesToRemove; i++) {
      appointments.remove(appointments.fields.length - i - 1);
    }
  };

  const onMultiAppointmentWizardSelect =
    (close: () => void) =>
    (
      totalAppointments: number,
      mondayRepeat: boolean,
      tuesdayRepeat: boolean,
      wednesdayRepeat: boolean,
      thursdayRepeat: boolean,
      fridayRepeat: boolean,
      saturdayRepeat: boolean,
      sundayRepeat: boolean,
    ) => {
      if (!totalAppointments || totalAppointments < 2) {
        close();
        return;
      }

      if (
        !mondayRepeat &&
        !tuesdayRepeat &&
        !wednesdayRepeat &&
        !thursdayRepeat &&
        !fridayRepeat &&
        !saturdayRepeat &&
        !sundayRepeat
      ) {
        close();
        return;
      }

      let lastDate = getDate(dateWatch);
      if (!lastDate) {
        close();
        return;
      }

      let appointmentsLeft = totalAppointments - 1;

      while (appointmentsLeft > 0) {
        let targetDate = new Date(lastDate!.getFullYear(), lastDate!.getMonth(), lastDate!.getDate() + 1);

        lastDate = targetDate;

        const day = targetDate.getDay();
        if (
          (day === 0 && sundayRepeat) ||
          (day === 1 && mondayRepeat) ||
          (day === 2 && tuesdayRepeat) ||
          (day === 3 && wednesdayRepeat) ||
          (day === 4 && thursdayRepeat) ||
          (day === 5 && fridayRepeat) ||
          (day === 6 && saturdayRepeat)
        ) {
          const newAppointment = {
            date: format(targetDate, 'yyyy-MM-dd'),
            time: timeWatch,
          };
          appointments.append(newAppointment);
          appointmentsLeft--;
        }
      }

      close();
    };

  const [isHover, setIsHover] = useState(false);

  const onMouseEnter = (index: number) => {
    setAdditionalAppointmentIndexFocus(index);
    setIsHover(true);
  };

  const onMouseLeave = (index: number) => {
    if (index === additionalAppointmentIndexFocus) {
      setAdditionalAppointmentIndexFocus(null);
      setIsHover(false);
    }
  };

  const multiAppointmentRef = useRef<HTMLDivElement>(null);

  return (
    <div className="mt-4">
      {!!appointments.fields.length && (
        <div className="flex flex-col gap-x-4 mb-2">
          <div>
            <div className="font-bold text-gray-900 mb-2 inline-block">Repeated Appointments</div>
            <div className="font-bold text-gray-900 mb-2 inline-block">
              <button
                type="button"
                onClick={() => sortAdditionalAppointments()}
                className="ml-2 inline-block rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                <FontAwesomeIcon icon={faSort} size="xs" className="mr-2" />
                Sort
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-col">
        {appointments.fields.map((_, index) => (
          <Fragment key={index}>
            <div
              className={classNames(
                'repeated-appointment',
                'flex flex-row w-full space-x-2 p-2 hover:bg-blue-300',
                index % 2 ? 'bg-gray-100' : '',
              )}
              onMouseEnter={() => onMouseEnter(index)}
              onMouseLeave={() => onMouseLeave(index)}
            >
              <div className="grow">
                <AppointmentMultiSection
                  appointments={appointments}
                  watch={watch}
                  register={register}
                  setValue={setValue}
                  fieldName={fieldName}
                  getValues={getValues}
                  mainAppointmentDate={sourceDate}
                  mainAppointmentTime={sourceTime}
                  isHover={isHover}
                  additionalAppointmentIndex={index}
                />
              </div>
              <div className="flex items-center">
                <div>
                  <FontAwesomeIcon
                    className="text-red-600 cursor-pointer hover:text-red-800 hover:scale-110 group"
                    icon={faTrashCan}
                    onClick={() => onRemoveClick(index)}
                  />
                </div>
              </div>
            </div>
          </Fragment>
        ))}
        <div className="gap-y-2 gap-x-2 flex flex-col md:flex-row">
          <div>
            <button
              type="button"
              onClick={onAddAppointment}
              className="mr-2 whitespace-nowrap rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm  text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" />
              Add 1 Additional Appointment
            </button>
          </div>

          {!appointments.fields.length && (
            <div className="flex flex-col">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        open
                          ? 'bg-violet-300 hover:bg-violet-400 focus-visible:outline-violet-500'
                          : 'bg-violet-600 hover:bg-violet-500 focus-visible:outline-violet-600',
                        'mr-2 whitespace-nowrap rounded-md px-2.5 py-1.5 text-sm text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600',
                      )}
                    >
                      <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
                      Add Several Appointments
                    </Disclosure.Button>
                    <Disclosure.Panel className="text-gray-500">
                      {({ close }) => (
                        <div ref={multiAppointmentRef}>
                          <MultiAppointmentWizard
                            scheduleDefinition={scheduleDefinition}
                            setSelected={onMultiAppointmentWizardSelect(close)}
                            container={multiAppointmentRef}
                          />
                        </div>
                      )}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          )}
          {!!appointments.fields.length && (
            <button
              type="button"
              onClick={clearAll}
              className="mr-2 whitespace-nowrap rounded-md bg-red-600 px-2.5 py-1.5 text-sm  text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              <FontAwesomeIcon icon={faXmark} className="mr-2" />
              Clear All
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
