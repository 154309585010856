import { createContext, useContext, useEffect } from 'react';
import React from 'react';
import {
  Appointment,
  useAddAppointmentInCache,
  useDeleteAppointmentInCache,
  useGetAppointmentsBySchedule,
  useUpdateAppointmentInCache,
} from 'app/api/AppointmentApi';
import { useWebsockets } from 'utils/websocket';

export interface IAppointmentsContext {
  appointments: Appointment[] | null;
  isLoading: boolean;
  isError: boolean;
}

const AppointmentContext = createContext<IAppointmentsContext>({
  appointments: null,
  isLoading: false,
  isError: false,
});

const sortByTime = (a: Appointment, b: Appointment) => {
  // sort based on a string field called "time" formatted like HH:mm
  const aTime = a.appointmentTime.split(':');
  const bTime = b.appointmentTime.split(':');

  if (aTime[0] > bTime[0]) {
    return 1;
  }
  if (aTime[0] < bTime[0]) {
    return -1;
  }
  if (aTime[1] > bTime[1]) {
    return 1;
  }
  if (aTime[1] < bTime[1]) {
    return -1;
  }
  if (a.patientLastName > b.patientLastName) {
    return 1;
  }
  if (a.patientLastName < b.patientLastName) {
    return -1;
  }
  if (a.patientFirstName > b.patientFirstName) {
    return 1;
  }
  if (a.patientFirstName < b.patientFirstName) {
    return -1;
  }
  return 0;
};

export function AppointmentsProvider({
  scheduleDefinitionId,
  date,
  children,
}: {
  scheduleDefinitionId: string | null | undefined;
  date: string | null | undefined;
  children: JSX.Element;
}) {
  const { lastAppointmentMessage } = useWebsockets();

  const {
    data: appointments,
    isLoading: appointmentsIsLoading,
    isError: appointmentsIsError,
  } = useGetAppointmentsBySchedule(scheduleDefinitionId, date);

  const addAppointment = useAddAppointmentInCache();
  const updateAppointment = useUpdateAppointmentInCache();
  const deleteAppointment = useDeleteAppointmentInCache();

  useEffect(() => {
    if (!lastAppointmentMessage) {
      return;
    }
    switch (lastAppointmentMessage.action) {
      case 'create':
        addAppointment(lastAppointmentMessage.model);
        break;
      case 'update':
        updateAppointment(lastAppointmentMessage.model);
        break;
      case 'delete':
        deleteAppointment(lastAppointmentMessage.model);
        break;
      case 'unknown':
      default:
        console.error('Unknown appointment action type');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAppointmentMessage]);

  const providerValue = {
    appointments: appointments?.sort(sortByTime) ?? [],
    isLoading: appointmentsIsLoading,
    isError: appointmentsIsError,
  };

  return <AppointmentContext.Provider value={providerValue}>{children}</AppointmentContext.Provider>;
}

export function useAppointmentsContext() {
  return useContext(AppointmentContext);
}
