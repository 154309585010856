import { useForm, ValidationRule } from 'react-hook-form';
import useField from '../../Forms/FieldHook';

import { ScheduleDefinition } from '../../../api/ScheduleDefinitionApi';
import { CheckboxGroup } from '../../Forms/CheckboxGroup';
import React from 'react';
import { InputGroup } from '../../Forms/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';

export type MultiAppointmentWizardProps = {
  scheduleDefinition?: ScheduleDefinition | null;
  setSelected: (
    totalAppointments: number,
    mondayRepeat: boolean,
    tuesdayRepeat: boolean,
    wednesdayRepeat: boolean,
    thursdayRepeat: boolean,
    fridayRepeat: boolean,
    saturdayRepeat: boolean,
    sundayRepeat: boolean,
  ) => void;
  container: React.RefObject<HTMLDivElement>;
};

export const MultiAppointmentWizard = ({ scheduleDefinition, setSelected, container }: MultiAppointmentWizardProps) => {
  container.current?.scrollIntoView({ behavior: 'smooth' });

  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
    getValues,
  } = useForm({
    shouldFocusError: true,
    defaultValues: {
      totalAppointments: 6,
      mondayRepeat: false,
      tuesdayRepeat: false,
      wednesdayRepeat: false,
      thursdayRepeat: false,
      fridayRepeat: false,
      saturdayRepeat: false,
      sundayRepeat: false,
    } as any,
  });

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
    setValueAs?: ((value: any) => any) | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required, setValueAs };
  };

  const openMonday = scheduleDefinition?.monday?.isOpen ?? false;
  const openTuesday = scheduleDefinition?.tuesday?.isOpen ?? false;
  const openWednesday = scheduleDefinition?.wednesday?.isOpen ?? false;
  const openThursday = scheduleDefinition?.thursday?.isOpen ?? false;
  const openFriday = scheduleDefinition?.friday?.isOpen ?? false;
  const openSaturday = scheduleDefinition?.saturday?.isOpen ?? false;
  const openSunday = scheduleDefinition?.sunday?.isOpen ?? false;

  const openMWF = openMonday || openWednesday || openFriday;
  const openTT = openTuesday || openThursday;
  const openSS = openSaturday || openSunday;

  const totalAppointments = useField(fieldArguments('totalAppointments', 'Total # Appts'));
  // set default value for totalAppointments: 6

  const mondayRepeat = useField<boolean>(fieldArguments('mondayRepeat', 'Mondays'));
  const tuesdayRepeat = useField<boolean>(fieldArguments('tuesdayRepeat', 'Tuesdays'));
  const wednesdayRepeat = useField<boolean>(fieldArguments('wednesdayRepeat', 'Wednesdays'));
  const thursdayRepeat = useField<boolean>(fieldArguments('thursdayRepeat', 'Thursdays'));
  const fridayRepeat = useField<boolean>(fieldArguments('fridayRepeat', 'Fridays'));
  const saturdayRepeat = useField<boolean>(fieldArguments('saturdayRepeat', 'Saturdays'));
  const sundayRepeat = useField<boolean>(fieldArguments('sundayRepeat', 'Sundays'));

  const onClick = () => {
    let totalAppointments = getValues('totalAppointments');
    totalAppointments = parseInt(totalAppointments ?? 0);

    let mondayRepeat = getValues('mondayRepeat');
    mondayRepeat = mondayRepeat === 'true' || !!mondayRepeat;

    let tuesdayRepeat = getValues('tuesdayRepeat');
    tuesdayRepeat = tuesdayRepeat === 'true' || !!tuesdayRepeat;

    let wednesdayRepeat = getValues('wednesdayRepeat');
    wednesdayRepeat = wednesdayRepeat === 'true' || !!wednesdayRepeat;

    let thursdayRepeat = getValues('thursdayRepeat');
    thursdayRepeat = thursdayRepeat === 'true' || !!thursdayRepeat;

    let fridayRepeat = getValues('fridayRepeat');
    fridayRepeat = fridayRepeat === 'true' || !!fridayRepeat;

    let saturdayRepeat = getValues('saturdayRepeat');
    saturdayRepeat = saturdayRepeat === 'true' || !!saturdayRepeat;

    let sundayRepeat = getValues('sundayRepeat');
    sundayRepeat = sundayRepeat === 'true' || !!sundayRepeat;

    setSelected(
      totalAppointments,
      mondayRepeat,
      tuesdayRepeat,
      wednesdayRepeat,
      thursdayRepeat,
      fridayRepeat,
      saturdayRepeat,
      sundayRepeat,
    );
  };

  const anySelected = () =>
    getValues('mondayRepeat') ||
    getValues('tuesdayRepeat') ||
    getValues('wednesdayRepeat') ||
    getValues('thursdayRepeat') ||
    getValues('fridayRepeat') ||
    getValues('saturdayRepeat') ||
    getValues('sundayRepeat');

  return (
    <div className="flex flex-col gap-y-1 py-2">
      <div className="flex flex-row items-center gap-x-2">
        <div className="w-36">
          <InputGroup {...totalAppointments} type="number" placeholder="6" />
        </div>
        <div className="items-center">
          <button
            type="button"
            onClick={() => setValue('totalAppointments', 3)}
            className="ml-2 inline-block rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            3
          </button>
          <button
            type="button"
            onClick={() => setValue('totalAppointments', 6)}
            className="ml-2 inline-block rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            6
          </button>
          <button
            type="button"
            onClick={() => setValue('totalAppointments', 12)}
            className="ml-2 inline-block rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            12
          </button>
        </div>
      </div>
      <div className="flex flex-row gap-x-4">
        {openMWF && (
          <div className="sm:col-span-1">
            {openMonday && <CheckboxGroup {...mondayRepeat} initialValue={getValues('mondayRepeat')} />}
            {openWednesday && <CheckboxGroup {...wednesdayRepeat} initialValue={getValues('wednesdayRepeat')} />}
            {openFriday && <CheckboxGroup {...fridayRepeat} initialValue={getValues('fridayRepeat')} />}
          </div>
        )}
        {openTT && (
          <div className="sm:col-span-1">
            {openTuesday && <CheckboxGroup {...tuesdayRepeat} initialValue={getValues('tuesdayRepeat')} />}
            {openThursday && <CheckboxGroup {...thursdayRepeat} initialValue={getValues('thursdayRepeat')} />}
          </div>
        )}
        {openSS && (
          <div className="sm:col-span-1">
            {openSaturday && <CheckboxGroup {...saturdayRepeat} initialValue={getValues('saturdayRepeat')} />}
            {openSunday && <CheckboxGroup {...sundayRepeat} initialValue={getValues('sundayRepeat')} />}
          </div>
        )}
      </div>
      <button
        disabled={!anySelected()}
        className={classNames(
          anySelected()
            ? 'bg-violet-600 hover:bg-violet-500  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-600'
            : 'bg-violet-300',
          'mr-2 mt-2 whitespace-nowrap rounded-md px-2.5 py-1.5 text-sm  text-white shadow-sm ',
        )}
        onClick={() => onClick()}
      >
        <FontAwesomeIcon icon={faCalendarCheck} className="mr-2" />
        Add Appointments
      </button>
    </div>
  );
};
