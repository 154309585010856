import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';

export type AppointmentType = NewAppointmentTypeRequest & Auditable;

export type NewAppointmentTypeRequest = {
  name: string;
  notes?: string;
  shortCode: string;
  durationMinutes?: number;
  defaultAppointmentStatusId: string;
  appointmentStatusIds: string[];
  scheduleTypeId: string;
  decrementRemainingAppointments: boolean;
  emoji: string;
  checkInDurationMinutes: number;
  isNew: boolean;
  isFollowUp: boolean;
  isEvaluation: boolean;
};

export const useGetAppointmentTypes = () => {
  const { computeClient: client } = useApiClient();
  return useQuery(['appointment-type'], () => client.get<AppointmentType[]>('/appointment-type').then(response => response.data));
};

export const useGetAppointmentType = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['appointment-type', id], () =>
    client.get<AppointmentType>(`/appointment-type/${id}`).then(response => response.data),
  );
};

export const useCreateAppointmentType = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<AppointmentType, AxiosError, NewAppointmentTypeRequest>({
    mutationFn: async function (request: NewAppointmentTypeRequest) {
      const response = await client.post('/appointment-type', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['appointment-type']);
    },
  });
};

export const useUpdateAppointmentType = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<AppointmentType, AxiosError, AppointmentType>({
    mutationFn: request => client.put('/appointment-type', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['appointment-type']);
    },
  });
};

export const useDeleteAppointmentType = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/appointment-type/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['appointment-type']);
    },
  });
};
