import { TemporaryNote } from 'app/api/TemporaryNoteApi';
import React from 'react';

export const TemporaryNoteSummary = ({ temporaryNote }: { temporaryNote: TemporaryNote }) => {
  return (
    <>
      <div className="text-lg text-gray-900 font-bold">{temporaryNote.body}</div>
    </>
  );
};
