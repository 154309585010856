import { Appointment } from 'app/api/AppointmentApi';
import { format } from 'date-fns';
import React from 'react';
import { useMemo } from 'react';
import { useFlexworxConfig } from '../../../../utils/flexworx-config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons';

export const AppointmentSummary = ({ appointment }: { appointment: Appointment }) => {
  const { appointmentStatusFromId, appointmentTypeFromId } = useFlexworxConfig();
  const appointmentStatus = appointmentStatusFromId(appointment.appointmentStatusId);
  const appointmentType = appointmentTypeFromId(appointment.appointmentTypeId);

  const birthdateFormatted = useMemo(() => {
    if (!appointment?.patientBirthDate) {
      return '';
    }
    const date = new Date(
      parseInt(appointment.patientBirthDate.split('-')[0]),
      parseInt(appointment.patientBirthDate.split('-')[1]) - 1,
      parseInt(appointment.patientBirthDate.split('-')[2]),
    );

    return format(date, 'MM/dd/yyyy');
  }, [appointment]);

  return (
    <>
      <div className="text-lg text-gray-900 font-bold">{appointment.patientDisplayLongReverse}</div>
      <div className="mt-1 text-gray-900">
        <FontAwesomeIcon icon={faAnglesRight} className="mr-2 text-blue-700" />
        {appointmentType?.name} - {appointmentStatus?.name}
      </div>
      <div className="mt-1 text-gray-900">
        Birth Date:
        {appointment.patientBirthDate && <span className="font-bold"> {birthdateFormatted}</span>}
        {!appointment.patientBirthDate && <span className="text-gray-400 text-sm italic"> Not provided</span>}
      </div>
      <div>
        Phone 1:
        {appointment.patientPhone1 && <span className="font-bold"> {appointment.patientPhone1}</span>}
        {!appointment.patientPhone1 && <span className="text-gray-400 text-sm italic"> Not provided</span>}
      </div>
      <div>
        Phone 2:
        {appointment.patientPhone2 && <span className="font-bold"> {appointment.patientPhone2}</span>}
        {!appointment.patientPhone2 && <span className="text-gray-400 text-sm italic"> Not provided</span>}
      </div>
      {appointment.appointmentNote && (
        <>
          <div className="mt-1 text-gray-900">Appointment Notes:</div>
          <div>{appointment.appointmentNote}</div>
        </>
      )}
    </>
  );
};
