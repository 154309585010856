import React from 'react';
import { Appointment } from 'app/api/AppointmentApi';
import { useCopyPaste } from 'utils/appointmentCopyPaste';
import classNames from 'classnames';

export type AppointmentListContextMenuLayoutProps = {
  appointment: Appointment;
  close: () => void;
};

interface IContextMenuItem {}

export class ContextMenuClickable implements IContextMenuItem {
  constructor(public description: string | JSX.Element, public onClick: () => void, public disabled: boolean) {}
}

export const AppointmentListContextMenuLayout = ({ appointment, close }: AppointmentListContextMenuLayoutProps) => {
  const copyPaste = useCopyPaste();

  const copyAppointment = new ContextMenuClickable(
    'Copy Appointment',
    () => {
      copyPaste.copyAppointment(appointment);
      close();
    },
    false,
  );

  const contextMenuItems: IContextMenuItem[] = [];
  contextMenuItems.push(copyAppointment);

  const isClickable = (item: IContextMenuItem) => item instanceof ContextMenuClickable;
  const clickyItem = (item: IContextMenuItem) => item as ContextMenuClickable;

  return (
    <div className="flex ">
      <div className={'w-64'}>
        {contextMenuItems.map((item, index) => (
          <div key={index}>
            {!isClickable(item) && <div className="border-t-2 border-gray-200" />}
            {isClickable(item) && (
              <button
                disabled={clickyItem(item).disabled}
                type="button"
                className={classNames(
                  'block px-4 py-1 w-full text-sm text-gray-700 text-left disabled:opacity-50 disabled:cursor-not-allowed',
                  !clickyItem(item).disabled ? 'hover:bg-gray-100' : '',
                )}
                onClick={clickyItem(item).onClick}
              >
                {clickyItem(item).description}
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
