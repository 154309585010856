import { Disclosure } from '@headlessui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { INavigationItem } from './navBarLinks';

export interface IDisclosureButtonProps {
  className: string;
  navItem: INavigationItem;
  children: React.ReactNode;
}

export function DisclosureButton({ className, navItem, children }: IDisclosureButtonProps) {
  if (navItem.newTab) {
    return (
      <Disclosure.Button as="a" href={navItem.href} className={className} target="_blank" rel="noreferrer">
        {children}
      </Disclosure.Button>
    );
  }

  return navItem.isExternal ? (
    <Disclosure.Button as="a" href={navItem.href} className={className}>
      {children}
    </Disclosure.Button>
  ) : (
    <Disclosure.Button as={Link} to={navItem.href} className={className}>
      {children}
    </Disclosure.Button>
  );
}
