import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ChangeEventHandler, forwardRef, useId, useMemo, useState } from 'react';
import { InfoTip } from '../Tooltip';
import { GenericInputGroupProps } from './InputGroup';

// where T can be string | number | readonly string[] | undefined
export type RadioOptionsWithTextInputGroupProps = GenericInputGroupProps<HTMLInputElement, string> & {
  tooltip?: string;
  inlineList?: boolean;
  options: { description: string; value: string }[];
  value: string;
};

export const RadioOptionsWithTextInputGroup = forwardRef<HTMLInputElement, RadioOptionsWithTextInputGroupProps>(
  (
    {
      label,
      tooltip,
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      value,
      options,
      inlineList,
    }: RadioOptionsWithTextInputGroupProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const baseClass = 'focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300';
    const invalidClass = classNames(baseClass, 'focus:ring-red-500 text-red-900 placeholder-red-300');

    const id = useId();

    const iterableErrorMessages = useMemo(() => {
      if (errors?.message) {
        if (Array.isArray(errors.message)) {
          return errors.message;
        }
        return [errors.message];
      }
      return [];
    }, [errors]);

    const onChange: ChangeEventHandler<HTMLInputElement> = event => {
      setValue(event.target.value);
      clearErrors();
    };

    const isChecked = (option: { description: string; value: string }) => {
      return value?.toString() === option.value?.toString();
    };

    const isOtherChecked = () => {
      return !options.some(option => isChecked(option));
    };

    const [textBoxValue, _setTextBoxValue] = useState<string>(() => {
      const optionVals = options.map(option => option.value);
      if (!optionVals.includes(value)) {
        return value;
      } else {
        return '';
      }
    });

    const setTextBoxValue = (value: string) => {
      _setTextBoxValue(value);
      setValue(value);
    };

    return (
      <div>
        {label && (
          <label htmlFor={id} className="block text-sm font-medium text-gray-700">
            {label}
            {tooltip && <InfoTip content={tooltip} />}
          </label>
        )}
        <div className={classNames('relative rounded-md -mt-0.5', inlineList ? 'flex space-x-2' : '')} id={id}>
          {options.map((option, index) => (
            <div key={index} className="flex items-center">
              <label
                htmlFor={option.value?.toString() + id}
                className={classNames(
                  'ml-2 block text-sm font-medium leading-6 cursor-pointer',
                  errors ? 'text-red-900' : 'text-gray-900',
                  value?.toString() === option.value?.toString() ? 'text-indigo-900 font-extrabold' : 'text-gray-900',
                )}
              >
                <input
                  type="radio"
                  name={name}
                  className={errors ? invalidClass : baseClass}
                  ref={ref}
                  checked={isChecked(option)}
                  onChange={onChange}
                  value={option.value?.toString()}
                  id={option.value?.toString() + id}
                  {...(errors && { 'aria-invalid': 'true' })}
                  {...(errors && { 'aria-describedby': `${option.value?.toString() + id}-error` })}
                />
                <span className="ml-1">{option.description}</span>
              </label>
            </div>
          ))}
          <div className="flex items-center">
            <label
              htmlFor={'other' + id}
              className={classNames(
                'ml-2 text-sm font-medium leading-6 flex flex-row items-center cursor-pointer',
                errors ? 'text-red-900' : 'text-gray-900',
                // vertical align the text with the radio button
              )}
            >
              <input
                type="radio"
                name={name}
                className={errors ? invalidClass : baseClass}
                ref={ref}
                checked={isOtherChecked()}
                onChange={onChange}
                value={textBoxValue}
                {...(errors && { 'aria-invalid': 'true' })}
                {...(errors && { 'aria-describedby': `${'other' + id}-error` })}
              />
              <span className={classNames('ml-1', isOtherChecked() ? 'text-indigo-900 font-extrabold' : 'text-gray-900')}>
                Other
              </span>
              <input
                type="text"
                name={name}
                id={'other' + id}
                value={textBoxValue}
                onChange={e => setTextBoxValue(e.target.value)}
                className="ml-2 block w-36 pl-2 sm:text-sm border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:pl-3"
                placeholder="Specify..."
                aria-describedby={`${'other' + id}-error`}
                {...(errors && { 'aria-invalid': 'true' })}
                onFocus={() => setValue(textBoxValue)}
                tabIndex={-1}
              />
            </label>
          </div>
          {errors && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <FontAwesomeIcon className="h-5 w-5 text-red-500" icon={faTriangleExclamation} aria-hidden="true" />
            </div>
          )}
        </div>
        {iterableErrorMessages.map((message, index) => (
          <p key={index} className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {message}
          </p>
        ))}
      </div>
    );
  },
);
