import React from 'react';
import { LawFirmInputGroup } from '../../../components/Forms/LawFirmInputGroup/LawFirmInputGroup';
import { LawOfficeInputGroup } from '../../../components/Forms/LawOfficeInputGroup/LawOfficeInputGroup';
import { AttorneyInputGroup } from '../../../components/Forms/AttorneyInputGroup/AttorneyInputGroup';
import { ParalegalInputGroup } from '../../../components/Forms/ParalegalInputGroup/ParalegalInputGroup';
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { MultifieldOutput } from '../../../components/Forms/FieldHook';
import { useFlexworxAnciallry } from '../../../../utils/flexworx-ancillary';
import { TextAreaGroup } from '../../../components/Forms/TextAreaGroup';
import { InputGroup } from '../../../components/Forms/InputGroup';

export type RepresentationRangeProps = {
  ranges: MultifieldOutput;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  index: number;
  setValue: UseFormSetValue<FieldValues>;
};

export const RepresentationRange = ({ ranges, watch, index, register, setValue }: RepresentationRangeProps) => {
  const watchLawFirmId = watch(`ranges[${index}].lawFirmId`);
  const watchLawOfficeId = watch(`ranges[${index}].lawOfficeId`);
  const watchAttorneyId = watch(`ranges[${index}].attorneyId`);
  const watchParalegalId = watch(`ranges[${index}].paralegalId`);

  const lawFirmId = register(`ranges[${index}].lawFirmId`);
  const lawOfficeId = register(`ranges[${index}].lawOfficeId`);
  const attorneyId = register(`ranges[${index}].attorneyId`);
  const paralegalId = register(`ranges[${index}].paralegalId`);

  const setLawFirmId = (index: number) => (value: string) => setValue(`ranges[${index}].lawFirmId`, value);
  const setLawOfficeId = (index: number) => (value: string) => setValue(`ranges[${index}].lawOfficeId`, value);
  const setAttorneyId = (index: number) => (value: string) => setValue(`ranges[${index}].attorneyId`, value);
  const setParalegalId = (index: number) => (value: string) => setValue(`ranges[${index}].paralegalId`, value);
  const setNotesValue = (value: string) => setValue(`ranges[${index}].notes`, value);

  const setStartDate = (value: string) => setValue(`ranges[${index}].start`, value);
  const setEndDate = (value: string) => setValue(`ranges[${index}].end`, value);

  const { lawFirmFromId, lawOfficeFromId, attorneysFromIds, paralegalsFromIds } = useFlexworxAnciallry();

  return (
    <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-12 w-full">
      <div className="sm:col-span-6 lg:col-span-4">
        <LawFirmInputGroup
          {...lawFirmId}
          lawOfficeId={watchLawOfficeId}
          attorneyId={watchAttorneyId}
          paralegalId={watchParalegalId}
          label="Law Firm"
          setValue={setLawFirmId(index)}
          clearErrors={ranges.clearErrors(index, 'lawFirmId')}
          errors={ranges.errors(index, 'lawFirmId')}
          defaultModel={lawFirmFromId(watchLawFirmId)}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4">
        <LawOfficeInputGroup
          {...lawOfficeId}
          lawFirmId={watchLawFirmId}
          attorneyId={watchAttorneyId}
          paralegalId={watchParalegalId}
          label="Law Office"
          setValue={setLawOfficeId(index)}
          clearErrors={ranges.clearErrors(index, 'lawOfficeId')}
          errors={ranges.errors(index, 'lawOfficeId')}
          defaultModel={lawOfficeFromId(watchLawOfficeId)}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4">
        <AttorneyInputGroup
          {...attorneyId}
          lawFirmId={watchLawFirmId}
          lawOfficeId={watchLawOfficeId}
          paralegalId={watchParalegalId}
          label="Attorney"
          setValue={setAttorneyId(index)}
          clearErrors={ranges.clearErrors(index, 'attorneyId')}
          errors={ranges.errors(index, 'attorneyId')}
          defaultModel={attorneysFromIds([watchAttorneyId])[0]}
        />
      </div>
      <div className="sm:col-span-6 lg:col-span-4">
        <ParalegalInputGroup
          {...paralegalId}
          lawFirmId={watchLawFirmId}
          lawOfficeId={watchLawOfficeId}
          attorneyId={watchAttorneyId}
          label="Paralegal"
          setValue={setParalegalId(index)}
          clearErrors={ranges.clearErrors(index, 'paralegalId')}
          errors={ranges.errors(index, 'paralegalId')}
          defaultModel={paralegalsFromIds([watchParalegalId])[0]}
        />
      </div>
      <div className="lg:col-span-2"></div>
      <div className="sm:col-span-3">
        <InputGroup
          label="Start Date"
          type="date"
          {...register(`ranges[${index}].start`)}
          errors={ranges.errors(index, 'start')}
          clearErrors={ranges.clearErrors(index, 'start')}
          setValue={setStartDate}
          placeholder={new Date().toISOString()}
        />
      </div>
      <div className="sm:col-span-3">
        <InputGroup
          label="End Date"
          type="date"
          {...register(`ranges[${index}].end`)}
          errors={ranges.errors(index, 'end')}
          clearErrors={ranges.clearErrors(index, 'end')}
          setValue={setEndDate}
          placeholder={new Date().toISOString()}
        />
      </div>
      <div className="sm:col-span-12">
        <TextAreaGroup
          {...register(`ranges[${index}].notes`)}
          label="Notes"
          placeholder="Notes"
          errors={ranges.errors(index, 'notes')}
          clearErrors={ranges.clearErrors(index, 'notes')}
          setValue={setNotesValue}
        />
      </div>
    </div>
  );
};
