import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';

export const InboxWithNumber = ({ number: _number }: { number: number }) => {
  const [number, setNumber] = useState(_number);

  const debouncer = debounce((number: number) => {
    setNumber(number);
  }, 700);

  const numberCallback = useCallback(debouncer, [debouncer]);

  useEffect(() => {
    numberCallback(_number);
  }, [_number, numberCallback]);

  const color = useMemo(() => {
    if (number < 5) {
      return 'green';
    } else if (number < 10) {
      return 'yellow';
    } else {
      return 'red';
    }
  }, [number]);

  const numberText = useMemo(() => {
    if (number <= 20) {
      return number.toString();
    } else {
      return '20+';
    }
  }, [number]);

  const width = useMemo(() => {
    if (number <= 20) {
      return 'w-5';
    }
    return 'w-7';
  }, [number]);

  const numberColors = useMemo(() => {
    if (color === 'green') {
      return 'text-white bg-green-600 group-hover:bg-green-300 group-hover:text-gray-900';
    } else if (color === 'yellow') {
      return 'text-gray-900 bg-yellow-300 group-hover:bg-yellow-200 group-hover:text-gray-700';
    } else {
      return 'text-white bg-red-600 group-hover:bg-red-400 group-hover:text-gray-900';
    }
  }, [color]);

  return (
    <strong className={classNames('relative inline-flex items-center text-xs font-medium group pt-1 ')}>
      {number > 0 && (
        <>
          <span
            className={classNames(
              width,
              numberColors,
              'absolute -top-1.5 -right-2 h-5 rounded-full flex justify-center items-center items',
            )}
          >
            <span>{numberText}</span>
          </span>
        </>
      )}
      <FontAwesomeIcon icon={faInbox} className="h-4 w-4 text-gray-400 group-hover:text-gray-500" />
    </strong>
  );
};
