import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';

export type BusinessHours = {
  isOpen: boolean;
  // in the format of HH:mm
  timeStart?: string;
  // in the format of HH:mm
  timeEnd?: string;
  // earliest time that can be scheduled
  earliestCheckIn?: string;
};

export type ScheduleDefinition = NewScheduleDefinitionRequest & Auditable;

export type NewScheduleDefinitionRequest = {
  name: string;
  clinicLocationId: string;
  scheduleTypeId: string;
  notes?: string;
  sunday: BusinessHours;
  monday: BusinessHours;
  tuesday: BusinessHours;
  wednesday: BusinessHours;
  thursday: BusinessHours;
  friday: BusinessHours;
  saturday: BusinessHours;
  maxAppointmentsPerTimeslot: number;
  defaultAppointmentTypeId: string;
  smsIsEnabled: boolean;
  color?: string;
  timeslotInterval: number;
  appointmentInterval: number;
  appointmentTakesUpSpace: boolean;
  nightClinicStartTime?: string;
};

export const useGetScheduleDefinitions = () => {
  const { computeClient: client } = useApiClient();
  return useQuery(['schedule-definition'], () =>
    client.get<ScheduleDefinition[]>('/schedule-definition').then(response => response.data),
  );
};

export const useGetScheduleDefinition = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['schedule-definition', id], () =>
    client.get<ScheduleDefinition>(`/schedule-definition/${id}`).then(response => {
      return response.data;
    }),
  );
};

export const useCreateScheduleDefinition = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<ScheduleDefinition, AxiosError, NewScheduleDefinitionRequest>({
    mutationFn: async function (request: NewScheduleDefinitionRequest) {
      const response = await client.post('/schedule-definition', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-definition']);
    },
  });
};

export const useUpdateScheduleDefinition = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<ScheduleDefinition, AxiosError, ScheduleDefinition>({
    mutationFn: request => client.put('/schedule-definition', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-definition']);
    },
  });
};

export const useDeleteScheduleDefinition = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/schedule-definition/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['schedule-definition']);
    },
  });
};
