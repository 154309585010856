import { Table } from '@tanstack/react-table';
import React from 'react';
import { SimpleTable } from './SimpleTable';
import { LoadingWrapper } from '../../../utils/loadingWrapper';

export type SimpleItemListParams<T> = {
  table: Table<T> | undefined;
  isLoading: boolean;
  isError: boolean;
  headerPlural: string;
  description?: string | React.JSX.Element;
  onClick: (item: T) => void;
  wrapLines?: boolean;
};

export function SimpleItemList<T>({
  table,
  isLoading,
  isError,
  headerPlural,
  description,
  onClick,
  wrapLines,
}: SimpleItemListParams<T>) {
  const totalNumber = table?.getRowModel().rows.length ?? 0;

  return (
    <>
      <div className="flex flex-col space-y-4 ">
        <h1 className="mt-4">
          <span className="text-2xl">{headerPlural}</span>
          {table && <span className="ml-2 text-lg text-gray-600 inline-block align-bottom">({totalNumber})</span>}
        </h1>
        {description && <div className="text-gray-600">{description}</div>}
      </div>

      <div className="mt-4 flex flex-col">
        <LoadingWrapper isLoading={isLoading} isError={isError}>
          <SimpleTable table={table} onClick={onClick} wrapLines={wrapLines} />
        </LoadingWrapper>
      </div>
    </>
  );
}
