import { createContext, useContext } from 'react';
import React from 'react';
import { Paralegal, useGetParalegals } from 'app/api/ParalegalApi';
import { Attorney, useGetAttorneys } from 'app/api/AttorneyApi';
import { LawFirm, useGetLawFirms } from 'app/api/LawFirmApi';
import { TreatmentCenter, useGetTreatmentCenters } from 'app/api/TreatmentCenterApi';
import { LawOffice, useGetLawOffices } from 'app/api/LawOfficeApi';

export interface IFlexworxAncillaryContext {
  paralegals: Paralegal[] | null;
  attorneys: Attorney[] | null;
  lawFirms: LawFirm[] | null;
  lawOffices: LawOffice[] | null;
  treatmentCenters: TreatmentCenter[] | null;
  isLoading: boolean;
  isError: boolean;
  lawFirmFromId: (id: string | undefined | null) => LawFirm | undefined;
  lawOfficeFromId: (id: string | undefined | null) => LawOffice | undefined;
  attorneysFromIds: (ids: string[]) => Attorney[];
  paralegalsFromIds: (ids: string[]) => Paralegal[];
  lawOfficesFromIds: (ids: string[]) => LawOffice[];
  treatmentCenterFromId: (id: string | undefined | null) => TreatmentCenter | undefined;
}

const FlexworxAncillaryContext = createContext<IFlexworxAncillaryContext>({
  paralegals: null,
  attorneys: null,
  lawFirms: null,
  lawOffices: null,
  treatmentCenters: null,
  isLoading: false,
  isError: false,
  lawFirmFromId: () => undefined,
  lawOfficeFromId: () => undefined,
  attorneysFromIds: () => [],
  paralegalsFromIds: () => [],
  lawOfficesFromIds: () => [],
  treatmentCenterFromId: () => undefined,
});

export function FlexworxAncillaryProvider({ children }) {
  const { data: paralegals, isLoading: paralegalsIsLoading, isError: paralegalsIsError } = useGetParalegals();
  const { data: attorneys, isLoading: attorneysIsLoading, isError: attorneysIsError } = useGetAttorneys();
  const { data: lawFirms, isLoading: lawFirmsIsLoading, isError: lawFirmsIsError } = useGetLawFirms();
  const { data: lawOffices, isLoading: lawOfficesIsLoading, isError: lawOfficesIsError } = useGetLawOffices();
  const {
    data: treatmentCenters,
    isLoading: treatmentCentersIsLoading,
    isError: treatmentCentersIsError,
  } = useGetTreatmentCenters();

  const providerValue = {
    paralegals: paralegals || null,
    attorneys: attorneys || null,
    lawFirms: lawFirms || null,
    lawOffices: lawOffices || null,
    treatmentCenters: treatmentCenters || null,
    isLoading: paralegalsIsLoading || attorneysIsLoading || lawFirmsIsLoading || treatmentCentersIsLoading || lawOfficesIsLoading,
    isError: paralegalsIsError || attorneysIsError || lawFirmsIsError || treatmentCentersIsError || lawOfficesIsError,
    lawFirmFromId: (id: string | undefined | null) => lawFirms?.find(lawFirm => lawFirm.id === id),
    lawOfficeFromId: (id: string | undefined | null) => lawOffices?.find(lawOffice => lawOffice.id === id),
    attorneysFromIds: (ids: string[]) => attorneys?.filter(x => ids.includes(x.id)) ?? [],
    paralegalsFromIds: (ids: string[]) => paralegals?.filter(x => ids.includes(x.id)) ?? [],
    lawOfficesFromIds: (ids: string[]) => lawOffices?.filter(x => ids.includes(x.id)) ?? [],
    treatmentCenterFromId: (id: string | undefined | null) => treatmentCenters?.find(x => x.id === id),
  };

  return <FlexworxAncillaryContext.Provider value={providerValue}>{children}</FlexworxAncillaryContext.Provider>;
}

export function useFlexworxAnciallry() {
  return useContext(FlexworxAncillaryContext);
}
