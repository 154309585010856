import { faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { MouseEventHandler, forwardRef, useId, useMemo, useEffect } from 'react';
import { GenericInputGroupProps } from '../InputGroup';
import { InfoTip } from 'app/components/Tooltip';
import { Paralegal } from '../../../api/ParalegalApi';

export type TableInputTextFieldProps<T> = GenericInputGroupProps<HTMLInputElement, string> & {
  placeholder: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onClear?: () => void;
  model: T | null;
};

export const ParalegalInputTextField = forwardRef<HTMLInputElement, TableInputTextFieldProps<Paralegal>>(
  (
    {
      label,
      placeholder,
      name,
      errors,
      onClick: _onClick,
      clearErrors,
      tooltip,
      model,
      onClear: _onClear,
    }: TableInputTextFieldProps<Paralegal>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [textVal, setTextVal] = React.useState<string>(() => {
      if (!model) {
        return '';
      }
      return model?.firstName + ' ' + model?.lastName;
    });

    useEffect(() => {
      if (!model) {
        setTextVal('');
        return;
      }
      setTextVal(model?.firstName + ' ' + model?.lastName);
    }, [model]);

    const onClear = () => {
      clearErrors();
      setTextVal('');
      if (_onClear) {
        _onClear();
      }
    };

    const textRef = React.useRef<HTMLInputElement>(null);
    const baseClass =
      'block w-full rounded-md border-gray-300 text-gray-900 placeholder-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm cursor-pointer';
    const invalidClass = classNames(
      baseClass,
      'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500',
    );

    const onClick: MouseEventHandler<HTMLInputElement> = event => {
      clearErrors();
      if (_onClick) {
        _onClick(event);
      }
    };

    const id = useId();

    const iterableErrorMessages = useMemo(() => {
      if (errors?.message) {
        if (Array.isArray(errors.message)) {
          return errors.message;
        }
        return [errors.message];
      }
      return [];
    }, [errors]);

    return (
      <>
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
          {tooltip && <InfoTip content={tooltip} />}
        </label>
        <div className="relative mt-1 rounded-md shadow-sm">
          <input
            onClick={onClick}
            name={name}
            ref={textRef}
            type="text"
            readOnly={true}
            id={id}
            value={textVal}
            className={errors ? invalidClass : baseClass}
            placeholder={placeholder}
            {...(errors && { 'aria-invalid': 'true' })}
            {...(errors && { 'aria-describedby': `${id}-error` })}
            autoComplete="off"
          />

          {onClear && (
            <>
              {model && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer" onClick={onClear}>
                  <FontAwesomeIcon className="h-5 w-5 text-gray-400" icon={faXmark} aria-hidden="true" />
                </div>
              )}
            </>
          )}

          <input ref={ref} type="hidden" />

          {errors && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <FontAwesomeIcon className="h-5 w-5 text-red-500" icon={faTriangleExclamation} aria-hidden="true" />
            </div>
          )}
        </div>
        {iterableErrorMessages.map((message, index) => (
          <p key={index} className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {message}
          </p>
        ))}
      </>
    );
  },
);
