import { Transition } from '@headlessui/react';
import { Modal } from 'app/components/Modal';
import React from 'react';
import { Patient } from 'app/api/PatientApi';
import { Appointment } from 'app/api/AppointmentApi';
import { AppointmentFormProvider } from '../AppointmentFormHook';
import { AppointmentFormProviderInsulation } from './AppointmentModalEdit';
import { FieldValues } from 'react-hook-form';

export type AppointmentModalNewProps = {
  isOpen: boolean;
  closeModal: () => void;
  time: string;
  date: string;
  scheduleDefinitionId: string | null;
  patient?: Patient;
  hasAppointmentsProvider: boolean;
  defaultValues?: FieldValues;
  onAppointmentSubmitSuccess: (appointment: Appointment) => void;
};

export const AppointmentModalNew = ({
  isOpen,
  closeModal: _closeModal,
  time: _time,
  date: _date,
  scheduleDefinitionId: _scheduleDefinitionId,
  defaultValues,
  patient,
  onAppointmentSubmitSuccess,
}: AppointmentModalNewProps) => {
  const closeModal = () => {
    _closeModal();
  };

  defaultValues = {
    ...defaultValues,
    appointmentTime: _time,
    appointmentDate: _date,
    scheduleDefinitionId: _scheduleDefinitionId,
  };

  return (
    <Modal width="sm:max-w-7xl" closeModalText={'Cancel'} open={isOpen} closeModal={closeModal}>
      <Transition
        show={isOpen}
        enter="transition duration-300 ease-out"
        enterFrom="transform scale-75 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-300 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-75 opacity-0"
      >
        <AppointmentFormProvider
          defaultValues={defaultValues}
          initialScheduleDefinitionId={_scheduleDefinitionId}
          initialTime={_time}
          initialDate={_date}
          initialPatient={patient}
        >
          <AppointmentFormProviderInsulation
            closeModal={closeModal}
            shouldMoveTabsUp={true}
            onAppointmentSubmitSuccess={onAppointmentSubmitSuccess}
          />
        </AppointmentFormProvider>
      </Transition>
    </Modal>
  );
};
