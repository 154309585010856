import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { PagedQueryRequest, PagedQueryResponse } from './Pagination';
import { Auditable } from './Auditable';

export type Attorney = NewAttorneyRequest &
  Auditable & {
    paralegalIds: string[];
  };

export type NewAttorneyRequest = {
  firstName: string;
  lastName: string;
  lawOfficeId: string;
  phone?: string;
  email?: string;
  notes?: string;
};

export const useGetAttorneys = (request?: PagedQueryRequest) => {
  const { computeClient: client } = useApiClient();
  const requestOverride = {} as PagedQueryRequest;
  if (request) {
    requestOverride.pageSize = request.pageSize || 50;
    requestOverride.lastKeyEvaluated = request.lastKeyEvaluated;
  }
  return useQuery(['attorney', request], async () => {
    let list: Attorney[] = [];
    while (true) {
      const response = await client.post<PagedQueryResponse<Attorney>>('/attorney/list', request || null);
      list = list.concat(response.data.items);
      if (Object.keys(response.data.lastKeyEvaluated as {}).length > 0) {
        requestOverride.lastKeyEvaluated = response.data.lastKeyEvaluated;
      } else {
        break;
      }
    }
    return list;
  });
};

export const useGetAttorney = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['attorney', id], () => client.get<Attorney>(`/attorney/${id}`).then(response => response.data));
};

export const useCreateAttorney = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Attorney, AxiosError, NewAttorneyRequest>({
    mutationFn: async function (request: NewAttorneyRequest) {
      const response = await client.post('/attorney', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['attorney']);
    },
  });
};

export const useUpdateAttorney = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Attorney, AxiosError, Attorney>({
    mutationFn: request => client.put('/attorney', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['attorney']);
    },
  });
};

export const useDeleteAttorney = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/attorney/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['attorney']);
    },
  });
};
