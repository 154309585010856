import React from 'react';
import { Patient, useGetPatientSupplemental } from '../../api/PatientApi';
import { EditPatientDemographicsForm } from '../../components/PatientForm/EditPatientDemographicsForm';
import { EditPatientSupplementalForm } from '../../components/PatientForm/EditPatientSupplementalForm';
import { LoadingWrapper } from '../../../utils/loadingWrapper';

export type PatientDemographicsProps = {
  patient: Patient;
};

export const PatientDemographics = ({ patient }: PatientDemographicsProps) => {
  const { data, isLoading, isError } = useGetPatientSupplemental(patient.id);

  return (
    <div>
      <h1 className="text-2xl font-semibold text-indigo-900 ">Demographics</h1>
      <div className="italic ">Non-Clinical Patient Data</div>
      <EditPatientDemographicsForm defaultValues={patient} />
      <h1 className="text-2xl font-semibold text-indigo-900 ">Patient Intake</h1>
      <div className="italic ">Intake & Injury Details</div>
      <LoadingWrapper isLoading={isLoading} isError={isError}>
        <EditPatientSupplementalForm defaultValues={data!} />
      </LoadingWrapper>
    </div>
  );
};
