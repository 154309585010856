// The SchedulePreFetcher is an optimization that can make it seem like the app works super fast.
// The tradeoff is a bunch of extra calls. But that could be worth it for the responsiveness.

import { usePrefetchAppointments } from '../../api/AppointmentApi';
import { useState } from 'react';

export const useSchedulePreFetcher = () => {
  const prefetchAppointments = usePrefetchAppointments();

  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [isPrefetching, setIsPrefetching] = useState<boolean>(false);

  const onPrefetch = async (scheduleDefinitionId: string | null, date: string) => {
    // If we need a speed boost, we can add this in!
    if (process.env.REACT_APP_PREFETCH_SCHEDULE_SPEED_BOOST && scheduleDefinitionId && date) {
      try {
        setIsPrefetching(true);
        await prefetchAppointments(scheduleDefinitionId, date, 20000);
      } finally {
        setIsPrefetching(false);
      }
    }
  };

  const onRefresh = async (scheduleDefinitionId: string | null, date: string) => {
    if (scheduleDefinitionId && date) {
      try {
        setIsRefreshing(true);
        await prefetchAppointments(scheduleDefinitionId, date);
      } finally {
        setIsRefreshing(false);
      }
    }
  };

  return {
    onPrefetch,
    onRefresh,
    isRefreshing,
    isPrefetching,
  };
};
