import React, { useMemo, useState } from 'react';
import { PagedItemList } from 'app/components/Forms/PagedItemList';
import { FormActionProps } from 'app/components/Forms/FormActionProps';
import { Paralegal } from 'app/api/ParalegalApi';
import { ParalegalForm } from 'app/components/Auxillary/ParalegalForm';
import { useFlexworxAnciallry } from 'utils/flexworx-ancillary';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  ColumnFiltersState,
  getFilteredRowModel,
  ColumnSort,
} from '@tanstack/react-table';
import { fuzzyFilter } from './TreatmentCenterList';
import { SimpleItemNewButton } from '../../components/Forms/SimpleItemNewButton';
import { SimpleItemEditModal } from '../../components/Forms/SimpleItemEditModal';

type HydratedParalegal = Paralegal & {
  lawOfficeName: string;
  attorneyNames: string;
};

export const ParalegalList = ({
  initialAttorneyId,
  initialLawOfficeId,
  onSelect,
  allowNew,
}: {
  initialAttorneyId?: string | null;
  initialLawOfficeId?: string | null;
  onSelect?: (paralegal: Paralegal) => void;
  allowNew: boolean;
}) => {
  const { paralegals: _paralegals, attorneysFromIds, lawOfficeFromId, isError, isLoading } = useFlexworxAnciallry();

  const [sorting, setSorting] = useState<ColumnSort[]>(() => {
    return [{ id: 'lastName', desc: false }];
  });

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(() => {
    const initialState: ColumnFiltersState = [];
    if (initialLawOfficeId) {
      const initialLawOffice = lawOfficeFromId(initialLawOfficeId);
      initialState.push({
        id: 'lawOfficeName',
        value: initialLawOffice?.description ?? '',
      });
    }
    if (initialAttorneyId) {
      const initialAttorney = attorneysFromIds([initialAttorneyId])[0];
      initialState.push({
        id: 'attorneyNames',
        value: [initialAttorney?.firstName, initialAttorney?.lastName].join(' '),
      });
    }
    return initialState;
  });

  const paralegals: null | HydratedParalegal[] = useMemo(() => {
    if (!_paralegals) return null;
    return _paralegals.map(a => {
      const lawOffice = lawOfficeFromId(a.lawOfficeId);
      const lawOfficeName = lawOffice?.description ?? '';

      const attorneys = attorneysFromIds(a.attorneyIds);
      const attorneyNames = attorneys.map(x => [x.firstName, x.lastName].join(' ')).join('\n');

      return {
        ...a,
        lawOfficeName: lawOfficeName,
        attorneyNames: attorneyNames,
      };
    });
  }, [_paralegals, attorneysFromIds, lawOfficeFromId]);

  const columnHelper = createColumnHelper<HydratedParalegal>();
  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row.firstName, {
        id: 'firstName',
        cell: info => info.getValue(),
        header: () => <span>First Name</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.lastName, {
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.lawOfficeName, {
        id: 'lawOfficeName',
        cell: info => info.getValue(),
        header: () => <span>Law Office</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.attorneyNames, {
        id: 'attorneyNames',
        cell: info => info.getValue(),
        header: () => <span>Attorneys</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.phone, {
        id: 'phone',
        cell: info => info.getValue(),
        header: () => <span>Phone</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: paralegals ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const createForm = (props: FormActionProps<Paralegal>) => <ParalegalForm {...props} />;
  const editForm = (item: Paralegal, props: FormActionProps<Paralegal>) => <ParalegalForm defaultValues={item} {...props} />;

  const [editModalIsVisible, setEditModalIsVisible] = useState(false);
  const [editModalItem, setEditModalItem] = useState<Paralegal | null>(null);
  const onClick = (item: Paralegal) => {
    if (onSelect) {
      onSelect(item);
      return;
    }
    setEditModalItem(item);
    setEditModalIsVisible(true);
  };

  return (
    <>
      {allowNew && (
        <SimpleItemNewButton
          children={createForm}
          headerSingular="Paralegal"
          getName={(item: Paralegal) => `${item.firstName} ${item.lastName}`}
        />
      )}

      <SimpleItemEditModal
        children={props => editForm(editModalItem!, props)}
        headerSingular="Paralegal"
        getName={(item: Paralegal) => `${item.firstName} ${item.lastName}`}
        closeModal={() => setEditModalIsVisible(false)}
        isVisible={editModalIsVisible}
      />
      <PagedItemList table={table} isError={isError} isLoading={isLoading} headerPlural="Paralegals" onClick={onClick} />
    </>
  );
};
