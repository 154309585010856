import React, { useEffect } from 'react';
import { GenericInputGroupProps } from '../InputGroup';
import { AttorneysInputTextField } from './AttorneysInputTextField';
import { useFlexworxAnciallry } from '../../../../utils/flexworx-ancillary';
import { Modal } from '../../Modal';
import { AttorneyList } from '../../../pages/AncillaryDataPage/AttorneyList';
import { MultiselectSelectedItemsRows } from '../MultiselectSelectedItemsRow';

export type AttorneysInputGroupProps = GenericInputGroupProps<HTMLInputElement, string[]> & {
  attorneyIds: string[];
  lawFirmId: string | null;
  lawOfficeId: string | null;
  paralegalId: string | null;
};

export const AttorneysInputGroup = ({
  clearErrors,
  setValue,
  attorneyIds,
  lawOfficeId,
  lawFirmId,
  paralegalId,
  label,
  name,
}: AttorneysInputGroupProps) => {
  const [showSelectionModal, setShowSelectionModal] = React.useState<boolean>(false);

  const onSelection = (attorneyId: string) => {
    clearErrors();
    if (!attorneyIds.includes(attorneyId)) {
      setValue([...attorneyIds, attorneyId]);
    }
    setShowSelectionModal(false);
  };

  const { attorneysFromIds, lawFirmFromId, attorneys, lawOfficeFromId, paralegalsFromIds, lawOffices } = useFlexworxAnciallry();

  const clear = () => {
    setValue([]);
  };

  // watch the lawFirmId
  useEffect(() => {
    if (!lawFirmId) {
      return;
    }
    // we will only clear the value if applicable, not set it.
    const lawFirm = lawFirmFromId(lawFirmId);
    if (!lawFirm) {
      return;
    }
    if (!lawOffices) {
      return;
    }
    const allowedLawOffices = lawOffices.filter(x => lawFirm.lawOfficeIds.includes(x.id));
    if (allowedLawOffices.length === 0) {
      clear();
    }
    if (!attorneys) {
      return;
    }
    const allowedLawOfficeIds = allowedLawOffices.map(x => x.id);
    const allowedAttorneys = attorneys.filter(x => allowedLawOfficeIds.includes(x.lawOfficeId));
    if (allowedAttorneys.length === 0) {
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawFirmId]);

  // watch the lawOfficeId
  React.useEffect(() => {
    if (!lawOfficeId) {
      return;
    }
    const lawOffice = lawOfficeFromId(lawOfficeId);
    if (!lawOffice) {
      return;
    }
    if (!attorneys) {
      return;
    }
    const allowedAttorneys = attorneys.filter(x => x.lawOfficeId === lawOfficeId);
    if (allowedAttorneys.length === 0) {
      clear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawOfficeId]);

  // watch the paralegalId
  useEffect(() => {
    if (!paralegalId) {
      return;
    }
    const paralegal = paralegalsFromIds([paralegalId])[0];
    if (!paralegal) {
      return;
    }
    if (!attorneys) {
      return;
    }
    const allowedAttorneys = attorneys.filter(x => x.lawOfficeId === paralegal.lawOfficeId);
    if (allowedAttorneys.length === 0) {
      clear();
    }
    if (allowedAttorneys.length === 1) {
      onSelection(allowedAttorneys[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paralegalId]);

  const removeSelected = (selected: string) => {
    clearErrors();
    setValue(attorneyIds.filter(v => v !== selected));
  };

  const getName = (value: string) => {
    const attorneys = attorneysFromIds([value]);
    if (!attorneys.length) {
      return 'Unknown';
    }
    return `${attorneys[0].lastName}, ${attorneys[0].firstName}`;
  };

  const getKey = (value: string) => value;

  return (
    <>
      <Modal
        width="sm:max-w-7xl"
        open={showSelectionModal}
        closeModal={() => setShowSelectionModal(false)}
        closeModalText="Cancel"
      >
        <AttorneyList
          initialLawOfficeId={lawOfficeId}
          initialParalegalId={paralegalId}
          onSelect={attorney => onSelection(attorney.id)}
          allowNew={false}
        />
      </Modal>

      <div className="flex flex-col">
        <div className="w-full">
          <AttorneysInputTextField
            clearErrors={clearErrors}
            label={label}
            name={name}
            placeholder="Select Attorneys..."
            onClick={() => setShowSelectionModal(true)}
            setValue={attorneyId => onSelection(attorneyId)}
          />
          <MultiselectSelectedItemsRows items={attorneyIds} removeSelected={removeSelected} getName={getName} getKey={getKey} />
        </div>
      </div>
    </>
  );
};
