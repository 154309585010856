import { createContext, useContext } from 'react';
import React from 'react';
import { useRefreshWebsocketAuth, useWebsocketOneTimeCode } from 'app/api/WebsocketApi';

export interface IWebsocketWraperContext {
  websocketUrl: string | null;
  setError: (error: Event) => void;
}

const WebsocketWrapperContext = createContext<IWebsocketWraperContext>({
  websocketUrl: null,
  setError: () => {},
});

export function WebsocketWrapperProvider({ children }) {
  // this should only be rendered if we're logged in. If we're not logged in, we shouldn't be here.

  const { data: websocketUrl, isLoading } = useWebsocketOneTimeCode();

  const refreshAuth = useRefreshWebsocketAuth();

  const setError = async (error: Event) => {
    console.log('ERROR:::: ', error);
    if (!isLoading) {
      await refreshAuth();
    }
  };

  return (
    <WebsocketWrapperContext.Provider
      value={{
        websocketUrl: websocketUrl ?? null,
        setError,
      }}
    >
      <>{children}</>
    </WebsocketWrapperContext.Provider>
  );
}

export const useWebsocketWrapper = () => {
  return useContext(WebsocketWrapperContext);
};
