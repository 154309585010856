import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { MouseEventHandler, useId, useMemo } from 'react';
import { GenericInputGroupProps } from '../InputGroup';
import { InfoTip } from 'app/components/Tooltip';

export type AttorneyInputTextFieldProps = GenericInputGroupProps<HTMLInputElement, string> & {
  placeholder: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
};

export const AttorneysInputTextField = ({
  label,
  placeholder,
  name,
  errors,
  onClick: _onClick,
  clearErrors,
  tooltip,
}: AttorneyInputTextFieldProps) => {
  const baseClass =
    'block w-full rounded-md border-gray-300 text-gray-900 placeholder-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm cursor-pointer';
  const invalidClass = classNames(
    baseClass,
    'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500',
  );

  const onClick: MouseEventHandler<HTMLInputElement> = event => {
    clearErrors();
    if (_onClick) {
      _onClick(event);
    }
  };

  const id = useId();

  const iterableErrorMessages = useMemo(() => {
    if (errors?.message) {
      if (Array.isArray(errors.message)) {
        return errors.message;
      }
      return [errors.message];
    }
    return [];
  }, [errors]);

  return (
    <>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
        {tooltip && <InfoTip content={tooltip} />}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          onClick={onClick}
          name={name}
          type="text"
          readOnly={true}
          id={id}
          className={errors ? invalidClass : baseClass}
          placeholder={placeholder}
          {...(errors && { 'aria-invalid': 'true' })}
          {...(errors && { 'aria-describedby': `${id}-error` })}
          autoComplete="off"
        />

        {errors && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <FontAwesomeIcon className="h-5 w-5 text-red-500" icon={faTriangleExclamation} aria-hidden="true" />
          </div>
        )}
      </div>
      {iterableErrorMessages.map((message, index) => (
        <p key={index} className="mt-2 text-sm text-red-600" id={`${id}-error`}>
          {message}
        </p>
      ))}
    </>
  );
};
