import { Appointment } from 'app/api/AppointmentApi';
import React from 'react';
import { useToast } from 'utils/toast';
import { AppointmentForm } from '../../Patient/AppointmentSingleForm/AppointmentForm';

export type AppointmentAreaProps = {
  closeModal: () => void;
  onAppointmentSubmitSuccess: (appointment: Appointment) => void;
  openDemographics: () => void;
};

export const AppointmentArea = ({ closeModal, onAppointmentSubmitSuccess, openDemographics }: AppointmentAreaProps) => {
  const { toastError, toastInfo } = useToast();

  const onUnrecoverableError = (message: string | JSX.Element) => {
    // todo: we would probably want to log this error to Sentry or something
    toastError('Error', message);
  };

  const onSuccess = (appointment: Appointment) => {
    closeModal();
    onAppointmentSubmitSuccess(appointment);
  };

  const onCancel = () => {
    closeModal();
    toastInfo('Cancelled', `Nothing changed!`);
  };

  const onDeleted = () => {
    closeModal();
  };

  return (
    <>
      <AppointmentForm
        onCancel={onCancel}
        onSuccess={onSuccess}
        onDeleted={onDeleted}
        onUnrecoverableError={onUnrecoverableError}
        openDemographics={openDemographics}
      />
    </>
  );
};
