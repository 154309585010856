import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { Bucket, ClosestBucket } from '../BucketOperations';
import { useOnClickOutside } from 'utils/useOnClickOutside';
import classNames from 'classnames';
import { Appointment } from 'app/api/AppointmentApi';
import { TemporaryNote } from 'app/api/TemporaryNoteApi';
import { ContextMenuLayout } from './ContextMenuLayout';
import { Patient } from '../../../api/PatientApi';

export type DayTimeTableContextMenuProps<T extends HTMLElement = HTMLElement> = {
  x: number;
  y: number;
  bucket: Bucket;
  close: () => void;
  container: React.RefObject<T>;
  addButtonFor: (bucket: Bucket) => ClosestBucket | null;
  onNewAppointmentClick: (time: string) => void;
  onEditAppointmentClick: (appointment: Appointment) => void;
  onNewNoteClick: (time: string) => void;
  onEditNoteClick: (note: TemporaryNote) => void;
  onPasteNote: (note: TemporaryNote) => void;
  onPasteAppointment: (appointment: Appointment, patient: Patient) => void;
};

export const DayTimeTableContextMenu = ({
  x,
  y,
  close,
  bucket,
  container,
  addButtonFor,
  onNewAppointmentClick: _onNewAppointmentClick,
  onEditAppointmentClick: _onEditAppointmentClick,
  onNewNoteClick: _onNewNoteClick,
  onEditNoteClick: _onEditNoteClick,
  onPasteNote: _onPasteNote,
  onPasteAppointment: _onPasteAppointment,
}: DayTimeTableContextMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, close);

  useLayoutEffect(() => {
    const contextMenuEl = ref.current;

    if (!contextMenuEl) {
      return;
    }
    const containerEl = container.current;
    if (!containerEl) {
      return;
    }

    const { width: contextMenuWidth, height: contextMenuHeight } = contextMenuEl.getBoundingClientRect();
    const { right: containerRight } = containerEl.getBoundingClientRect();

    const screenBottom = window.scrollY + window.innerHeight;

    let newX = x;
    let newY = y;

    if (x + contextMenuWidth > containerRight) {
      newX = containerRight - contextMenuWidth - 10;
    }

    if (y + contextMenuHeight > screenBottom) {
      newY = screenBottom - contextMenuHeight - 20;
    }

    contextMenuEl.style.left = `${newX}px`;
    contextMenuEl.style.top = `${newY}px`;
  }, [x, y, container]);

  const isOpenedOnExistingAppointment = useMemo(() => !!bucket.event, [bucket.event]);

  const nearestBucketForNewAppointment = addButtonFor(bucket);

  const onNewAppointmentClick = (time: string) => {
    close();
    _onNewAppointmentClick(time);
  };

  const onEditAppointmentClick = (appointment: Appointment) => {
    close();
    _onEditAppointmentClick(appointment);
  };

  const onNewNoteClick = (time: string) => {
    close();
    _onNewNoteClick(time);
  };

  const onEditNoteClick = (note: TemporaryNote) => {
    close();
    _onEditNoteClick(note);
  };

  const onPasteNote = (note: TemporaryNote) => {
    close();
    _onPasteNote(note);
  };

  const onPasteAppointment = (appointment: Appointment, patient: Patient) => {
    close();
    _onPasteAppointment(appointment, patient);
  };

  return (
    <>
      <div
        ref={ref}
        className={classNames(
          'absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
          isOpenedOnExistingAppointment ? 'w-144' : 'w-64',
        )}
      >
        {nearestBucketForNewAppointment && (
          <ContextMenuLayout
            nearestBucketForNewAppointment={nearestBucketForNewAppointment}
            selectedBucket={bucket}
            onNewAppointmentClick={onNewAppointmentClick}
            onEditAppointmentClick={onEditAppointmentClick}
            onNewNoteClick={onNewNoteClick}
            onEditNoteClick={onEditNoteClick}
            onPasteNote={onPasteNote}
            onPasteAppointment={onPasteAppointment}
            close={close}
          />
        )}
      </div>
    </>
  );
};
