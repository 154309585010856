import { useState } from 'react';

export const phoneNumberRegex = new RegExp('^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$');

export default function usePhoneMask(initialState: string | (() => string)): [string, (value: string) => void, boolean] {
  const testIsValid = (value: string) => {
    if (!value || /^\s*$/.test(value)) {
      return true;
    }

    return phoneNumberRegex.test(value);
  };

  const [value, _setValue] = useState(initialState);
  const [isValid, setIsValid] = useState(testIsValid(value));

  const setValue = (value: string) => {
    // replace everything except parentheses, numbers, spaces, and dashes
    value = value.replace(/[^()-\d\s]/g, '');
    // replace multiple spaces with one space
    value = value.replace(/\s+/g, ' ');
    // replace multiple dashes with one dash
    value = value.replace(/-+/g, '-');
    // replace multiple parentheses with one parentheses
    value = value.replace(/\(+/g, '(');
    value = value.replace(/\)+/g, ')');

    _setValue(value);
    setIsValid(testIsValid(value));
  };

  return [value, setValue, isValid];
}
