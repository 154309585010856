import React from 'react';
import { InputGroup } from '../Forms/InputGroup';
import useField from '../Forms/FieldHook';
import { RadioBooleanInputGroup } from '../Forms/RadioBooleanInputGroup';
import { GeographicalStateOptions } from '../Forms/Data';
import { TextAreaGroup } from '../Forms/TextAreaGroup';
import { ListBoxGroupV2 } from '../Forms/ListBoxGroupV2';
import { RadioOptionsWithTextInputGroup } from '../Forms/RadioOptionsWithTextInputGroup';

export type SectionInjuryProps = {
  fieldArguments: any;
  watch: any;
  setValue: any;
};

export const SectionInjury = ({ fieldArguments, watch, setValue }: SectionInjuryProps) => {
  const injuryDate = useField(
    fieldArguments('injuryDate', 'Injury Date', {
      value: new RegExp('^(19|20)?[0-9]{2}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$'),
      message: 'Invalid Date',
    }),
  );
  const injuryState = useField(fieldArguments('injuryState', 'Injury State'));
  const hasFracture = useField(fieldArguments('hasFracture', 'Has Fracture?'));
  const note = useField(fieldArguments('injuryNote', 'Injury Notes'));
  const injuryCause = useField(fieldArguments('injuryCause', 'Injury Cause'));

  const watchInjuryState = watch('injuryState');
  const watchHasFracture = watch('hasFracture');
  const watchInjuryCause = watch('injuryCause');

  const hasFractureOptions = [
    { description: 'Yes', value: true },
    { description: 'No', value: false },
  ];

  const injuryCauseOptions = [
    { description: 'Automobile', value: 'automobile' },
    { description: 'Motorcycle', value: 'motorcycle' },
  ];

  return (
    <div className="gap-y-2 gap-x-2">
      <div className="grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-9">
        <div className="sm:col-span-4">
          <div className="grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-9">
            <div className="sm:col-span-3">
              <InputGroup {...injuryDate} type="date" placeholder="" />
            </div>
            <div className="sm:col-span-3">
              <ListBoxGroupV2
                options={GeographicalStateOptions}
                {...injuryState}
                value={watchInjuryState}
                absolutePositioning={true}
              />
            </div>
            <div className="sm:col-span-3">
              <RadioBooleanInputGroup {...hasFracture} options={hasFractureOptions} inlineList={true} value={watchHasFracture} />
            </div>
            <div className="sm:col-span-9">
              <RadioOptionsWithTextInputGroup
                {...injuryCause}
                options={injuryCauseOptions}
                inlineList={true}
                value={watchInjuryCause}
              />
            </div>
          </div>
        </div>
        <div className="sm:col-span-5">
          <div className="grid grid-cols-1 gap-y-2 gap-x-2 sm:grid-cols-1">
            <div className="sm:col-span-1">
              <TextAreaGroup {...note} placeholder="Something about the injury..." rows={4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
