import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { PatientFilteredQuery } from 'app/api/PatientApi';
import { InputGroup } from 'app/components/Forms/InputGroup';
import { PhoneInputGroup } from 'app/components/Forms/PhoneInputGroup';
import { phoneNumberRegex } from 'app/components/Forms/PhoneMaskHook';
import React, { useMemo, useState } from 'react';
import { FieldValues, useForm, ValidationRule } from 'react-hook-form';
import useField from '../../components/Forms/FieldHook';
import { EnterForm } from 'app/components/Forms/EnterForm';
import { ButtonRowV2 } from 'app/components/Forms/ButtonRowV2';
import classnames from 'classnames';
import { LawFirmInputGroup } from '../../components/Forms/LawFirmInputGroup/LawFirmInputGroup';
import { useFlexworxAnciallry } from '../../../utils/flexworx-ancillary';
import { useFlexworxConfig } from '../../../utils/flexworx-config';
import { OptionFactory } from '../../components/Forms/Data';
import { ListBoxGroupV2 } from '../../components/Forms/ListBoxGroupV2';

export type PatientFilteredQueryFormProps = {
  onSubmit: (data: PatientFilteredQuery) => void;
  onUnrecoverableError: (message: string | JSX.Element) => void;
  onClear: () => void;
  defaultValues?: FieldValues | undefined;
  compact?: boolean;
};

const dateRegex = new RegExp('^(19|20)?[0-9]{2}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$');

export const PatientFilteredQueryForm = ({
  onSubmit,
  onUnrecoverableError,
  onClear: _onClear,
  defaultValues,
  compact,
}: PatientFilteredQueryFormProps) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
    watch,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });

  const { lawFirmFromId } = useFlexworxAnciallry();

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required };
  };

  const onClear = () => {
    reset();
    _onClear();
  };

  const firstName = useField(fieldArguments('firstName', 'First Name'));
  const lastName = useField(fieldArguments('lastName', 'Last Name'));
  const phone = useField(
    fieldArguments('phone', 'Phone', {
      value: phoneNumberRegex,
      message: 'Invalid Phone Number',
    }),
  );
  const birthDate = useField(fieldArguments('birthDate', 'Birth Date', { value: dateRegex, message: 'Invalid Date' }));

  const lawFirmId = useField(fieldArguments('lawFirmId', 'Law Firm'));
  const watchLawFirmId = watch('lawFirmId');

  const clinicLocationId = useField(fieldArguments('clinicLocationId', 'Clinic Location'));
  const { clinicLocations } = useFlexworxConfig();

  const clinicLocationOptions = useMemo(() => {
    return [
      OptionFactory.createOption<string>('NA', 'None Selected', null),
      ...((clinicLocations &&
        clinicLocations
          .sort((a, b) => (a.name || '' > (b.name || '') ? 1 : -1))
          .map(clinicLocation =>
            OptionFactory.createOption<string>(clinicLocation.id, `${clinicLocation.name}`, clinicLocation.id),
          )) ||
        []),
    ];
  }, [clinicLocations]);
  const watchClinicLocationId = watch('clinicLocationId');

  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <EnterForm
      submitForm={handleSubmit(onSubmit)}
      setError={setError}
      onUnrecoverableError={onUnrecoverableError}
      className="space-y-8 divide-y divide-gray-200 bg-gray-100 p-4 rounded-md shadow-md"
      setDisabled={setIsDisabled}
    >
      <>
        <div className="space-y-8 divide-y divide-gray-200">
          <div className={classnames(compact ? '' : 'pt-4')}>
            <div className="grid grid-cols-1 gap-y-2 gap-x-1 md:grid-cols-6">
              <div className="md:col-span-1">
                <InputGroup {...lastName} type="text" placeholder="Doe" />
              </div>
              <div className="md:col-span-1">
                <InputGroup {...firstName} type="text" placeholder="John" />
              </div>
              <div className=" md:col-span-1">
                <PhoneInputGroup {...phone} placeholder="(901) 555-5555" />
              </div>
              <div className="md:col-span-1">
                <InputGroup {...birthDate} type="date" placeholder="01/01/2000" />
              </div>
              <div className="md:col-span-1">
                <LawFirmInputGroup
                  {...lawFirmId}
                  lawOfficeId={null}
                  attorneyId={null}
                  paralegalId={null}
                  label="Law Firm"
                  defaultModel={lawFirmFromId(watchLawFirmId)}
                />
              </div>
              <div className="md:col-span-1">
                <ListBoxGroupV2
                  {...clinicLocationId}
                  options={clinicLocationOptions}
                  value={watchClinicLocationId}
                  absolutePositioning={false}
                />
              </div>
              {compact && (
                <div className="sm:col-span-12 md:col-span-5 mt-6">
                  <div className="flex justify-end">
                    <ButtonRowV2
                      onCancel={onClear}
                      isDisabled={isDisabled}
                      setIsDisabled={setIsDisabled}
                      onUnrecoverableError={onUnrecoverableError}
                      onSubmitText="Search"
                      onCancelText="Clear"
                      onSubmitIcon={faMagnifyingGlass}
                      onCancelIcon={faXmark}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {!compact && (
          <div className="pt-5">
            <div className="flex">
              <ButtonRowV2
                onCancel={onClear}
                isDisabled={isDisabled}
                setIsDisabled={setIsDisabled}
                onUnrecoverableError={onUnrecoverableError}
                onSubmitText="Search"
                onCancelText="Clear"
                onSubmitIcon={faMagnifyingGlass}
                onCancelIcon={faXmark}
              />
            </div>
          </div>
        )}
      </>
    </EnterForm>
  );
};
