import React, { useState } from 'react';
import { FieldValues, useForm, ValidationRule } from 'react-hook-form';
import useField from 'app/components/Forms/FieldHook';
import { FormActionProps } from 'app/components/Forms/FormActionProps';
import { InputGroup } from 'app/components/Forms/InputGroup';
import { TemporaryNote, useCreateTemporaryNote, useDeleteTemporaryNote, useUpdateTemporaryNote } from 'app/api/TemporaryNoteApi';
import { EnterForm } from 'app/components/Forms/EnterForm';
import { ButtonRowV2 } from 'app/components/Forms/ButtonRowV2';
import { TextAreaGroup } from 'app/components/Forms/TextAreaGroup';
import { notesGlyphs } from 'app/components/Patient/AppointmentSingleForm/AppointmentForm';
import { CheckboxGroup } from 'app/components/Forms/CheckboxGroup';

export type TemporaryNoteFormParams = FormActionProps<TemporaryNote> & {
  defaultValues?: FieldValues;
};

export function TemporaryNoteForm({
  onSuccess,
  onCancel: _onCancel,
  onUnrecoverableError,
  defaultValues,
  onDeleted,
}: TemporaryNoteFormParams) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });

  const isNew = !defaultValues?.id;
  const createTemporaryNote = useCreateTemporaryNote();
  const updateTemporaryNote = useUpdateTemporaryNote();
  const deleteTemporaryNote = useDeleteTemporaryNote();

  const apiAction = isNew ? createTemporaryNote : updateTemporaryNote;

  const onSubmit = async data => {
    const temporaryNote = await apiAction.mutateAsync(data);
    onSuccess(temporaryNote);
    reset();
  };

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required };
  };

  const body = useField<string>(fieldArguments('body', 'Body', undefined, 'Body is required'));
  const blockAppointments = useField(fieldArguments('blockAppointments', 'Block Appointments'));
  const startTime = useField(fieldArguments('startTime', 'Start Time'));
  const endTime = useField(fieldArguments('endTime', 'End Time'));

  const header = isNew ? 'Create Schedule Note' : 'Update Schedule Note';
  const submitText = isNew ? 'Create' : 'Update';

  const onDelete = async () => {
    if (defaultValues?.id) {
      await deleteTemporaryNote.mutateAsync(defaultValues.id);
      if (onDeleted) {
        onDeleted(defaultValues as TemporaryNote);
      }
    }
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const blockAppointmentsToolitp = 'If checked, this note will block appointments from being scheduled during the time period';
  const endTimeTooltip = 'If greater than 15 minutes, the note will take up multiple time slots';

  return (
    <EnterForm
      submitForm={handleSubmit(onSubmit)}
      setError={setError}
      onUnrecoverableError={onUnrecoverableError}
      className="space-y-8 divide-y divide-gray-200"
      setDisabled={setIsDisabled}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="pt-2">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900 italic">{header}</h3>
            <p className="mt-1 text-sm text-gray-500">Please fill out as much information as possible.</p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="col-span-12">
              <TextAreaGroup {...body} placeholder="This is a very important reminder..." glyphs={notesGlyphs} rows={6} />
            </div>
            <div className="col-span-12">
              <CheckboxGroup
                {...blockAppointments}
                initialValue={defaultValues?.blockAppointments}
                tooltip={blockAppointmentsToolitp}
              />
            </div>
            <div className="col-span-2">
              <InputGroup {...startTime} type="time" placeholder="08:00" />
            </div>
            <div className="col-span-2">
              <InputGroup {...endTime} type="time" placeholder="08:00" tooltip={endTimeTooltip} />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <ButtonRowV2
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            onUnrecoverableError={onUnrecoverableError}
            onSubmitText={submitText}
            onDelete={isNew ? undefined : onDelete}
          />
        </div>
      </div>
    </EnterForm>
  );
}
