import React, { useState } from 'react';
import { FormActionProps } from '../Forms/FormActionProps';
import { InputGroup } from '../Forms/InputGroup';
import { FieldValues, useForm, ValidationRule } from 'react-hook-form';
import useField from '../Forms/FieldHook';
import { LawOffice, useCreateLawOffice, useDeleteLawOffice, useUpdateLawOffice } from '../../api/LawOfficeApi';
import { EnterForm } from '../Forms/EnterForm';
import { ButtonRowV2 } from '../Forms/ButtonRowV2';
import { GeographicalStateOptions } from '../Forms/Data';
import { ListBoxGroup } from '../Forms/ListBoxGroup';
import { PhoneInputGroup } from '../Forms/PhoneInputGroup';
import { LawFirmInputGroup } from '../Forms/LawFirmInputGroup/LawFirmInputGroup';
import { useFlexworxAnciallry } from '../../../utils/flexworx-ancillary';

export type LawOfficeFormParams = FormActionProps<LawOffice> & {
  defaultValues?: FieldValues | undefined;
};

export function LawOfficeForm({ onSuccess, onUnrecoverableError, defaultValues, onDeleted }: LawOfficeFormParams) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });

  const { lawFirmFromId } = useFlexworxAnciallry();

  const defaultLawFirm = lawFirmFromId(defaultValues?.lawFirmId);

  const geographicalStateInitialValue = defaultValues?.state;

  const isNew = !defaultValues?.id;
  const createLawOffice = useCreateLawOffice();
  const updateLawOffice = useUpdateLawOffice();
  const deleteLawOffice = useDeleteLawOffice();

  const apiAction = isNew ? createLawOffice : updateLawOffice;

  const onSubmit = async data => {
    const lawFirm = await apiAction.mutateAsync(data);
    onSuccess(lawFirm);
    reset();
  };

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required };
  };

  const phone = useField(fieldArguments('phone', 'Phone'));
  const fax = useField(fieldArguments('fax', 'Fax'));
  const addressLine1 = useField(fieldArguments('addressLine1', 'Address Line 1'));
  const addressLine2 = useField(fieldArguments('addressLine2', 'Address Line 2'));
  const city = useField(fieldArguments('city', 'City', undefined, 'City is required'));
  const state = useField(fieldArguments('state', 'State', undefined, 'State is required'));
  const zip = useField(fieldArguments('zip', 'Zip'));
  const lawFirmId = useField(fieldArguments('lawFirmId', 'Law Firm', undefined, 'Law Firm is required'));

  const header = isNew ? 'Create Law Office' : 'Update Law Office';
  const submitText = isNew ? 'Create' : 'Update';

  const onDelete = async () => {
    if (defaultValues?.id) {
      await deleteLawOffice.mutateAsync(defaultValues.id);
      if (onDeleted) {
        onDeleted(defaultValues as LawOffice);
      }
    }
  };

  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <EnterForm
      className="space-y-8 divide-y divide-gray-200"
      submitForm={handleSubmit(onSubmit)}
      setError={setError}
      onUnrecoverableError={onUnrecoverableError}
      setDisabled={setIsDisabled}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900 italic">{header}</h3>
            <p className="mt-1 text-sm text-gray-500">Please fill out as much information as possible</p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="sm:col-span-12">
              <LawFirmInputGroup
                {...lawFirmId}
                defaultModel={defaultLawFirm}
                lawOfficeId={null}
                attorneyId={null}
                paralegalId={null}
              />
            </div>
            <div className="sm:col-span-6">
              <PhoneInputGroup {...phone} placeholder="(555) 555-5555" />
            </div>
            <div className="sm:col-span-6">
              <PhoneInputGroup {...fax} placeholder="(555) 888-9999" />
            </div>
            <div className="sm:col-span-12">
              <InputGroup {...addressLine1} type="text" placeholder="1234 Weatherby Drive" />
            </div>
            <div className="sm:col-span-12">
              <InputGroup {...addressLine2} type="text" placeholder="Suite 33" />
            </div>
            <div className="sm:col-span-6">
              <InputGroup {...city} type="text" placeholder="Memphis" />
            </div>
            <div className="sm:col-span-3">
              <ListBoxGroup
                options={GeographicalStateOptions}
                showCheckIcon={true}
                {...state}
                initialValue={geographicalStateInitialValue}
                formKey="0"
              />
            </div>
            <div className="sm:col-span-3">
              <InputGroup {...zip} type="text" placeholder="38112" />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <ButtonRowV2
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            onUnrecoverableError={onUnrecoverableError}
            onSubmitText={submitText}
            onDelete={isNew ? undefined : onDelete}
          />
        </div>
      </div>
    </EnterForm>
  );
}
