import classNames from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export declare interface IPageContainerProps {
  children?: React.ReactNode;
  title: string;
  description: string;
  maxWidth?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | '7xl' | 'full' | 'screen';
  showTitle?: boolean;
}

export function PageContainer({ children, title, description, maxWidth: _maxWidth, showTitle: _showTitle }: IPageContainerProps) {
  const maxWidth = 'max-w-' + (_maxWidth ?? '7xl');
  const showTitle = _showTitle ?? true;

  const headerClass = classNames('mx-auto px-4 sm:px-6 lg:px-8', maxWidth);
  const mainClass = classNames('mx-auto px-4 sm:px-6 lg:px-8', maxWidth);

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
      </Helmet>
      {showTitle && (
        <header>
          <div className={headerClass}>
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">{title}</h1>
          </div>
        </header>
      )}
      <main>
        <div className={mainClass}>
          <div className="px-4 pb-8 sm:px-0">{children}</div>
        </div>
      </main>
    </>
  );
}
