import React, { useEffect, useRef } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

export type AppointmentPatientValidationHandlerProps = {
  patientPhone1: FormField;
  patientClinicLocationId: FormField;
  openDemographics: () => void;
  additionalAppointmentCount: number;
};

type FormField = {
  name: string;
  errors?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  clearErrors: () => void;
};

export const AppointmentPatientValidationHandler = ({
  patientPhone1,
  patientClinicLocationId,
  openDemographics,
  additionalAppointmentCount,
}: AppointmentPatientValidationHandlerProps) => {
  let { errors: patientPhone1Errors } = patientPhone1;
  let { errors: patientClinicLocationIdErrors } = patientClinicLocationId;

  const targetRef = useRef<HTMLDivElement>(null);

  const showErrors = () => patientPhone1Errors || patientClinicLocationIdErrors;

  useEffect(() => {
    if (showErrors()) {
      targetRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientPhone1Errors, patientClinicLocationIdErrors]);

  const onClick = () => {
    openDemographics();
  };

  const errorSummary =
    additionalAppointmentCount > 0
      ? 'Cannot create appointments due to the following errors:'
      : 'Cannot create appointment due to the following errors:';

  return (
    <>
      {showErrors() && (
        <div ref={targetRef} className="bg-red-100 border border-red-400 text-red-700 px-1 py-2 rounded relative" role="alert">
          <strong className="font-bold">{errorSummary}</strong>
          <ul>
            {patientPhone1Errors && (
              <>
                <li>{<>{patientPhone1Errors.message}</>}</li>
              </>
            )}
            {patientClinicLocationIdErrors && (
              <>
                <li>{<>{patientClinicLocationIdErrors.message}</>}</li>
              </>
            )}
          </ul>
          <div className="mt-2">
            <button
              type="button"
              onClick={onClick}
              className="rounded bg-blue-600 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              Patient Demographics
            </button>
          </div>
        </div>
      )}
    </>
  );
};
