import React from 'react';
import { AlertBox } from './AlertBox';
import { capitalize } from 'lodash';

export const EnvironmentAlert = ({ className }: { className?: string }) => {
  // Capitalize the first letter of the environment
  const environment = capitalize(process.env.REACT_APP_ENVIRONMENT);

  return (
    <>
      {process.env.REACT_APP_ENVIRONMENT !== 'production' && (
        <AlertBox
          primaryText={`${environment} Environment`}
          secondaryText="More information..."
          secondaryLinkPath="environment-explanation"
          className="ml-4"
        />
      )}
    </>
  );
};
