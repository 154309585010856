import { MultifieldOutput } from '../Forms/FieldHook';
import { FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import React, { useEffect } from 'react';
import { InputGroup } from '../Forms/InputGroup';
import { RadioBooleanInputGroup } from '../Forms/RadioBooleanInputGroup';
import { TreatmentCenterInputGroup } from '../Forms/TreatmentCenterInputGroup/TreatmentCenterInputGroup';
import { TextAreaGroup } from '../Forms/TextAreaGroup';
import { TreatmentCenter } from '../../api/TreatmentCenterApi';

export type SectionTreatmentProps = {
  treatments: MultifieldOutput;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  index: number;
  setValue: UseFormSetValue<FieldValues>;
};

export const SectionTreatment = ({ treatments, watch, index, register, setValue }: SectionTreatmentProps) => {
  const treatmentNote = register(`treatments[${index}].treatmentNote`);
  const treatmentDate = register(`treatments[${index}].treatmentDate`, {
    pattern: {
      value: new RegExp('^(19|20)?[0-9]{2}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$'),
      message: 'Treatment Date',
    },
  });
  const treatmentCenterId = register(`treatments[${index}].treatmentCenterId`);
  const isEmergencyRoom = register(`treatments[${index}].isEmergencyRoom`);

  const watchTreatmentCenterId = watch(`treatments[${index}].treatmentCenterId`);
  const watchIsEmergencyRoom = watch(`treatments[${index}].isEmergencyRoom`);

  useEffect(() => {
    if (watchIsEmergencyRoom === undefined || watchIsEmergencyRoom === null) {
      setValue(`treatments[${index}].isEmergencyRoom`, false);
    }
  }, [index, setValue, watchIsEmergencyRoom]);

  const isEmergencyRoomOptions = [
    { description: 'Yes', value: true },
    { description: 'No', value: false },
  ];

  const setIsEmergencyRoom = (value: boolean) => setValue(`treatments[${index}].isEmergencyRoom`, value);

  const treatmentCenterModel = {
    id: watchTreatmentCenterId,
  } as any as TreatmentCenter;

  return (
    <>
      <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-9 w-full">
        <div className="sm:col-span-4">
          <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-9 w-full">
            <div className="sm:col-span-4">
              <InputGroup
                {...treatmentDate}
                label="Treatment Date"
                clearErrors={treatments.clearErrors(index, 'treatmentDate')}
                setValue={(value: string) => setValue(`treatments[${index}].treatmentDate`, value)}
                type="date"
                placeholder=""
                tooltip="Date of treatment"
              />
            </div>
            <div className="sm:col-span-4">
              <RadioBooleanInputGroup
                {...isEmergencyRoom}
                clearErrors={treatments.clearErrors(index, 'isEmergencyRoom')}
                label="Emergency Room?"
                options={isEmergencyRoomOptions}
                inlineList={true}
                value={watchIsEmergencyRoom}
                tooltip="Did patient receive treatment at an emergency room at this location?"
                setValue={(value: any) => setIsEmergencyRoom(value)}
              />
            </div>
            <div className="sm:col-span-8">
              <TreatmentCenterInputGroup
                {...treatmentCenterId}
                label="Treatment Center"
                clearErrors={treatments.clearErrors(index, 'treatmentCenterId')}
                setValue={(value: string) => setValue(`treatments[${index}].treatmentCenterId`, value)}
                defaultModel={treatmentCenterModel}
              />
            </div>
          </div>
        </div>
        <div className="sm:col-span-5">
          <TextAreaGroup
            label="Treatment Notes"
            clearErrors={treatments.clearErrors(index, 'treatmentNote')}
            setValue={(value: string) => setValue(`treatments[${index}].treatmentNote`, value)}
            {...treatmentNote}
            placeholder="Something about the treatment..."
            rows={4}
          />
        </div>
      </div>
    </>
  );
};
