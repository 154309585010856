import React from 'react';
import { AppointmentModalNew } from './layout/AppointmentModalNew';
import { Appointment } from '../../api/AppointmentApi';
import { Patient } from '../../api/PatientApi';

export type PasteableAppointmentProps = {
  key: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  time: string;
  date: string;
  scheduleDefinitionId: string;
  appointment: Appointment;
  patient: Patient;
};

const noop = () => {};

export const PasteableAppointment = ({
  open,
  setOpen,
  appointment,
  patient,
  time,
  date,
  scheduleDefinitionId,
}: PasteableAppointmentProps) => {
  const defaultValues = {
    ...appointment,
  };

  return (
    <AppointmentModalNew
      isOpen={open}
      closeModal={() => setOpen(false)}
      time={time}
      date={date}
      scheduleDefinitionId={scheduleDefinitionId}
      hasAppointmentsProvider={false}
      patient={patient}
      defaultValues={defaultValues}
      onAppointmentSubmitSuccess={noop}
    />
  );
};
