import React, { forwardRef, useEffect } from 'react';
import { GenericInputGroupProps, InputGroupProps } from '../InputGroup';
import { Attorney } from '../../../api/AttorneyApi';
import { AttorneyInputTextField } from './AttorneyInputTextField';
import { useFlexworxAnciallry } from '../../../../utils/flexworx-ancillary';
import { Modal } from '../../Modal';
import { AttorneyList } from '../../../pages/AncillaryDataPage/AttorneyList';

export type AttorneyInputGroupProps = GenericInputGroupProps<HTMLInputElement, string> & {
  defaultModel?: Attorney;
  lawFirmId: string | null;
  lawOfficeId: string | null;
  paralegalId: string | null;
};

export const AttorneyInputGroup = forwardRef<HTMLInputElement, AttorneyInputGroupProps>(
  (
    {
      label,
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      tooltip,
      defaultModel,
      lawOfficeId,
      lawFirmId,
      paralegalId,
    }: AttorneyInputGroupProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const args: InputGroupProps = {
      label,
      type: 'text',
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      tooltip,
      placeholder: '',
    };

    const [model, setModel] = React.useState<Attorney | null>(defaultModel ?? null);
    const [showSelectionModal, setShowSelectionModal] = React.useState<boolean>(false);

    const onSelection = (attorney: Attorney) => {
      clearErrors();
      setModel(attorney);
      setValue(attorney.id);
      setShowSelectionModal(false);
    };

    const clear = () => {
      setValue('');
      setModel(null);
    };

    const { lawFirmFromId, attorneys, lawOfficeFromId, paralegalsFromIds, lawOffices } = useFlexworxAnciallry();

    // watch the lawFirmId
    useEffect(() => {
      if (!lawFirmId) {
        return;
      }
      // we will only clear the value if applicable, not set it.
      const lawFirm = lawFirmFromId(lawFirmId);
      if (!lawFirm) {
        return;
      }
      if (!lawOffices) {
        return;
      }
      const allowedLawOffices = lawOffices.filter(x => lawFirm.lawOfficeIds.includes(x.id));
      if (allowedLawOffices.length === 0) {
        clear();
      }
      if (!attorneys) {
        return;
      }
      const allowedLawOfficeIds = allowedLawOffices.map(x => x.id);
      const allowedAttorneys = attorneys.filter(x => allowedLawOfficeIds.includes(x.lawOfficeId));
      if (allowedAttorneys.length === 0) {
        clear();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lawFirmId]);

    // watch the lawOfficeId
    React.useEffect(() => {
      if (!lawOfficeId) {
        return;
      }
      const lawOffice = lawOfficeFromId(lawOfficeId);
      if (!lawOffice) {
        return;
      }
      if (!attorneys) {
        return;
      }
      const allowedAttorneys = attorneys.filter(x => x.lawOfficeId === lawOfficeId);
      if (allowedAttorneys.length === 0) {
        clear();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lawOfficeId]);

    // watch the paralegalId
    useEffect(() => {
      if (!paralegalId) {
        return;
      }
      const paralegal = paralegalsFromIds([paralegalId])[0];
      if (!paralegal) {
        return;
      }
      if (!attorneys) {
        return;
      }
      const allowedAttorneys = attorneys.filter(x => x.lawOfficeId === paralegal.lawOfficeId);
      if (allowedAttorneys.length === 0) {
        clear();
      }
      if (allowedAttorneys.length === 1) {
        onSelection(allowedAttorneys[0]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paralegalId]);

    return (
      <>
        <Modal
          width="sm:max-w-7xl"
          open={showSelectionModal}
          closeModal={() => setShowSelectionModal(false)}
          closeModalText="Cancel"
        >
          <AttorneyList
            initialLawOfficeId={lawOfficeId}
            initialParalegalId={paralegalId}
            onSelect={onSelection}
            allowNew={false}
          />
        </Modal>

        <div className="flex flex-col">
          <div className="w-full">
            <AttorneyInputTextField
              {...args}
              ref={ref}
              model={model}
              onClear={clear}
              onClick={() => setShowSelectionModal(true)}
            />
          </div>
        </div>
      </>
    );
  },
);
