import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { ChangeEventHandler, forwardRef, useId, useMemo } from 'react';
import { InfoTip } from '../Tooltip';
import { GenericInputGroupProps } from './InputGroup';

// where T can be string | number | readonly string[] | undefined
export type RadioInputGroupProps<T> = GenericInputGroupProps<
  HTMLInputElement,
  (T & string) | number | readonly string[] | boolean | undefined
> & {
  tooltip?: string;
  inlineList?: boolean;
  options: { description: string; value: T }[];
  value: T;
};

export const RadioBooleanInputGroup = forwardRef<HTMLInputElement, RadioInputGroupProps<boolean | null>>(
  (
    {
      label,
      tooltip,
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      value,
      options,
      inlineList,
    }: RadioInputGroupProps<boolean | null>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const baseClass = 'focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300';
    const invalidClass = classNames(baseClass, 'focus:ring-red-500 text-red-900 placeholder-red-300');

    const id = useId();

    const iterableErrorMessages = useMemo(() => {
      if (errors?.message) {
        if (Array.isArray(errors.message)) {
          return errors.message;
        }
        return [errors.message];
      }
      return [];
    }, [errors]);

    const onChange: ChangeEventHandler<HTMLInputElement> = event => {
      switch (event.target.value) {
        case 'true':
          setValue(true);
          break;
        case 'false':
          setValue(false);
          break;
        default:
          //should allow tri-state
          setValue(null as any);
          break;
      }
      clearErrors();
    };

    if (value === undefined) {
      value = null;
    }

    const isChecked = (option: { description: string; value: boolean | null }) => {
      if ((value as any) === '') {
        value = null;
      }
      return value?.toString() === option.value?.toString();
    };

    return (
      <>
        {label && (
          <label htmlFor={id} className="block text-sm font-medium text-gray-700">
            {label}
            {tooltip && <InfoTip content={tooltip} />}
          </label>
        )}
        <div className={classNames('relative mt-1 rounded-md', inlineList ? 'flex space-x-2' : '')} id={id}>
          {options.map((option, index) => (
            <div key={index} className="flex items-center">
              <label
                htmlFor={option.value?.toString() + id}
                className={classNames(
                  'ml-2 block text-sm font-medium leading-6  cursor-pointer',
                  errors ? 'text-red-900' : 'text-gray-900',
                  value?.toString() === option.value?.toString() ? 'text-indigo-900 font-extrabold' : 'text-gray-900',
                )}
              >
                <input
                  type="radio"
                  name={name}
                  className={errors ? invalidClass : baseClass}
                  ref={ref}
                  checked={isChecked(option)}
                  onChange={onChange}
                  value={option.value?.toString()}
                  id={option.value?.toString() + id}
                  {...(errors && { 'aria-invalid': 'true' })}
                  {...(errors && { 'aria-describedby': `${option.value?.toString() + id}-error` })}
                />
                <span className="ml-1">{option.description}</span>
              </label>
            </div>
          ))}
          {errors && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <FontAwesomeIcon className="h-5 w-5 text-red-500" icon={faTriangleExclamation} aria-hidden="true" />
            </div>
          )}
        </div>
        {iterableErrorMessages.map((message, index) => (
          <p key={index} className="mt-2 text-sm text-red-600" id={`${id}-error`}>
            {message}
          </p>
        ))}
      </>
    );
  },
);
