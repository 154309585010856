import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Auditable } from './Auditable';

export type RepresentationTimeRange = {
  start: string | null;
  end: string | null;
  notes: string;
  lawFirmId: string | null;
  lawOfficeId: string | null;
  attorneyId: string | null;
  paralegalId: string | null;
};

export type Representation = NewPrepresentationRequest & Auditable;

export type NewPrepresentationRequest = {
  patientId: string;
  ranges: RepresentationTimeRange[];
};

export const useGetRepresentation = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['representation', patientId], () =>
    client.get<Representation>(`/patient/${patientId}/representation/`).then(response => response.data),
  );
};

export const useCreateRepresentation = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Representation, AxiosError, NewPrepresentationRequest>({
    mutationFn: async function (request: NewPrepresentationRequest) {
      const response = await client.post(`/patient/${patientId}/representation/`, request);
      return response.data;
    },
    onSuccess: representation => queryClient.setQueryData(['representation', representation.patientId], representation),
  });
};

export const useUpdateRepresentation = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Representation, AxiosError, Representation>({
    mutationFn: request => client.put(`/patient/${patientId}/representation`, request).then(response => response.data),
    onSuccess: representation => queryClient.setQueryData(['representation', representation.patientId], representation),
  });
};
