import React from 'react';
import { faCheckCircle, faExclamationCircle, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { Notification } from './Notification';

export class NotificationFactory {
  static build(
    message: JSX.Element,
    type: 'success' | 'error' | 'loading' | 'blank' | 'custom',
    key: string,
    startPause: () => void,
    endPause: () => void,
  ) {
    switch (type) {
      case 'success':
        return (
          <Notification
            key={key}
            startPause={startPause}
            endPause={endPause}
            message={message}
            icon={faCheckCircle}
            accentColor={'text-green-400'}
          />
        );
      case 'error':
        return (
          <Notification
            key={key}
            startPause={startPause}
            endPause={endPause}
            message={message}
            icon={faXmarkCircle}
            accentColor={'text-red-400'}
          />
        );
      default:
        return (
          <Notification
            key={key}
            startPause={startPause}
            endPause={endPause}
            message={message}
            icon={faExclamationCircle}
            accentColor={'text-blue-400'}
          />
        );
    }
  }
}
