import { TemporaryNote, useGetTemporaryNote } from 'app/api/TemporaryNoteApi';
import { Modal } from '../../Modal';
import React, { useMemo } from 'react';
import { LoadingAnimated } from 'app/components/LoadingAnimated';
import { TemporaryNoteArea, TemporaryNoteAreaProps } from './TemporaryNoteArea';

export type TemporaryNoteModalEditProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  temporaryNote: TemporaryNote | undefined | null;
};

export const TemporaryNoteModalEdit = ({ open, setOpen, temporaryNote }: TemporaryNoteModalEditProps) => {
  return (
    <>
      {open && <>{temporaryNote && <TemporaryNoteModalRender open={open} setOpen={setOpen} temporaryNote={temporaryNote} />}</>}
    </>
  );
};

export type TemporaryNoteModalRenderProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  temporaryNote: TemporaryNote;
};

const TemporaryNoteModalRender = ({ open, setOpen, temporaryNote: _temporaryNote }: TemporaryNoteModalRenderProps) => {
  const {
    data: temporaryNote,
    isLoading: temporaryNoteIsLoading,
    isError: temporaryNoteIsError,
  } = useGetTemporaryNote(_temporaryNote.id);
  const hasAllData = useMemo(() => temporaryNote, [temporaryNote]);

  return (
    <Modal width="sm:max-w-7xl" closeModalText={'Cancel'} open={open} closeModal={() => setOpen(false)}>
      <>
        {temporaryNoteIsLoading && <LoadingAnimated />}
        {temporaryNoteIsError && <div>There was an error loading the temporaryNote</div>}
        {hasAllData && <TemporaryNoteModalBody open={open} temporaryNote={temporaryNote!} setOpen={setOpen} />}
      </>
    </Modal>
  );
};

type TemporaryNoteModalBodyProps = {
  temporaryNote: TemporaryNote;
  open: boolean;
  setOpen: (val: boolean) => void;
};

// this component is used when we have a patient object & and temporaryNote object.
const TemporaryNoteModalBody = ({ temporaryNote, setOpen, open }: TemporaryNoteModalBodyProps) => {
  const defaultValues: TemporaryNote = {
    id: temporaryNote.id,
    organizationId: temporaryNote.organizationId,
    header: temporaryNote.header,
    body: temporaryNote.body,
    noteDate: temporaryNote.noteDate,
    startTime: temporaryNote.startTime,
    endTime: temporaryNote.endTime,
    isAllDay: temporaryNote.isAllDay,
    blockAppointments: temporaryNote.blockAppointments,
    targetId: temporaryNote.targetId,
    temporaryNoteType: temporaryNote.temporaryNoteType,
    createdAt: temporaryNote.createdAt,
    createdBy: temporaryNote.createdBy,
    updatedBy: temporaryNote.updatedBy,
    updatedAt: temporaryNote.updatedAt,
    color: temporaryNote.color,
  };

  const props: TemporaryNoteAreaProps = {
    targetId: defaultValues.targetId,
    setModalOpen: setOpen,
    defaultValues: defaultValues,
  };

  return (
    <>
      <TemporaryNoteArea {...props} />
    </>
  );
};
