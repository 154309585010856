import { useQuery } from '@tanstack/react-query';
import useApiClient from 'utils/apiClientHook';

export type ApiMetadata = {
  commitHash: string;
};

export const useMetadata = () => {
  const { computeClient } = useApiClient();
  return useQuery(['/metadata'], () => computeClient.get<ApiMetadata>(`/metadata`).then(response => response.data));
};
