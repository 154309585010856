import React, { useState } from 'react';
import { Representation, useUpdateRepresentation } from '../../../api/RepresentationApi';
import { FormActionProps } from '../../../components/Forms/FormActionProps';
import { FieldValues, useForm } from 'react-hook-form';
import { useMultiField } from '../../../components/Forms/FieldHook';
import { EnterForm } from '../../../components/Forms/EnterForm';
import { ButtonRowV2 } from '../../../components/Forms/ButtonRowV2';
import { DynamicRepresentationGroupInput } from './DynamicRepresentationGroupInput';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { Patient } from '../../../api/PatientApi';

export type RepresentationFormParams = FormActionProps<Representation> & {
  defaultValues?: FieldValues;
  patient: Patient;
};

export const RepresentationForm = ({
  onSuccess,
  onUnrecoverableError,
  onCancel: _onCancel,
  defaultValues,
  patient,
}: RepresentationFormParams) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    reset,
    control,
    watch,
    setValue,
    getValues,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });

  const updateRepresentation = useUpdateRepresentation(patient.id);

  const onSubmit = async data => {
    const configuration = await updateRepresentation.mutateAsync(data);
    onSuccess(configuration);
  };

  const onCancel = () => {
    reset();
    _onCancel();
  };

  const ranges = useMultiField({
    control,
    name: 'ranges',
    errors,
    clearErrors,
  });

  const header = 'Update Representation';
  const submitText = 'Update';

  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <>
      <EnterForm
        submitForm={handleSubmit(onSubmit)}
        setError={setError}
        onUnrecoverableError={onUnrecoverableError}
        className="divide-y divide-gray-200"
        setDisabled={setIsDisabled}
      >
        <div className="space-y-8 divide-y divide-gray-200">
          <div className="">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900 italic">{header}</h3>
            </div>
            <div className="mx-1 grid grid-cols-1  gap-x-4 sm:grid-cols-12">
              <div className="sm:col-span-12">
                <DynamicRepresentationGroupInput
                  ranges={ranges}
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  getValues={getValues}
                  clearErrors={clearErrors}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="flex justify-end">
            <ButtonRowV2
              onCancel={onCancel}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
              onUnrecoverableError={onUnrecoverableError}
              onSubmitText={submitText}
              onCancelText={'Discard Changes'}
              onCancelIcon={faUndo}
            />
          </div>
        </div>
      </EnterForm>
    </>
  );
};
