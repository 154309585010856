import React, { useEffect, useMemo } from 'react';

import { ScheduleDisplayForPatientAppointment } from './ScheduleDisplayForPatientAppointment';
import classNames from 'classnames';
import { PatientAppointmentList } from '../../../pages/PatientPage/appointmentList';
import { Appointment } from '../../../api/AppointmentApi';
import { useScheduleLaunchPadProvider } from '../../../pages/SchedulePage/ScheduleDisplayLaunchPadProvider';
import { useActiveBucketProvider } from '../../../pages/SchedulePage/ActiveBucketProvider';
import { useAppointmentFormContext } from '../AppointmentFormHook';
import { CommunicationList } from '../../../pages/PatientPage/communicationList';
import { PatientDemographics } from '../../../pages/PatientPage/Demographics';
import { PatientRepresentation } from '../../../pages/PatientPage/Representation';
import { format } from 'date-fns';

export type LaunchpadModalContainerProps = {
  activeBucketKey: string | null;
  closeModal: () => void;
  hasAppointmentsProvider: boolean;
  onAppointmentSubmitSuccess: (appointment: Appointment) => void;
  //this is a hack to move the tabs up when displaying a New Appointment. For some reason, it doesn't work without this....
  shouldMoveTabsUp: boolean;
};

export function LaunchpadModalContainer(props: LaunchpadModalContainerProps) {
  const { patient, appointmentIdWatch, dateWatch, timeWatch, clearErrors } = useAppointmentFormContext();

  const appointmentFormTabId = 1;
  const appointmentListTabId = 2;
  const patientCommunication = 3;
  const patientDemographics = 4;
  const patientRepresentation = 5;

  const tabs = useMemo(() => {
    const tabs = [{ name: 'Appointment', id: appointmentFormTabId }];
    if (patient) {
      tabs.push({ name: 'Appointment History', id: appointmentListTabId });
      tabs.push({ name: 'Communication', id: patientCommunication });
      tabs.push({ name: 'Demographics', id: patientDemographics });
      tabs.push({ name: 'Representation', id: patientRepresentation });
    }

    return tabs;
  }, [patient]);

  const { scrollToActiveBucket } = useActiveBucketProvider();

  const [activeTab, _setActiveTab] = React.useState(tabs[0]);
  const setActiveTab = (tab: { name: string; id: number }) => {
    _setActiveTab(tab);
    clearErrors();
  };

  useEffect(() => {
    scrollToActiveBucket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const { setAppointment } = useScheduleLaunchPadProvider();

  const onGoToSchedule = (appointment: Appointment) => {
    props.closeModal();
    setAppointment(appointment);
  };

  const style = () => {
    //   if (props.shouldMoveTabsUp) {
    //     return buildTopStyle(-16);
    //   } else {
    //     return buildTopStyle(4);
    //   }

    return {};
  };

  const smsTemplate = () => {
    if (!dateWatch || !timeWatch) return '';

    const year = parseInt(dateWatch.split('-')[0]);
    const month = parseInt(dateWatch.split('-')[1]);
    const day = parseInt(dateWatch.split('-')[2]);
    const hour = parseInt(timeWatch.split(':')[0]);
    const minute = parseInt(timeWatch.split(':')[1]);

    const d = new Date(year, month - 1, day, hour, minute);
    const friendlyDate = format(d, 'MMMM do, yyyy');
    const friendlyTime = format(d, 'h:mm a');

    // TODO: This should be exactly like the automatic one.
    // We would like to remind you of your upcoming ${nickname} appointment.
    // You have an appointment at
    return `Hello ${patient?.displayShort},\n\nWe would like to remind you of your upcoming  appointment on ${friendlyDate} at ${friendlyTime}. Please let us know if you need to reschedule or cancel. Thank you!`;
  };

  const openDemographics = () => {
    setActiveTab(tabs.find(t => t.id === patientDemographics) || tabs[0]);
  };

  return (
    <>
      <div className="w-full sticky bg-white h-16 z-50 top-0" style={style()}>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            defaultValue={activeTab.name}
          >
            {tabs.map(tab => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block mb-4">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {patient && (
                <div className="bg-gray-200 whitespace-nowrap border-2 p-4 text-sm font-extrabold border-indigo-500 text-indigo-600 p-x-3">
                  <>{patient.displayLongReverse}</>
                </div>
              )}
              {tabs.map(tab => (
                <div
                  key={tab.name}
                  className={classNames(
                    tab.id === activeTab.id
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer',
                    'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium ',
                  )}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab.name}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className={classNames('bg-white p-1', '')}>
        <>
          {activeTab.id === appointmentFormTabId && (
            <ScheduleDisplayForPatientAppointment {...props} openDemographics={openDemographics} />
          )}
          {activeTab.id === appointmentListTabId && (
            <PatientAppointmentList
              patient={patient!}
              showPrintButton={false}
              showNewAppointmentButton={false}
              enableEditing={false}
              onGoToSchedule={onGoToSchedule}
              initialAppointmentId={appointmentIdWatch ?? undefined}
              tableContainerClass={''}
            />
          )}
          {activeTab.id === patientCommunication && <CommunicationList patient={patient!} smsTemplate={smsTemplate()} />}
          {activeTab.id === patientDemographics && <PatientDemographics patient={patient!} />}
          {activeTab.id === patientRepresentation && <PatientRepresentation patient={patient!} />}
        </>
      </div>
    </>
  );
}
