import React from 'react';
import { TemporaryNoteModalNew } from './layout/TemporaryNoteModalNew';
import { TemporaryNote } from '../../api/TemporaryNoteApi';

export type PasteableTemporaryNoteProps = {
  key: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  time: string;
  date: string;
  scheduleDefinitionId: string;
  temporaryNote: TemporaryNote;
};

export const PasteableTemporaryNote = ({
  open,
  setOpen,
  temporaryNote,
  time,
  date,
  scheduleDefinitionId,
}: PasteableTemporaryNoteProps) => {
  return (
    <TemporaryNoteModalNew
      open={open}
      setOpen={setOpen}
      time={time}
      date={date}
      targetId={scheduleDefinitionId}
      defaultValues={temporaryNote}
    />
  );
};
