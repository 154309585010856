import { Patient } from '../../api/PatientApi';
import { FieldValues, useForm, ValidationRule } from 'react-hook-form';
import { SmsMessage, useSendSMSMessage } from '../../api/SmsApi';
import useField from '../Forms/FieldHook';
import { FormActionProps } from './FormActionProps';
import { EnterForm } from './EnterForm';
import { ButtonRowV2 } from './ButtonRowV2';
import React from 'react';
import { faSms } from '@fortawesome/free-solid-svg-icons';
import { TextAreaGroup } from './TextAreaGroup';
import { InputGroup } from './InputGroup';

export type SmsMessageFormProps = FormActionProps<SmsMessage> & {
  patient: Patient;
  smsTemplate?: string;
};

export const SmsMessageForm = ({ onSuccess, onUnrecoverableError, patient, smsTemplate }: SmsMessageFormProps) => {
  const defaultValues: FieldValues = {
    ...patient,
    patientId: patient.id,
    body: smsTemplate || '',
  };

  const sendSMSMutation = useSendSMSMessage(patient.id);

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });

  const onSubmit = async data => {
    const textMessage = await sendSMSMutation.mutateAsync(data);
    onSuccess(textMessage);
    reset();
  };

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
    setValueAs?: ((value: any) => any) | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required, setValueAs };
  };

  const [isDisabled, setIsDisabled] = React.useState<boolean>(false);

  const body = useField<string>(fieldArguments('body', 'SMS Body'));
  const phone = useField<string>(fieldArguments('phone1', 'Phone'));
  const recipient = useField<string>(fieldArguments('displayLong', 'Recipient'));

  return (
    <EnterForm
      submitForm={handleSubmit(onSubmit)}
      setError={setError}
      onUnrecoverableError={onUnrecoverableError}
      className="space-y-8 divide-y divide-gray-200"
      setDisabled={setIsDisabled}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div className="pt-2">
          <div className="mt-6 mx-1 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="sm:col-span-6">
              <InputGroup type="text" placeholder="" readonly={true} {...phone} />
            </div>
            <div className="sm:col-span-6">
              <InputGroup type="text" placeholder="" readonly={true} {...recipient} />
            </div>
            <div className="sm:col-span-12">
              <TextAreaGroup {...body} placeholder="Hello there..." rows={6} />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <ButtonRowV2
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            onUnrecoverableError={onUnrecoverableError}
            onSubmitText="Send SMS Now"
            onSubmitIcon={faSms}
          />
        </div>
      </div>
    </EnterForm>
  );
};
