import React from 'react';
import { INavigationItem } from './navBarLinks';
import { NavLink } from 'react-router-dom';

export declare interface INavBarNavLinkProps {
  activeClass: string;
  inactiveClass: string;
  navItem: INavigationItem;
  children: React.ReactNode;
}

export function NavBarNavLink({ inactiveClass, activeClass, navItem, children }: INavBarNavLinkProps) {
  if (navItem.newTab) {
    return (
      <a href={navItem.href} className={inactiveClass} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }

  return navItem.isExternal ? (
    <a href={navItem.href} className={inactiveClass}>
      {children}
    </a>
  ) : (
    <NavLink to={navItem.href} className={({ isActive }) => (isActive ? activeClass : inactiveClass)}>
      {children}
    </NavLink>
  );
}
