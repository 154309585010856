import { createContext, useContext, useEffect, useState } from 'react';
import React from 'react';
import {
  InitialAppointmentScheduleQueueTask,
  useAddAppointmentTaskInCache,
  useDeleteAppointmentTaskInCache,
  useGetInitialAppointmentQueueTasks,
  useUpdateAppointmentTaskInCache,
} from 'app/api/ScheduleQueueApi';
import { useAuth } from './auth';
import { useWebsockets } from './websocket';

const sortByPriority = (a: InitialAppointmentScheduleQueueTask, b: InitialAppointmentScheduleQueueTask) => {
  if (new Date(a.priorityTimestamp) < new Date(b.priorityTimestamp)) {
    return -1;
  }

  if (new Date(a.priorityTimestamp) > new Date(b.priorityTimestamp)) {
    return 1;
  }

  return 0;
};

export interface IScheduleQueueContext {
  initialAppointmentTasks: InitialAppointmentScheduleQueueTask[] | null;
  isLoading: boolean;
  isError: boolean;
}

const ScheduleQueueContext = createContext<IScheduleQueueContext>({
  initialAppointmentTasks: null,
  isLoading: false,
  isError: false,
});

export function ScheduleQueueProvider({ children }) {
  const { user } = useAuth();

  const [fetchData, setFetchData] = useState(false);
  const { lastAppointmentTaskMessage } = useWebsockets();

  const {
    data: initialAppointmentQueueTasks,
    isLoading: initialAppointmentTasksIsLoading,
    isError: initialAppointmentTasksIsError,
  } = useGetInitialAppointmentQueueTasks(fetchData);

  const addAppointmentTask = useAddAppointmentTaskInCache();
  const updateAppointmentTask = useUpdateAppointmentTaskInCache();
  const deleteAppointmentTask = useDeleteAppointmentTaskInCache();

  useEffect(() => {
    if (user) {
      setFetchData(true);
    }
  }, [user]);

  useEffect(() => {
    if (!lastAppointmentTaskMessage) {
      return;
    }
    switch (lastAppointmentTaskMessage.action) {
      case 'create':
        addAppointmentTask(lastAppointmentTaskMessage.model);
        break;
      case 'update':
        updateAppointmentTask(lastAppointmentTaskMessage.model);
        break;
      case 'delete':
        deleteAppointmentTask(lastAppointmentTaskMessage.model);
        break;
      case 'unknown':
      default:
        console.error('Unknown appointment action type');
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastAppointmentTaskMessage]);

  const providerValue = {
    initialAppointmentTasks: initialAppointmentQueueTasks?.sort(sortByPriority) ?? [],
    isLoading: initialAppointmentTasksIsLoading,
    isError: initialAppointmentTasksIsError,
  };

  return <ScheduleQueueContext.Provider value={providerValue}>{children}</ScheduleQueueContext.Provider>;
}

export function useScheduleQueueContext() {
  return useContext(ScheduleQueueContext);
}
