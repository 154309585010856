import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { PagedQueryRequest, PagedQueryResponse } from './Pagination';
import { Auditable } from './Auditable';

export type Paralegal = NewParalegalRequest & Auditable;

type NewParalegalRequest = {
  firstName: string;
  lastName: string;
  lawOfficeId: string;
  phone?: string;
  email?: string;
  notes?: string;
  attorneyIds: string[];
};

export const useGetParalegals = (request?: PagedQueryRequest) => {
  const { computeClient: client } = useApiClient();
  const requestOverride = {} as PagedQueryRequest;
  if (request) {
    requestOverride.pageSize = request.pageSize || 50;
    requestOverride.lastKeyEvaluated = request.lastKeyEvaluated;
  }
  return useQuery(['paralegal', request], async () => {
    let paralegals: Paralegal[] = [];
    while (true) {
      const response = await client.post<PagedQueryResponse<Paralegal>>('/paralegal/list', request || null);
      paralegals = paralegals.concat(response.data.items);
      if (Object.keys(response.data.lastKeyEvaluated as {}).length > 0) {
        requestOverride.lastKeyEvaluated = response.data.lastKeyEvaluated;
      } else {
        break;
      }
    }
    return paralegals;
  });
};

export const useGetParalegal = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['paralegal', id], () => client.get<Paralegal>(`/paralegal/${id}`).then(response => response.data));
};

export const useCreateParalegal = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Paralegal, AxiosError, NewParalegalRequest>({
    mutationFn: async function (request: NewParalegalRequest) {
      const response = await client.post('/paralegal', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['paralegal']);
    },
  });
};

export const useUpdateParalegal = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Paralegal, AxiosError, Paralegal>({
    mutationFn: request => client.put('/paralegal', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['paralegal']);
    },
  });
};

export const useDeleteParalegal = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/paralegal/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['paralegal']);
    },
  });
};
