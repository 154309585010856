import React, { useRef } from 'react';
import { MouseEvent } from 'react';
import classNames from 'classnames';
import { BusinessHours, ScheduleDefinition } from 'app/api/ScheduleDefinitionApi';
import { useFlexworxConfig } from 'utils/flexworx-config';
import { Bucket, getContrastYIQ, isOpen } from './BucketOperations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '../Tooltip';

export type DayTimeRowProps = {
  bucket: Bucket;
  onRightClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, bucket: Bucket) => void;
  onSingleClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, bucket: Bucket) => void;
  onDoubleClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>, bucket: Bucket) => void;
  onNewNoteClick: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, bucket: Bucket) => void;
  onEditNoteClick: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, bucket: Bucket) => void;
  date: string;
  scheduleDefinition: ScheduleDefinition;
  reduceColumns: boolean;
  buildRowId: (key: string) => string;
  isActive: boolean;
  allowAddNotes?: boolean;
  disableDoubleClick?: boolean;
  index: number;
};

export const DayTimeRow = ({
  bucket,
  onRightClick,
  date,
  scheduleDefinition,
  reduceColumns,
  onSingleClick,
  onDoubleClick,
  onNewNoteClick,
  buildRowId,
  isActive,
  allowAddNotes,
  index,
}: DayTimeRowProps) => {
  const rowRef = useRef<HTMLTableRowElement>(null);

  // var doubleClickArgs: {
  //   onSingleClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>) => void;
  //   onDoubleClick?: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>) => void;
  //   ref: React.RefObject<HTMLTableRowElement>;
  //   latency: number;
  // } = {
  //   onSingleClick: (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>) => {
  //     onSingleClick(e, bucket);
  //   },
  //   ref: rowRef,
  //   latency: 0,
  // };

  // if (!disableDoubleClick) {
  //   doubleClickArgs.onDoubleClick = (e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>) => {
  //     onDoubleClick(e, bucket);
  //   };
  //   doubleClickArgs.latency = 250;
  // }

  // useDoubleClick(doubleClickArgs);

  const getBusinessHours = (day: number) => {
    switch (day) {
      case 0:
        return scheduleDefinition.sunday;
      case 1:
        return scheduleDefinition.monday;
      case 2:
        return scheduleDefinition.tuesday;
      case 3:
        return scheduleDefinition.wednesday;
      case 4:
        return scheduleDefinition.thursday;
      case 5:
        return scheduleDefinition.friday;
      case 6:
        return scheduleDefinition.saturday;
      default:
        const genericBusinessHours: BusinessHours = {
          timeStart: '00:00',
          timeEnd: '23:59',
          isOpen: false,
        };
        return genericBusinessHours;
    }
  };

  const outsideOfSchedule = (bucket: Bucket) => {
    const d = date.split('-').map(x => parseInt(x));
    const day = new Date(d[0], d[1] - 1, d[2]).getDay();
    const businessHours = getBusinessHours(day);
    return !bucket.isWithinBusinessHours(businessHours);
  };

  const {
    getColorRgbForAppointmentStatus,
    getColorHexForAppointmentStatus,
    getEmojiForAppointmentStatus,
    getEmojiForAppointmentType,
    hexToRgb,
  } = useFlexworxConfig();

  // might be overridden by getRowStyle
  const backgroundColor = (bucket: Bucket) => {
    if (isActive) {
      return 'bg-gray-600';
    }
    if (!isOpen(date, scheduleDefinition) || outsideOfSchedule(bucket)) {
      return 'bg-gray-300';
    }
    if (index % 2 === 0) {
      return 'bg-white';
    } else {
      return 'bg-gray-50';
    }
  };

  const getRowFocusTextColor = (bucket: Bucket) => {
    if (!isOpen(date, scheduleDefinition) || (outsideOfSchedule(bucket) && !bucket.event)) {
      return 'text-gray-900';
    }

    if (isActive) {
      return 'text-white';
    }

    if (bucket.isAppointment()) {
      return bucket.focusColor(getColorHexForAppointmentStatus);
    }

    if (bucket.isTemporaryNote()) {
      const hex = bucket.asTemporaryNote().color;
      if (!hex) {
        return 'text-gray-900';
      }
      const contrast = getContrastYIQ(hex);
      if (contrast === 'black') {
        return 'text-gray-900';
      }
      return 'text-white';
    }
  };

  const getRowStyle = (bucket: Bucket) => {
    if (isActive) {
      return;
    }

    if (bucket.isAppointment()) {
      const rbg = getColorRgbForAppointmentStatus(bucket.asAppointment().appointmentStatusId);
      return {
        backgroundColor: rbg ?? '',
      };
    }

    if (bucket.isTemporaryNote()) {
      const note = bucket.asTemporaryNote();
      const rbg = hexToRgb(note.color);
      return {
        backgroundColor: rbg ?? '',
      };
    }
  };

  const border = (bucket: Bucket) => {
    const common = 'border border-l-0 border-r-0';
    const unavailable = `${common} border-t-red-900 border-b-red-900`;
    if (!isOpen(date, scheduleDefinition)) {
      return unavailable;
    }
    if (outsideOfSchedule(bucket)) {
      return unavailable;
    }
    if (isActive) {
      return `${common} border-t-blue-900 border-b-blue-900`;
    }
    return `${common} border-gray-200`;
  };

  const clickableRow = (bucket: Bucket) => {
    if (isActive) {
      return 'cursor-pointer hover:bg-gray-800 hover:text-white';
    }
    if (bucket.event) {
      return 'cursor-pointer';
    }
    if (!isOpen(date, scheduleDefinition) || outsideOfSchedule(bucket)) {
      return '';
    }
    return 'cursor-pointer hover:bg-indigo-50';
  };

  return (
    <tr
      onContextMenu={e => onRightClick(e, bucket)}
      key={bucket.key}
      className={classNames(backgroundColor(bucket), clickableRow(bucket), border(bucket), 'group')}
      id={buildRowId(bucket.key)}
      ref={rowRef}
      onClick={e => onSingleClick(e, bucket)}
      onDoubleClick={e => onDoubleClick(e, bucket)}
    >
      {allowAddNotes && (
        <td>
          <div className="flex items-center justify-center h-4 w-4 mr-2 ml-2">
            {isActive && (
              <Tooltip content={'Click to add a note to the schedule'}>
                <button
                  onClick={e => onNewNoteClick(e, bucket)}
                  type="button"
                  className="text-indigo-400 group-hover:text-indigo-300"
                >
                  <FontAwesomeIcon icon={faComment} />
                </button>
              </Tooltip>
            )}
            {!isActive && (
              <div className="group-hover:visible invisible">
                <Tooltip content={'Click to add a note to the schedule'}>
                  <button onClick={e => onNewNoteClick(e, bucket)} type="button" className="text-indigo-700">
                    <FontAwesomeIcon icon={faComment} />
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
        </td>
      )}
      <td
        className={classNames(
          'whitespace-nowrap pl-4 py-1 pr-3 text-sm sm:pl-1 text-right border',
          isActive ? 'bg-gray-600' : 'border-gray-200',
          isActive ? 'bg-gray-600' : 'bg-gray-100',
          isActive ? 'text-white' : 'text-gray-900',
        )}
      >
        {bucket.formattedTime()}
      </td>

      {/* In the event that we do not have an event, here's how we format the rest of the td's */}
      {!bucket.event && (
        <>
          <td
            className={classNames('whitespace-nowrap px-2 py-1 text-sm ', getRowFocusTextColor(bucket))}
            style={getRowStyle(bucket)}
          ></td>
          {!reduceColumns && (
            <>
              <td
                className={classNames('whitespace-nowrap px-2 py-1 text-sm truncate ', getRowFocusTextColor(bucket))}
                style={getRowStyle(bucket)}
              ></td>
              <td
                className={classNames('whitespace-nowrap px-2 py-1 text-sm truncate ', getRowFocusTextColor(bucket))}
                style={getRowStyle(bucket)}
              ></td>
            </>
          )}
          <td
            className={classNames('whitespace-nowrap px-2 py-1 text-sm truncate', getRowFocusTextColor(bucket))}
            style={getRowStyle(bucket)}
          ></td>
        </>
      )}

      {/* In the event that we have an appointment, here's how we format the rest of the td's */}
      {bucket.isAppointment() && (
        <>
          <td
            className={classNames('whitespace-nowrap px-2 py-1 text-sm ', getRowFocusTextColor(bucket))}
            style={getRowStyle(bucket)}
          >
            <div>
              {bucket.isAppointment() && (
                <div>
                  {getEmojiForAppointmentType(bucket.asAppointment().appointmentTypeId)}{' '}
                  {getEmojiForAppointmentStatus(bucket.asAppointment().appointmentStatusId)} {bucket.description()}
                </div>
              )}
            </div>
          </td>
          {!reduceColumns && (
            <>
              <td
                className={classNames('whitespace-nowrap px-2 py-1 text-sm truncate ', getRowFocusTextColor(bucket))}
                style={getRowStyle(bucket)}
              >
                {bucket.phone1()}
              </td>
              <td
                className={classNames('whitespace-nowrap px-2 py-1 text-sm truncate ', getRowFocusTextColor(bucket))}
                style={getRowStyle(bucket)}
              >
                {bucket.phone2()}
              </td>
            </>
          )}
          <td
            className={classNames('px-2 py-1 text-sm ', getRowFocusTextColor(bucket), bucket.notes() ? 'line-clamp-1' : '')}
            style={getRowStyle(bucket)}
          >
            {bucket.notes()}
          </td>
        </>
      )}

      {/* And finally if we have a note, here's how we format the rest of the td's */}
      {bucket.isTemporaryNote() && (
        <>
          <td
            className={classNames('whitespace-nowrap px-2 py-1 text-sm truncate', getRowFocusTextColor(bucket))}
            style={getRowStyle(bucket)}
            colSpan={reduceColumns ? 2 : 4}
          >
            {bucket.body()}
          </td>
        </>
      )}
    </tr>
  );
};
