import React from 'react';
import { ContextMenuClickable, IContextMenuItem } from './ContextMenuLayout';
import classNames from 'classnames';

export const ClickableContextItem = ({ item: _item }: { item: IContextMenuItem }) => {
  const item = _item as ContextMenuClickable;
  return (
    <button
      disabled={item.disabled}
      type="button"
      className={classNames(
        'block px-4 py-1 w-full text-sm text-gray-700 text-left disabled:opacity-50 disabled:cursor-not-allowed',
        !item.disabled ? 'hover:bg-gray-100' : '',
      )}
      onClick={item.onClick}
    >
      {item.description}
    </button>
  );
};
