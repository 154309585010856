import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { PagedQueryRequest, PagedQueryResponse } from './Pagination';
import { Auditable } from './Auditable';

export type LawFirm = NewLawFirmRequest &
  Auditable & {
    lawOfficeIds: string[];
  };

export type NewLawFirmRequest = {
  name: string;
  notes?: string;
};

export const useGetLawFirms = (request?: PagedQueryRequest) => {
  const { computeClient: client } = useApiClient();
  const requestOverride = {} as PagedQueryRequest;
  if (request) {
    requestOverride.pageSize = request.pageSize || 50;
    requestOverride.lastKeyEvaluated = request.lastKeyEvaluated;
  }
  return useQuery(['law-firm', request], async () => {
    let list: LawFirm[] = [];
    while (true) {
      const response = await client.post<PagedQueryResponse<LawFirm>>('/law-firm/list', request || null);
      list = list.concat(response.data.items);
      if (Object.keys(response.data.lastKeyEvaluated as {}).length > 0) {
        requestOverride.lastKeyEvaluated = response.data.lastKeyEvaluated;
      } else {
        break;
      }
    }
    return list;
  });
};

export const useGetLawFirm = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['law-firm', id], () => client.get<LawFirm>(`/law-firm/${id}`).then(response => response.data));
};

export const useCreateLawFirm = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<LawFirm, AxiosError, NewLawFirmRequest>({
    mutationFn: async function (request: NewLawFirmRequest) {
      const response = await client.post('/law-firm', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['law-firm']);
    },
  });
};

export const useUpdateLawFirm = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<LawFirm, AxiosError, LawFirm>({
    mutationFn: request => client.put('/law-firm', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['law-firm']);
    },
  });
};

export const useDeleteLawFirm = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/law-firm/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['law-firm']);
    },
  });
};
