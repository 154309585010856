import { FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { MultifieldOutput } from '../../Forms/FieldHook';
import { InputGroup } from '../../Forms/InputGroup';
import { format } from 'date-fns';

export type AppointmentMultiSectionProps = {
  appointments: MultifieldOutput;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  additionalAppointmentIndex: number;
  setValue: UseFormSetValue<FieldValues>;
  fieldName: string;
  getValues: UseFormGetValues<FieldValues>;
  mainAppointmentTime: string | null;
  mainAppointmentDate: string | null;
  isHover: boolean;
};

const getDate = (date: string) => {
  const year = parseInt(date.split('-')[0]);
  const month = parseInt(date.split('-')[1]);
  const day = parseInt(date.split('-')[2]);
  return new Date(year, month - 1, day);
};

export const AppointmentMultiSection = ({
  appointments,
  watch,
  additionalAppointmentIndex,
  register,
  setValue,
  fieldName,
  getValues,
  mainAppointmentDate,
  mainAppointmentTime,
  isHover,
}: AppointmentMultiSectionProps) => {
  // const { additionalAppointmentIndexFocus, additionalAppointmentIndexLock, setAdditionalAppointmentIndexLock } =
  //   useAppointmentFormContext();

  const date = register(`${fieldName}[${additionalAppointmentIndex}].date`);
  const time = register(`${fieldName}[${additionalAppointmentIndex}].time`);

  const dateWatch = watch(`${fieldName}[${additionalAppointmentIndex}].date`);

  const getAppointmentNumber: (additionalAppointmentIndex: number) => { index: number; total: number } | null = (
    additionalAppointmentIndex: number,
  ) => {
    const thisDate = getValues(`${fieldName}[${additionalAppointmentIndex}].date`);
    const thisTime = getValues(`${fieldName}[${additionalAppointmentIndex}].time`);

    if (!thisDate || !thisTime) {
      return null;
    }

    const allAppointments: { time: string; date: string }[] = [];

    if (mainAppointmentDate && mainAppointmentTime) {
      allAppointments.push({ date: mainAppointmentDate, time: mainAppointmentTime });
    }

    for (let i = 0; i < appointments.fields.length; i++) {
      const date = getValues(`${fieldName}[${i}].date`);
      const time = getValues(`${fieldName}[${i}].time`);
      if (date && time) {
        allAppointments.push({ date, time });
      }
    }

    const sortedAppointments = allAppointments.sort((a, b) => {
      if (a.date === b.date) {
        return a.time < b.time ? -1 : 1;
      }
      return a.date < b.date ? -1 : 1;
    });

    return {
      index: sortedAppointments.findIndex(a => a.date === thisDate && a.time === thisTime) + 1,
      total: sortedAppointments.length,
    };
  };

  const [appointmentNumber, setAppointmentNumber] = useState(getAppointmentNumber(additionalAppointmentIndex));

  useEffect(() => {
    const number = getAppointmentNumber(additionalAppointmentIndex);
    setAppointmentNumber(number);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointments, watch]);

  // const showLock =
  //   (additionalAppointmentIndexFocus === additionalAppointmentIndex &&
  //     additionalAppointmentIndexLock !== additionalAppointmentIndex) ||
  //   (additionalAppointmentIndexLock === additionalAppointmentIndex && !isHover);
  // const showUnlock = additionalAppointmentIndexLock === additionalAppointmentIndex && isHover;

  // const onClick = () => {
  //   if (showLock) {
  //     setAdditionalAppointmentIndexLock(additionalAppointmentIndex);
  //   }
  //   if (showUnlock) {
  //     setAdditionalAppointmentIndexLock(null);
  //   }
  // };

  return (
    <>
      <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-12">
        <div className="sm:col-span-3">
          <InputGroup
            {...date}
            errors={appointments.errors(additionalAppointmentIndex, 'date')}
            label=""
            clearErrors={appointments.clearErrors(additionalAppointmentIndex, 'date')}
            setValue={(value: string) => setValue(`${fieldName}[${additionalAppointmentIndex}].date`, value)}
            type="date"
            placeholder=""
          />
        </div>
        <div className="sm:col-span-3">
          <InputGroup
            {...time}
            errors={appointments.errors(additionalAppointmentIndex, 'time')}
            label=""
            clearErrors={appointments.clearErrors(additionalAppointmentIndex, 'time')}
            setValue={(value: string) => setValue(`${fieldName}[${additionalAppointmentIndex}].time`, value)}
            type="time"
            placeholder=""
          />
        </div>

        <div className="sm:col-span-2 flex items-center">{dateWatch && <>{format(getDate(dateWatch), 'EEE')}</>}</div>
        <div className="sm:col-span-2 flex items-center">
          {appointmentNumber && (
            <>
              {appointmentNumber.index} of {appointmentNumber.total}
            </>
          )}
        </div>
        {/*<div className="sm:col-span-2 flex items-center">*/}
        {/*  <div className="h-10 w-10 cursor-pointer flex items-center justify-center" onClick={() => onClick()}>*/}
        {/*    {showUnlock && <FontAwesomeIcon icon={faUnlock} className="text-blue-950" />}*/}
        {/*    {showLock && <FontAwesomeIcon icon={faLock} className="text-blue-800" />}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </>
  );
};
