import { User, useAuth } from 'utils/auth';
import { useEffect } from 'react';
import React from 'react';
import { PageContainer } from './PageContainer';
import { Outlet } from 'react-router-dom';

export interface IRequireAuthProps {
  children?: React.ReactNode;
  capability?: (user: User) => boolean;
}

export function RequireAuth({ children, capability }: IRequireAuthProps) {
  const { user, loginUrl } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = React.useState(!!user);
  const [isAuthorized, setIsAuthorized] = React.useState(!!user && (!capability || capability(user)));

  useEffect(() => {
    if (!user) {
      setIsAuthenticated(false);
      setIsAuthorized(false);
    }
    if (!!user && (!capability || capability(user))) {
      setIsAuthenticated(true);
      setIsAuthorized(true);
    } else {
      setIsAuthenticated(true);
      setIsAuthorized(false);
    }
  }, [capability, user]);

  if (isAuthenticated && isAuthorized && children) {
    return <>{children}</>;
  } else if (isAuthenticated && isAuthorized) {
    return <Outlet />;
  }

  return (
    <div className="mt-8">
      {isAuthenticated && (
        <PageContainer title="Not Logged In" description="You are not logged in. Cannot view this private page.">
          <div className="mt-8">
            Please{' '}
            <a href={loginUrl} className="text-blue-500 hover:underline cursor-pointer text-xl font-bold">
              Sign In
            </a>
            .
          </div>
        </PageContainer>
      )}

      {!isAuthenticated && (
        <PageContainer title="Wrong Permissions" description="You do not have the correct permissions for this.">
          <div>You are not allowed to view this due to your user permissions.</div>
          {user?.email}
          <div>If you believe this is an error, please contact your administrator.</div>
        </PageContainer>
      )}
    </div>
  );
}
