import React from 'react';
import { ScheduleDefinition } from 'app/api/ScheduleDefinitionApi';
import { format } from 'date-fns';
import { parseDateString } from './DateNavigation';
import { useFlexworxConfig } from '../../../../utils/flexworx-config';
import { WebsocketStatus } from '../../NavBar/websocketStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '../../Tooltip';
import classNames from 'classnames';

export type ScheduleHeaderProps = {
  scheduleDefinition: ScheduleDefinition | null;
  date: string;
  setDate: (date: string) => void;
  monthCalendarToggleButton?: React.RefObject<HTMLButtonElement>;
  hideToday: boolean;
  children?: JSX.Element;
  styleGuide?: any;
  onRefresh: () => void;
  isRefreshing: boolean;
};

export const Header = ({
  scheduleDefinition,
  date,
  setDate: _setDate,
  monthCalendarToggleButton,
  hideToday,
  children,
  styleGuide,
  onRefresh,
  isRefreshing,
}: ScheduleHeaderProps) => {
  const getSelectedDate = () => parseDateString(date);

  const longDate = format(getSelectedDate(), 'MMMM d, yyyy');
  const shortDate = format(getSelectedDate(), 'MMM d, yyyy');
  const dayOfWeek = format(getSelectedDate(), 'EEEE');

  const { clinicLocationFromScheduleDefinitionId, scheduleTypeFromScheduleDefinitionId } = useFlexworxConfig();

  let scheduleDisplay = '';
  if (scheduleDefinition) {
    const clinicLocation = clinicLocationFromScheduleDefinitionId(scheduleDefinition?.id);
    const scheduleType = scheduleTypeFromScheduleDefinitionId(scheduleDefinition?.id);
    scheduleDisplay = `${scheduleType?.name} ${clinicLocation?.name}`;
  }

  return (
    <header className="flex flex-none items-center justify-between border-gray-200 py-4" style={styleGuide}>
      <div className="flex flex-row flex-nowrap w-full space-x-3">
        <h1 className="whitespace-nowrap">
          <span className="text-base font-semibold leading-6 text-gray-900">
            <WebsocketStatus />
            <span className="text-xl">
              <time dateTime={date} className="sm:hidden">
                {shortDate}
              </time>
              <time dateTime={date} className="hidden sm:inline">
                {dayOfWeek} {longDate}
              </time>
              <div className="hidden sm:inline"> {scheduleDisplay}</div>
            </span>
            {scheduleDefinition && (
              <Tooltip content={'Refresh this schedule'} placement="right">
                <span onClick={onRefresh}>
                  <FontAwesomeIcon
                    icon={faRefresh}
                    className={classNames(
                      'ml-2 text-blue-500 hover:cursor-pointer hover:text-blue-900',
                      isRefreshing ? 'animate-spin' : '',
                    )}
                  />
                </span>
              </Tooltip>
            )}
          </span>
        </h1>
        {children}
      </div>
    </header>
  );
};
