import React from 'react';

export type LoadingAnimatedProps = {
  size?: number;
};

const starSpinner = (ref: React.RefObject<SVGPolygonElement>) => {
  if (ref.current) {
    ref.current.style.transformOrigin = 'center';
    ref.current.style.transformBox = 'fill-box';
    // ref.current.style.animation = 'spin 5s linear infinite reverse';
    ref.current.style.animation = 'spin 10s linear infinite reverse';
  }
};

const groupSpinner = (ref: React.RefObject<SVGGElement>) => {
  if (ref.current) {
    ref.current.style.transformOrigin = 'center';
    ref.current.style.transformBox = 'fill-box';
    ref.current.style.animation = 'spin 10s linear infinite';
  }
};

export const LoadingAnimated = ({ size }: LoadingAnimatedProps) => {
  const blueRef = React.useRef<SVGPolygonElement>(null);
  const redRef = React.useRef<SVGPolygonElement>(null);
  const yellowRef = React.useRef<SVGPolygonElement>(null);
  const greenRef = React.useRef<SVGPolygonElement>(null);
  const purpleRef = React.useRef<SVGPolygonElement>(null);
  const groupRef = React.useRef<SVGSVGElement>(null);

  React.useEffect(() => {
    starSpinner(blueRef);
    starSpinner(redRef);
    starSpinner(yellowRef);
    starSpinner(greenRef);
    starSpinner(purpleRef);
    groupSpinner(groupRef);
  }, []);

  return (
    <div className="flex justify-center mt-4 h-96">
      <svg ref={groupRef} version="1.1" id="Layer_1" viewBox="0 0 2200 2200" height="300">
        <g transform="translate(100,100)">
          <polygon
            ref={yellowRef}
            fill="#FAA703"
            points="557.19,1462.02 645.09,1203.67 733,1462.02 1017.48,1462.02 787.33,1621.69 889.8,1880.04 645.09,1720.37 414.95,1880.04 502.86,1621.69 272.71,1462.02 "
          />
          <polygon
            ref={redRef}
            fill="#CB0A08"
            points="28,793.25 312.48,793.25 400.38,534.9 488.29,793.25 772.77,793.25 542.62,952.92 645.09,1211.27 400.38,1051.6 170.24,1211.27 258.15,952.92 "
          />
          <polygon
            ref={blueRef}
            fill="#321DD2"
            points="1364.03,372.23 1133.88,531.9 1236.35,790.25 991.64,630.58 761.49,790.25 849.4,531.9 619.26,372.23 903.73,372.23 991.64,113.88 1079.55,372.23 "
          />
          <polygon
            ref={greenRef}
            fill="#3C9E11"
            points="1457.38,952.92 1227.23,793.25 1511.71,793.25 1599.62,534.9 1687.52,793.25 1972,793.25 1741.85,952.92 1844.33,1211.27 1599.62,1051.6 1369.47,1211.27 "
          />
          <polygon
            ref={purpleRef}
            fill="#89059D"
            points="1135.4,1886.12 1223.31,1627.77 993.16,1468.1 1277.64,1468.1 1365.54,1209.75 1453.45,1468.1 1737.93,1468.1 1507.78,1627.77 1610.25,1886.12 1365.54,1726.45 "
            className=""
          />
        </g>
      </svg>
    </div>
  );
};
