import { FormActionProps } from './FormActionProps';
import React from 'react';
import { useToast } from '../../../utils/toast';
import { Modal } from '../Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export type SimpleItemNewButtonParams<T> = {
  headerSingular: string;
  children: (props: FormActionProps<T>) => JSX.Element;
  // this is only necessary for the toast notification. And that's disabled. So it's not necessary.
  getName?: (item: T) => string;
  onSuccess?: (item: T) => void;
};

export function SimpleItemNewButton<T>({
  headerSingular,
  getName,
  children,
  onSuccess: _onSuccess,
}: SimpleItemNewButtonParams<T>) {
  const { toastError } = useToast();

  const [showModal, setShowModal] = React.useState(false);

  const closeModal = () => setShowModal(false);

  const onSuccess = (item: T) => {
    closeModal();
    if (_onSuccess) {
      _onSuccess(item);
    }
  };

  const onUnrecoverableError = (message: string | JSX.Element) => {
    toastError('Error', message);
  };

  return (
    <>
      <Modal width="sm:max-w-3xl" open={showModal} closeModal={closeModal} closeModalText="Cancel">
        {children({
          onSuccess,
          onCancel: closeModal,
          onUnrecoverableError,
        })}
      </Modal>
      <div>
        <button
          className="mt-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => setShowModal(true)}
        >
          New {headerSingular}
          <FontAwesomeIcon className="ml-3 -mr-1 h-5 w-5" icon={faPlus} aria-hidden="true" />
        </button>
      </div>
    </>
  );
}
