import {
  ColumnFiltersState,
  ColumnSort,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  Table,
  useReactTable,
} from '@tanstack/react-table';
import { Attorney } from 'app/api/AttorneyApi';
import { AttorneyForm } from 'app/components/Auxillary/AttorneyForm';
import { FormActionProps } from 'app/components/Forms/FormActionProps';
import { PagedItemList } from 'app/components/Forms/PagedItemList';
import React, { useMemo, useState } from 'react';
import { useFlexworxAnciallry } from 'utils/flexworx-ancillary';
import { fuzzyFilter } from './TreatmentCenterList';
import { SimpleItemNewButton } from '../../components/Forms/SimpleItemNewButton';
import { SimpleItemEditModal } from '../../components/Forms/SimpleItemEditModal';

type HydratedAttorney = Attorney & {
  lawOfficeName: string;
  paralegalNames: string;
};

export const AttorneyList = ({
  initialLawOfficeId,
  initialParalegalId,
  onSelect,
  allowNew,
}: {
  initialLawOfficeId?: string | null;
  initialParalegalId?: string | null;
  onSelect?: (attorney: Attorney) => void;
  allowNew: boolean;
}) => {
  const { attorneys: _attorneys, lawOfficeFromId, paralegalsFromIds, isError, isLoading, lawFirmFromId } = useFlexworxAnciallry();

  const [sorting, setSorting] = useState<ColumnSort[]>(() => {
    return [{ id: 'lastName', desc: false }];
  });

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(() => {
    const initialState: ColumnFiltersState = [];
    if (initialLawOfficeId) {
      const initialLawOffice = lawOfficeFromId(initialLawOfficeId);
      const initialLawFirm = lawFirmFromId(initialLawOffice?.lawFirmId);
      initialState.push({
        id: 'lawOfficeName',
        value: initialLawFirm?.name ?? '',
      });
    }
    if (initialParalegalId) {
      const initialParalegal = paralegalsFromIds([initialParalegalId])[0];
      initialState.push({
        id: 'paralegalNames',
        value: [initialParalegal?.firstName, initialParalegal?.lastName].join(' '),
      });
    }
    return initialState;
  });

  const attorneys: null | HydratedAttorney[] = useMemo(() => {
    if (!_attorneys) return null;
    return _attorneys.map(a => {
      const lawOffice = lawOfficeFromId(a.lawOfficeId);
      const lawFirm = lawFirmFromId(lawOffice?.lawFirmId);
      const lawOfficeName = lawFirm?.name ?? '';

      const paralegals = paralegalsFromIds(a.paralegalIds);
      const paralegalNames = paralegals.map(x => [x.firstName, x.lastName].join(' ')).join('\n');

      return {
        ...a,
        lawOfficeName: lawOfficeName,
        paralegalNames: paralegalNames,
      };
    });
  }, [_attorneys, lawOfficeFromId, lawFirmFromId, paralegalsFromIds]);

  const columnHelper = createColumnHelper<HydratedAttorney>();

  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row.firstName, {
        id: 'firstName',
        cell: info => info.getValue(),
        header: () => <span>First Name</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.lastName, {
        id: 'lastName',
        cell: info => info.getValue(),
        header: () => <span>Last Name</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.lawOfficeName, {
        id: 'lawOfficeName',
        cell: info => info.getValue(),
        header: () => <span>Law Office</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.paralegalNames, {
        id: 'paralegalNames',
        cell: info => <div className="whitespace-pre">{info.getValue()}</div>,
        header: () => <span>Paralegals</span>,
        enableSorting: false,
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.phone, {
        id: 'phone',
        cell: info => info.getValue(),
        header: () => <span>Phone</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
    ],
    [columnHelper],
  );

  const table: Table<HydratedAttorney> = useReactTable({
    data: attorneys ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const [editModalIsVisible, setEditModalIsVisible] = useState(false);
  const [editModalItem, setEditModalItem] = useState<Attorney | null>(null);
  const onClick = (item: Attorney) => {
    if (onSelect) {
      onSelect(item);
      return;
    }
    setEditModalItem(item);
    setEditModalIsVisible(true);
  };

  const newForm = (props: FormActionProps<Attorney>) => <AttorneyForm {...props} />;

  const editForm = (item: Attorney, props: FormActionProps<Attorney>) => <AttorneyForm defaultValues={item} {...props} />;

  return (
    <>
      {allowNew && (
        <SimpleItemNewButton
          children={newForm}
          headerSingular="Attorney"
          getName={(item: Attorney) => `${item.firstName} ${item.lastName}`}
        />
      )}

      <SimpleItemEditModal
        children={props => editForm(editModalItem!, props)}
        headerSingular="Attorney"
        getName={(item: Attorney) => `${item.firstName} ${item.lastName}`}
        closeModal={() => setEditModalIsVisible(false)}
        isVisible={editModalIsVisible}
      />

      <PagedItemList table={table} isLoading={isLoading} isError={isError} headerPlural="Attorneys" onClick={onClick} />
    </>
  );
};
