import React, { useEffect } from 'react';
import { FieldError, FieldErrorsImpl, Merge, RefCallBack } from 'react-hook-form';
import { useFlexworxConfig } from '../../../../utils/flexworx-config';
import { ListBoxGroupV2 } from '../../Forms/ListBoxGroupV2';
import { OptionFactory } from '../../Forms/Data';

export type AppointmentStatusProps = {
  // This is expected to always be present because the form is only visible if there is one.
  appointmentTypeId: string | null;
  appointmentStatus: {
    ref: RefCallBack;
    name: string;
    label: string;
    errors: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
    clearErrors: () => void;
    setValue: (value: string) => void;
  };
  value: string | null;
};

export const AppointmentStatus = ({ appointmentTypeId, appointmentStatus, value }: AppointmentStatusProps) => {
  const { appointmentTypeFromId, appointmentStatuses } = useFlexworxConfig();

  const filteredAppointmentStatuses =
    appointmentStatuses?.filter(t => {
      const appointmentType = appointmentTypeFromId(appointmentTypeId ?? '');
      if (!appointmentType) {
        return false;
      }
      return appointmentType.appointmentStatusIds.includes(t.id);
    }) ?? [];

  const appointmentStatusOptions = [
    OptionFactory.createOption('none', 'None', null),
    ...(filteredAppointmentStatuses.map(t => OptionFactory.createOption(t.id, t.name, t.id)) || []),
  ];

  const [previousAppointmentTypeId, setPreviousAppointmentTypeId] = React.useState(appointmentTypeId);

  useEffect(() => {
    if (appointmentTypeId === previousAppointmentTypeId) {
      return;
    }
    setPreviousAppointmentTypeId(appointmentTypeId);

    const defaultAppointmentStatusId = appointmentTypeFromId(appointmentTypeId ?? '')?.defaultAppointmentStatusId;
    if (!defaultAppointmentStatusId) {
      return;
    }
    appointmentStatus.setValue(defaultAppointmentStatusId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTypeId]);

  // necessary so everything shows on the screen
  const absolutePositioning = false;
  return (
    <ListBoxGroupV2
      options={appointmentStatusOptions}
      {...appointmentStatus}
      value={value}
      absolutePositioning={absolutePositioning}
    />
  );
};
