import { Patient } from 'app/api/PatientApi';
import { Modal } from 'app/components/Modal';
import { PatientDirectory } from 'app/pages/PatientChartPage/PatientDirectory';
import React from 'react';

export type PatientDirectoryModalProps = {
  onDirectoryRowClick: (patient: Patient) => void;
  open: boolean;
  setOpen: (val: boolean) => void;
};

export const PatientDirectoryModal = ({ onDirectoryRowClick, open, setOpen }: PatientDirectoryModalProps) => {
  return (
    <Modal width="sm:max-w-7xl" open={open} closeModal={() => setOpen(false)} closeModalText="Cancel">
      <PatientDirectory onRowClick={onDirectoryRowClick} />
    </Modal>
  );
};
