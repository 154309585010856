import React, { useLayoutEffect, useRef } from 'react';
import { useOnClickOutside } from 'utils/useOnClickOutside';
import classNames from 'classnames';
import { Appointment } from 'app/api/AppointmentApi';
import { AppointmentListContextMenuLayout } from './AppointmentListContextMenuLayout';

export type AppointmentListContextMenuProps<T extends HTMLElement = HTMLElement> = {
  x: number;
  y: number;
  appointment: Appointment;
  close: () => void;
  container: React.RefObject<T>;
};

export const AppointmentListContextMenu = ({ x, y, close, appointment, container }: AppointmentListContextMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, close);

  useLayoutEffect(() => {
    const contextMenuEl = ref.current;

    if (!contextMenuEl) {
      return;
    }
    const containerEl = container.current;

    if (!containerEl) {
      return;
    }

    const { width: contextMenuWidth, height: contextMenuHeight } = contextMenuEl.getBoundingClientRect();
    const { right: containerRight } = containerEl.getBoundingClientRect();

    const screenBottom = window.scrollY + window.innerHeight;

    let newX = x;
    let newY = y;

    if (x + contextMenuWidth > containerRight) {
      newX = containerRight - contextMenuWidth - 10;
    }

    if (y + contextMenuHeight > screenBottom) {
      newY = screenBottom - contextMenuHeight - 20;
    }

    contextMenuEl.style.left = `${newX}px`;
    contextMenuEl.style.top = `${newY}px`;
  }, [x, y, container]);

  return (
    <>
      <div
        ref={ref}
        className={classNames(
          'absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
          'w-64',
        )}
      >
        <AppointmentListContextMenuLayout appointment={appointment} close={close} />
      </div>
    </>
  );
};
