import React from 'react';
import { Patient, useRefreshPatient } from '../../api/PatientApi';
import { Representation, useGetRepresentation } from '../../api/RepresentationApi';
import { LoadingAnimated } from '../../components/LoadingAnimated';
import { RepresentationForm } from './Forms/RepresentationForm';
import { useFlexworxAnciallry } from '../../../utils/flexworx-ancillary';
import { useToast } from '../../../utils/toast';
import { Modaltip } from '../../components/Modaltip';

export type RepresentationProps = {
  patient: Patient;
};

export const PatientRepresentation = ({ patient }: RepresentationProps) => {
  const { data, isLoading, isError, error, isSuccess } = useGetRepresentation(patient.id);
  const { isLoading: ancillaryIsLoading, isError: ancillaryIsError } = useFlexworxAnciallry();
  const refreshPatient = useRefreshPatient(patient.id);

  const anyLoading = () => isLoading || ancillaryIsLoading;
  const anyError = () => isError || ancillaryIsError;
  const allSuccess = () => isSuccess && !ancillaryIsError && !isError && !isLoading && !ancillaryIsLoading;

  // this should put the form into a new state everytime the key is changed
  const [monotomicKey, setMonotomicKey] = React.useState(0);

  const { toastInfo, toastError } = useToast();

  const onSuccess = async (response: Representation) => {
    setMonotomicKey(monotomicKey + 1);
    await refreshPatient();
  };

  const onCancel = () => {
    toastInfo('Form has been reset');
  };

  const onUnrecoverableError = (message: string | JSX.Element) => {
    toastError('An error has occurred', message);
  };

  return (
    <>
      <h1 className="text-2xl font-semibold text-indigo-900 ">Representation</h1>
      <div>
        Authorized Attorney: <span className="font-bold">{patient.attorneyDescription ?? 'None'}</span>
      </div>
      <div>
        Authorized Paralegal: <span className="font-bold">{patient.paralegalDescription ?? 'None'}</span>
      </div>
      <Modaltip width="sm:max-w-2xl" closeModalText="Dismiss">
        <>
          <h1 className="text-2xl font-semibold text-indigo-900 mb-4">Representation</h1>
          <p>This form has several purposes:</p>
          <ol className="list-decimal pl-8">
            <li>Provide history of representation</li>
            <li>Manage current representation</li>
            <li>Manage authorization to this patient in attorney portal</li>
          </ol>
          <p className="mt-2">
            <span className="font-bold">History of representation:</span> Used for billing disambiguation and for business
            analytics.
          </p>
          <p className="mt-2">
            <span className="font-bold">Current representation:</span> Determined by which record has{' '}
            <span className="italic">no End Date</span>. If there is no <span className="italic">End Date</span>, that means the
            representation did not end. Since it never ended, it is the current representation.
          </p>
          <p className="mt-2">
            <span className="font-bold">Representation Authorization:</span> Determined by which record has{' '}
            <span className="italic">no End Date</span>. Attorney and paralegal set here will have access to the Patient's data in
            the <span className="font-bold">Attorney Portal</span>.
          </p>

          <div className="mt-6">
            <p className="">Current access to {patient.displayLong}'s data:</p>
            <ol className="list-disc pl-8">
              <li>
                Attorney:{' '}
                {patient.attorneyDescription ? (
                  <>
                    <span className="font-bold">{patient.attorneyDescription}</span> has permission to view patient in Attorney
                    Portal
                  </>
                ) : (
                  'No attorney has permission to view patient in Attorney Portal'
                )}
              </li>
              <li>
                Paralegal:{' '}
                {patient.paralegalDescription ? (
                  <>
                    <span className="font-bold">{patient.paralegalDescription}</span> has permission to view patient in Attorney
                    Portal
                  </>
                ) : (
                  'No paralegal has permission to view patient in Attorney Portal'
                )}
              </li>
            </ol>
          </div>
        </>
      </Modaltip>
      <div className="mt-4">
        {anyLoading() && <LoadingAnimated />}
        {anyError() && <>Error: {error || 'unknown error'}</>}
        {allSuccess() && (
          <RepresentationForm
            key={monotomicKey}
            defaultValues={data}
            patient={patient}
            onSuccess={onSuccess}
            onCancel={onCancel}
            onUnrecoverableError={onUnrecoverableError}
          />
        )}
      </div>
    </>
  );
};
