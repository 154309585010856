import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { PagedQueryRequest, PagedQueryResponse } from './Pagination';
import { Auditable } from './Auditable';

export type TreatmentCenter = NewTreatmentCenterRequest & Auditable;

export type NewTreatmentCenterRequest = {
  name: string;
  notes?: string;
  fax?: string;
  phone?: string;
  city?: string;
  state?: string;
  addressLine1?: string;
  addressLine2?: string;
  zip?: string;
};

export const useGetTreatmentCenters = (request?: PagedQueryRequest) => {
  const { computeClient: client } = useApiClient();
  request = !!request ? request : ({} as PagedQueryRequest);
  return useQuery(['treatment-center', request], async () => {
    let list: TreatmentCenter[] = [];
    while (true) {
      const response = await client.post<PagedQueryResponse<TreatmentCenter>>('/treatment-center/list', request);
      list = list.concat(response.data.items);
      if (Object.keys(response.data.lastKeyEvaluated as {}).length > 0) {
        request!.lastKeyEvaluated = response.data.lastKeyEvaluated;
      } else {
        break;
      }
    }
    return list;
  });
};

export const useGetTreatmentCenter = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['treatment-center', id], () =>
    client.get<TreatmentCenter>(`/treatment-center/${id}`).then(response => response.data),
  );
};

export const useCreateTreatmentCenter = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<TreatmentCenter, AxiosError, NewTreatmentCenterRequest>({
    mutationFn: async function (request: NewTreatmentCenterRequest) {
      const response = await client.post('/treatment-center', request);
      return response.data;
    },
    onSuccess: () => {
      queryClient.refetchQueries(['treatment-center']);
    },
  });
};

export const useUpdateTreatmentCenter = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<TreatmentCenter, AxiosError, TreatmentCenter>({
    mutationFn: request => client.put('/treatment-center', request).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['treatment-center']);
    },
  });
};

export const useDeleteTreatmentCenter = () => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError, string>({
    mutationFn: id => client.delete(`/treatment-center/${id}`).then(response => response.data),
    onSuccess: () => {
      queryClient.refetchQueries(['treatment-center']);
    },
  });
};
