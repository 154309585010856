import React, { useEffect, useState } from 'react';
import { ListBoxGroupV2 } from '../../Forms/ListBoxGroupV2';
import { OptionFactory } from '../../Forms/Data';
import { useFlexworxConfig } from '../../../../utils/flexworx-config';
import { FieldError, FieldErrorsImpl, Merge, RefCallBack } from 'react-hook-form';

export type AppointmentTypeProps = {
  // This is expected to always be present because the form is only visible if there is one.
  scheduleDefinitionId: string | null;
  appointmentType: {
    ref: RefCallBack;
    name: string;
    label: string;
    errors: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
    clearErrors: () => void;
    setValue: (value: string) => void;
  };
  value: string | null;
};

export const AppointmentType = ({ scheduleDefinitionId, appointmentType, value }: AppointmentTypeProps) => {
  const { appointmentTypesFromScheduleTypeId, scheduleDefinitionFromId } = useFlexworxConfig();
  const [prevScheduleDefinitionId, setPrevScheduleDefinitionId] = useState(scheduleDefinitionId ?? null);

  const [appointmentOptions, setAppointmentOptions] = useState(() => {
    const scheduleDefinition = scheduleDefinitionFromId(scheduleDefinitionId ?? '');
    const appointmentTypes = appointmentTypesFromScheduleTypeId(scheduleDefinition?.scheduleTypeId ?? null);

    return [
      OptionFactory.createOption('none', 'None', null),
      ...(appointmentTypes?.map(t => OptionFactory.createOption(t.id, t.name, t.id)) || []),
    ];
  });

  useEffect(() => {
    if (scheduleDefinitionId === prevScheduleDefinitionId) {
      return;
    }
    setPrevScheduleDefinitionId(scheduleDefinitionId);

    const scheduleDefinition = scheduleDefinitionFromId(scheduleDefinitionId ?? '');
    const appointmentTypes = appointmentTypesFromScheduleTypeId(scheduleDefinition?.scheduleTypeId ?? null);

    setAppointmentOptions([
      OptionFactory.createOption('none', 'None', null),
      ...(appointmentTypes?.map(t => OptionFactory.createOption(t.id, t.name, t.id)) || []),
    ]);

    if (value && !appointmentTypes.map(x => x.id).includes(value)) {
      appointmentType.setValue('');
    }

    const defaultAppointmentTypeId = scheduleDefinition?.defaultAppointmentTypeId;
    if (!defaultAppointmentTypeId) {
      return;
    }
    appointmentType.setValue(defaultAppointmentTypeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleDefinitionId]);

  useEffect(() => {
    // if the options do not contain the value, clear the value
    if (!appointmentOptions.map(x => x.value).includes(value)) {
      appointmentType.setValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // necessary so everything shows on the screen
  const absolutePositioning = false;
  return (
    <ListBoxGroupV2 options={appointmentOptions} {...appointmentType} value={value} absolutePositioning={absolutePositioning} />
  );
};
