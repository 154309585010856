import React, { useEffect, useMemo } from 'react';
import { ListBoxGroupV2 } from '../../Forms/ListBoxGroupV2';
import { OptionFactory } from '../../Forms/Data';
import { useFlexworxConfig } from '../../../../utils/flexworx-config';

export const ScheduleDefinitionPickerScheduleType = ({
  scheduleTypeIdWatch,
  clinicLocationIdWatch,
  scheduleTypeId, // Form Field
  absolutePositioning,
}: {
  scheduleTypeIdWatch: string | null;
  clinicLocationIdWatch: string | null;
  scheduleTypeId: any;
  absolutePositioning?: boolean;
}) => {
  const { scheduleDefinitions, scheduleTypes } = useFlexworxConfig();

  const options = useMemo(() => {
    const relevantScheduleDefinitions = clinicLocationIdWatch
      ? scheduleDefinitions?.filter(x => x.clinicLocationId === clinicLocationIdWatch)
      : scheduleDefinitions;
    const scheduleTypeIds = relevantScheduleDefinitions?.map(x => x.scheduleTypeId) ?? [];
    return [
      OptionFactory.createOption<string>('NA', 'None Selected', null),
      ...((scheduleTypes &&
        scheduleTypes
          .filter(scheduleType => scheduleTypeIds.includes(scheduleType.id))
          .sort((a, b) => (a.name || '' > (b.name || '') ? 1 : -1))
          .map(scheduleType => OptionFactory.createOption<string>(scheduleType.id, `${scheduleType.name}`, scheduleType.id))) ||
        []),
    ];
  }, [clinicLocationIdWatch, scheduleDefinitions, scheduleTypes]);

  useEffect(() => {
    if (!clinicLocationIdWatch) {
      // if clinic location is not set, don't change the schedule type
      return;
    }

    if (scheduleTypeIdWatch) {
      // if there is already a value, don't change it
      return;
    }

    // if there is not a value, set it to the first option
    const firstOption = options.find(x => x.value !== null);
    if (firstOption?.value) {
      scheduleTypeId.setValue(firstOption.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clinicLocationIdWatch]);

  return (
    <>
      <ListBoxGroupV2
        {...scheduleTypeId}
        options={options}
        value={scheduleTypeIdWatch}
        absolutePositioning={absolutePositioning}
      />
    </>
  );
};
