import * as React from 'react';

import { Fragment, useEffect } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useNavBarLinks } from './navBarLinks';
import { useAuth } from 'utils/auth';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DisclosureButton } from './disclosureButton';
import { NavBarNavLink } from './navBarNavLink';
import { faCog, faXmark } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { WebsocketStatus } from './websocketStatus';
import { TaskQueueIcon } from '../Icons/TaskQueueIcon';
import { EnvironmentAlert } from '../../../utils/EnvironmentAlert';

export function NavBar() {
  const { user: _user } = useAuth();
  const navBarLinks = useNavBarLinks();
  const location = useLocation();
  const [navigation, setNavigation] = React.useState(navBarLinks.navItems);
  const [userNavigation, setUserNavigation] = React.useState(navBarLinks.userNavItems);
  const [configNavigation, setConfigNavigation] = React.useState(navBarLinks.configNavItems);
  const [queueNavigation, setQueueNavigation] = React.useState(navBarLinks.queueNavItems);
  const [patientNavigation, setPatientNavigation] = React.useState(navBarLinks.patientItems);
  const [user, setUser] = React.useState(_user);

  useEffect(() => {
    setNavigation(navBarLinks.navItems);
    setUserNavigation(navBarLinks.userNavItems);
    setConfigNavigation(navBarLinks.configNavItems);
    setQueueNavigation(navBarLinks.queueNavItems);
    setPatientNavigation(navBarLinks.patientItems);
    setUser(_user);
  }, [_user, navBarLinks]);

  const onXClick = (e: React.MouseEvent, item: any) => {
    e.preventDefault();
    navBarLinks.removePatientTab(item.key);
  };

  return (
    <Disclosure as="nav" className="border-b border-gray-200 bg-white print:hidden h-full">
      {({ open }) => (
        <>
          <div className="mx-auto h-full px-4 sm:px-6 lg:px-4">
            <div className="flex h-full justify-between">
              <div className="flex flex-shrink-0 items-center">
                <Link to="/">
                  <img className="block h-8 w-auto lg:hidden" src="/flexworx-empty.svg" alt="Flexworx" />
                  <img className="hidden h-8 w-auto lg:block" src="/flexworx-empty.svg" alt="Flexworx" />
                </Link>
              </div>
              <div>
                <EnvironmentAlert />
              </div>
              <div className="hidden grow sm:-my-px sm:ml-6 sm:flex sm:space-x-4 overflow-x-auto whitespace-nowrap">
                {navigation.map(item => {
                  const commonUtilities = 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium';
                  return (
                    <NavBarNavLink
                      key={item.name}
                      navItem={item}
                      activeClass={classnames(commonUtilities, 'border-indigo-500 text-indigo-600')}
                      inactiveClass={classnames(
                        commonUtilities,
                        'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                      )}
                    >
                      {item.icon && <span className="mr-2">{item.icon!}</span>}
                      {item.name}
                    </NavBarNavLink>
                  );
                })}
                {patientNavigation.map(item => {
                  const commonUtilities = 'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium';
                  return (
                    <NavBarNavLink
                      key={item.key}
                      navItem={item}
                      activeClass={classnames(commonUtilities, 'border-indigo-500 text-indigo-600')}
                      inactiveClass={classnames(
                        commonUtilities,
                        'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 ',
                      )}
                    >
                      {item.icon && <span className="mr-2">{item.icon!}</span>}
                      <div className="max-w-tab truncate inline-block">{item.name}</div>
                      <>
                        <button onClick={e => onXClick(e, item)} className="ml-1 text-gray-300 hover:text-purple-700">
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </>
                    </NavBarNavLink>
                  );
                })}
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                {/* Queue dropdown */}
                {!!queueNavigation.length && (
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="rounded-full bg-white pt-1 px-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <TaskQueueIcon />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-20 mt-2 w-64 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {queueNavigation.map(item => (
                          <Menu.Item key={item.name}>
                            {({ active }) => {
                              const className = classnames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700');

                              if (item.newTab) {
                                return (
                                  <a href={item.href} className={className} target="_blank" rel="noreferrer">
                                    {item.icon && <span className="mr-2">{item.icon!}</span>}
                                    {item.name}
                                  </a>
                                );
                              }

                              return item.isExternal ? (
                                <a href={item.href} className={className}>
                                  {item.icon && <span className="mr-2">{item.icon!}</span>}
                                  {item.name}
                                </a>
                              ) : (
                                <Link to={item.href} className={className}>
                                  {item.icon && <span className="mr-2">{item.icon!}</span>}
                                  {item.name}
                                </Link>
                              );
                            }}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}

                {/* Configuration dropdown */}
                {!!configNavigation.length && (
                  <Menu as="div" className="ml-3 relative">
                    <div data-testid="config-cog">
                      <Menu.Button className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <FontAwesomeIcon icon={faCog} />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {configNavigation.map(item => (
                          <Menu.Item key={item.name}>
                            {({ active }) => {
                              const className = classnames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700');

                              if (item.newTab) {
                                return (
                                  <a href={item.href} className={className} target="_blank" rel="noreferrer">
                                    {item.icon && <span className="mr-2">{item.icon!}</span>}
                                    {item.name}
                                  </a>
                                );
                              }

                              return item.isExternal ? (
                                <a href={item.href} className={className}>
                                  {item.icon && <span className="mr-2">{item.icon!}</span>}
                                  {item.name}
                                </a>
                              ) : (
                                <Link to={item.href} className={className}>
                                  {item.icon && <span className="mr-2">{item.icon!}</span>}
                                  {item.name}
                                </Link>
                              );
                            }}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}

                {/* Profile dropdown */}
                <Menu as="div" className="relative ml-3">
                  <div className="flex items-center">
                    <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <WebsocketStatus />
                      <span className="sr-only">Open user menu</span>
                      <span className="text-base font-medium text-gray-800">{user?.name || 'Signed out'}</span>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map(item => (
                        <Menu.Item key={item.name}>
                          {({ active }) => {
                            const className = classnames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700');

                            if (item.newTab) {
                              return (
                                <a href={item.href} className={className} target="_blank" rel="noreferrer">
                                  {item.icon && <span className="mr-2">{item.icon!}</span>}
                                  {item.name}
                                </a>
                              );
                            }

                            return item.isExternal ? (
                              <a href={item.href} className={className}>
                                {item.icon && <span className="mr-2">{item.icon!}</span>}
                                {item.name}
                              </a>
                            ) : (
                              <Link to={item.href} className={className}>
                                {item.icon && <span className="mr-2">{item.icon!}</span>}
                                {item.name}
                              </Link>
                            );
                          }}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            {/* Configuration Section on Mobile */}
            <div className="space-y-1 pt-2 pb-3">
              {navigation
                .concat(patientNavigation)
                .concat(queueNavigation)
                .concat(configNavigation)
                .map(item => (
                  <DisclosureButton
                    key={item.key}
                    navItem={item}
                    className={(() => {
                      const isActive = (location.pathname === '' && item.href === '/') || location.pathname.includes(item.href);
                      return classnames(
                        isActive
                          ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                          : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium whitespace-nowrap truncate',
                      );
                    })()}
                  >
                    {item.icon && <span className="mr-2">{item.icon!}</span>}
                    {item.name}
                  </DisclosureButton>
                ))}
            </div>
            {/* Profile Section on Mobile */}
            <div className="border-t border-gray-200 pt-4 pb-3">
              <div className="flex items-center px-4">
                <div>
                  <div className="text-base font-medium text-gray-800 flex items-center">
                    <WebsocketStatus />
                    <span>{user?.name ?? 'Signed Out'}</span>
                  </div>

                  {user?.email && <div className="text-sm font-medium text-gray-500">{user.email}</div>}
                </div>
              </div>
              <div className="mt-3 space-y-1">
                {userNavigation.map(item => (
                  <DisclosureButton
                    key={item.name}
                    navItem={item}
                    className={(() => {
                      const isActive = location.pathname.includes(item.href);

                      return classnames(
                        isActive
                          ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                          : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                      );
                    })()}
                  >
                    {item.icon && <span className="mr-2">{item.icon!}</span>}

                    {item.name}
                  </DisclosureButton>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
