import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';
import { Appointment } from './AppointmentApi';
import { Auditable } from './Auditable';

export type Patient = PatientPrecursor &
  Auditable & {
    displayShort: string;
    displayLong: string;
    displayShortReverse: string;
    displayLongReverse: string;
    phoneDisplay: string;
    attorneyDescription: string;
    paralegalDescription: string;
    globalId: string | null;
    lawFirmId: string;
    clinicLocationId: string;
  };

type PatientPrecursor = {
  firstName: string;
  lastName: string;
  descriptor?: string;
  email?: string;
  phone1?: string;
  phone2?: string;
  phone1CanText?: boolean;
  birthDate?: string;
  phone2Description?: string;
  isMinor?: boolean;
  isPregnant?: boolean;
  isParentInvolved?: boolean | null;
  sex?: string;
  zip?: string;
  suffix?: string;
  middleInitial?: string;
  clinicLocationId?: string;
};

export type NewPatientRequest = PatientPrecursor & {
  lawFirmId?: string;
  attorneyId?: string;
  paralegalId?: string;
  lawOfficeId?: string;
};

export type PatientSupplemental = {
  id: string;
};

export const useGetPatient = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['patient', id], () => client.get<Patient>(`/patient/${id}`).then(response => response.data));
};

export const useGetPatientSupplemental = (id: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['patient', 'supplemental', id], () =>
    client.get<PatientSupplemental>(`/patient/${id}/supplemental`).then(response => response.data),
  );
};

export const useRefreshPatient = (id: string) => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries(['patient', id]);
};

export const useGetPatientAppointments = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['patient-appointments', patientId], () =>
    client.get<Appointment[]>(`/patient/${patientId}/appointments`).then(response => response.data),
  );
};

export const useGetPatientAppointmentsPdf = (patientId: string, includeColors: boolean, includeNotes: boolean) => {
  const { computeClient: client } = useApiClient();

  const params = new URLSearchParams({
    includeColors: includeColors.toString(),
    includeNotes: includeNotes.toString(),
  });

  return useQuery(['patient-appointments', patientId], () =>
    client.get<string>(`/patient/${patientId}/appointments/pdf?${params}`).then(response => response.data),
  );
};

export type PatientFilteredQuery = {
  firstName?: string;
  lastName?: string;
  birthdate?: string;
  phone?: string;
  globalId?: string;
};

export type PatientsFilteredResults = {
  patients: Patient[];
  likelyOmitted: boolean;
};

// This is used on the patient search area of the schedule page
export const useGetFilteredPatients = () => {
  const { computeClient: client } = useApiClient();
  return useMutation<PatientsFilteredResults, AxiosError, PatientFilteredQuery>(request =>
    client.post('/patient/list', request).then(response => response.data),
  );
};

export const useUpdatePatient = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Patient, AxiosError, Patient>({
    mutationFn: request => {
      if ((request.isParentInvolved as any) === '') {
        request.isParentInvolved = null;
      }
      return client.put('/patient', request).then(response => response.data);
    },
    onSuccess: patient => queryClient.setQueryData(['patient', patientId], patient),
  });
};

export const useUpdatePatientSupplemental = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<PatientSupplemental, AxiosError, PatientSupplemental>({
    mutationFn: request => client.put('/patient/supplemental', request).then(response => response.data),
    onSuccess: patient => queryClient.setQueryData(['patient', 'supplemental', patientId], patient),
  });
};

export const useSetGlobalId = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<Patient, AxiosError, { globalId: string | null }>({
    mutationFn: request => client.put(`/patient/${patientId}/global-id`, request).then(response => response.data),
    onSuccess: patient => queryClient.setQueryData(['patient', patientId], patient),
  });
};

export const useGenerateGlobalId = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(
    [patientId, 'global-id'],
    () => client.post(`/patient/${patientId}/global-id`).then(response => response.data),
    {
      enabled: false,
    },
  );
};

export const useDeletePatient = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<string, AxiosError>({
    mutationFn: () => client.delete(`/patient/${patientId}`).then(response => response.data),
    onSuccess: async () => {
      queryClient.removeQueries(['patient', patientId]);
    },
  });
};

export type PatientStatus = 'intake' | 'active' | 'discharged' | 'closed';

export type PatientAdHocSearchFilter = {
  name?: string;
  phone?: string;
  birthDate?: string;
  statuses?: PatientStatus[]; // todo: checkboxes for this?
  lawFirmId?: string; // todo: typeahead selectbox for this?
  legalAgentId?: string; // todo: typeahead selectbox for this?
};

export type PatientSearchQueryRequest = {
  orderBy?: string;
  sortDirection?: string;
  filter: PatientAdHocSearchFilter;
};

export type Querystatus = 'QUEUED' | 'RUNNING' | 'SUCCEEDED' | 'FAILED' | 'CANCELLED' | 'CANCEL_REQUESTED';

export type PatientSearchMetadata = {
  oltpLastExportDate?: string;
};

export type PatientSerachResults = {
  queryExecutionId: string;
  nextToken: string | null;
  records: Patient[];
};

export const useGetPatientSearchMetadata = () => {
  const { computeClient } = useApiClient();
  return useQuery(['patientSearchMetadata'], () => computeClient.get('/patient/search').then(response => response.data));
};

export const usePatientApi = () => {
  const { computeClient } = useApiClient();

  const createPatient = useMutation<Patient, AxiosError, NewPatientRequest>((request: NewPatientRequest) => {
    if ((request.isParentInvolved as any) === '') {
      request.isParentInvolved = null;
    }
    return computeClient.post('/patient', request).then(response => response.data);
  });

  const createPatientSearchRequest = useMutation<string, AxiosError, PatientSearchQueryRequest>(
    (request: PatientSearchQueryRequest) => computeClient.post('/patient/search', request).then(response => response.data),
  );

  const getPatientSearchStatus = useMutation<string, AxiosError, string>((queryExecutionId: string) =>
    computeClient.get(`/patient/search/${queryExecutionId}/status`).then(response => response.data),
  );

  const getPatientSearchResults = useMutation<PatientSerachResults, AxiosError, string>((queryExecutionId: string) =>
    computeClient.get(`/patient/search/${queryExecutionId}/results`).then(response => response.data),
  );

  return { createPatient, createPatientSearchRequest, getPatientSearchStatus, getPatientSearchResults };
};
