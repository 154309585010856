import { LoadingAnimated } from 'app/components/LoadingAnimated';
import { PageContainer } from 'app/components/PageContainer';
import * as React from 'react';

export function KitchenSink() {
  return (
    <PageContainer title={`Kitchen Sink`} description="Assorted Components">
      Welcome to the Kitchen Sink
      <div className="divide-y divide-gray-200">
        <div className="mt-8">
          <span className="gray-900 italic">Loading Indicator:</span>
          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
            <div className="sm:col-span-12">
              <LoadingAnimated />
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}
