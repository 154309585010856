import React, { useState } from 'react';
import { FieldValues, useForm, ValidationRule } from 'react-hook-form';
import { PatientSupplemental, useUpdatePatientSupplemental } from '../../api/PatientApi';
import { useToast } from '../../../utils/toast';
import { ButtonRowV2 } from '../Forms/ButtonRowV2';
import { EnterForm } from '../Forms/EnterForm';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { SectionIntake } from './SectionIntake';
import { SectionInjury } from './SectionInjury';
import { DynamicSectionTreatment } from './DynamicSectionTreatment';
import { SectionDivider } from './SectionDivider';

export type EditPatientSupplementalFormProps = {
  defaultValues: FieldValues;
};

export const EditPatientSupplementalForm = ({ defaultValues }: EditPatientSupplementalFormProps) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    setValue,
    reset,
    watch,
    control,
    getValues,
  } = useForm({ shouldFocusError: true, defaultValues: defaultValues });
  const { toastError } = useToast();
  const onCancel = () => {
    reset();
  };
  const onSuccess = (patient: PatientSupplemental) => {};
  const onUnrecoverableError = (error: any) => toastError(error);

  const updateSupplemental = useUpdatePatientSupplemental(defaultValues.id);

  const onSubmit = async (data: any) => {
    const supplemental = await updateSupplemental.mutateAsync(data);
    onSuccess(supplemental);
  };

  const fieldArguments = (
    name: string,
    label: string,
    pattern?: ValidationRule<RegExp> | undefined,
    required?: string | ValidationRule<boolean> | undefined,
    setValueAs?: ((value: any) => any) | undefined,
  ) => {
    return { register, name, label, errors, clearErrors, setValue, pattern, required, setValueAs };
  };

  const [isDisabled, setIsDisabled] = useState(false);

  return (
    <EnterForm
      className="space-y-8 divide-y divide-gray-200"
      submitForm={handleSubmit(onSubmit)}
      setError={setError}
      onUnrecoverableError={onUnrecoverableError}
      setDisabled={setIsDisabled}
    >
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <SectionDivider text={'Intake Details'} className="m-4" />
          <SectionIntake fieldArguments={fieldArguments} watch={watch} setValue={setValue} />
          <SectionDivider text={'Injury'} className="m-4" />
          <SectionInjury fieldArguments={fieldArguments} watch={watch} setValue={setValue} />
          <SectionDivider text={'Treatments'} className="m-4" />
          <DynamicSectionTreatment
            register={register}
            watch={watch}
            setValue={setValue}
            control={control}
            errors={errors}
            clearErrors={clearErrors}
            getValues={getValues}
          />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <ButtonRowV2
            onCancel={onCancel}
            isDisabled={isDisabled}
            setIsDisabled={setIsDisabled}
            onUnrecoverableError={onUnrecoverableError}
            onCancelText={'Discard Changes'}
            onCancelIcon={faUndo}
          />
        </div>
      </div>
    </EnterForm>
  );
};
