import { createContext, useContext, useMemo } from 'react';
import React from 'react';
import toast, { useToaster } from 'react-hot-toast/headless';
import { NotificationFactory } from 'app/components/Notifications/NotificationFactory';
import { BuildNotificationMessage } from 'app/components/Notifications/Notification';
import throttle from 'lodash/throttle';

export interface IToastContext {
  toastSuccess: (main: JSX.Element | string, secondary?: JSX.Element | string) => void;
  toastError: (main: JSX.Element | string, secondary?: JSX.Element | string) => void;
  toastInfo: (main: JSX.Element | string, secondary?: JSX.Element | string) => void;
}

const ToastContext = createContext<IToastContext>({
  toastSuccess: () => {},
  toastError: () => {},
  toastInfo: () => {},
});

export function ToastProvider({ children }) {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;

  const toastSuccess = useMemo(
    () => (main: JSX.Element | string, secondary?: JSX.Element | string) =>
      toast.success(BuildNotificationMessage(main, secondary)),
    [],
  );

  const toastError = useMemo(
    () => (main: JSX.Element | string, secondary?: JSX.Element | string) =>
      toast.error(BuildNotificationMessage(main, secondary)),
    [],
  );

  const toastInfo = useMemo(
    () => (main: JSX.Element | string, secondary?: JSX.Element | string) => toast(BuildNotificationMessage(main, secondary)),
    [],
  );

  return (
    <ToastContext.Provider
      value={{
        toastSuccess: throttle(toastSuccess, 1000),
        toastError: throttle(toastError, 1000),
        toastInfo: throttle(toastInfo, 1000),
      }}
    >
      <>
        {children}

        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-toast"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end ">
            {toasts.map(toast =>
              NotificationFactory.build(toast.message as JSX.Element, toast.type, toast.id, startPause, endPause),
            )}
          </div>
        </div>
      </>
    </ToastContext.Provider>
  );
}

export const useToast = () => {
  return useContext(ToastContext);
};
