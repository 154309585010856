import React from 'react';
import classNames from 'classnames';

export const SectionDivider = ({ text, className }: { text?: string; className?: string }) => {
  return (
    <div className={classNames('relative', className)}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-white px-2 text-sm text-blue-500">{text}</span>
      </div>
    </div>
  );
};
