import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal } from './Modal';

export const Modaltip = ({
  closeModalText,
  width,
  children,
}: {
  closeModalText: string;
  width: 'sm:max-w-2xl' | 'md:max-w-2xl' | 'lg:max-w-2xl' | 'xl:max-w-2xl' | '2xl:max-w-2xl';
  children: JSX.Element;
}) => {
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false);

  return (
    <>
      <Modal width={width} closeModalText={closeModalText} open={infoModalIsOpen} closeModal={() => setInfoModalIsOpen(false)}>
        <>{children}</>
      </Modal>
      <button
        onClick={() => setInfoModalIsOpen(true)}
        type="button"
        className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <FontAwesomeIcon icon={faInfoCircle} className="-ml-0.5 h-5 w-5" aria-hidden="true" />
        More info
      </button>
    </>
  );
};
