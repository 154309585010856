import { Transition } from '@headlessui/react';
import { Modal } from 'app/components/Modal';
import React from 'react';
import { TemporaryNote } from 'app/api/TemporaryNoteApi';
import { useToast } from 'utils/toast';
import { TemporaryNoteForm } from './TemporaryNoteForm';

export type TemporaryNoteModalNewProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  time: string;
  date: string;
  targetId: string; // scheduleDefinitionId for instance
  defaultValues?: TemporaryNote;
};

export const TemporaryNoteModalNew = ({
  open,
  setOpen,
  time,
  date,
  targetId,
  defaultValues: _defaultValues,
}: TemporaryNoteModalNewProps) => {
  const { toastError } = useToast();

  const onSuccess = (temporaryNote: TemporaryNote) => {
    setOpen(false);
  };

  const onUnrecoverableError = (error: any) => {
    toastError('Error', error.message);
  };

  const defaultEndTime = (() => {
    const startHour = parseInt(time.split(':')[0]);
    const startMinute = parseInt(time.split(':')[1]);
    const startDateTime = new Date();
    startDateTime.setHours(startHour);
    startDateTime.setMinutes(startMinute);

    const minutes = 15;
    const endDateTime = new Date(startDateTime.getTime() + minutes * 60000);
    return `${endDateTime.getHours().toString().padStart(2, '0')}:${endDateTime.getMinutes().toString().padStart(2, '0')}`;
  })();

  const defaultValues = {
    ..._defaultValues,
    temporaryNoteType: 'ScheduleDefinition',
    noteDate: date,
    startTime: time,
    targetId,
  };
  if (!defaultValues.endTime) {
    defaultValues.endTime = defaultEndTime;
  }

  return (
    <Modal width="sm:max-w-7xl" closeModalText={'Cancel'} open={open} closeModal={() => setOpen(false)}>
      <>
        <Transition
          show={open}
          enter="transition duration-300 ease-out"
          enterFrom="transform scale-75 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-300 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-75 opacity-0"
        >
          <TemporaryNoteForm
            onSuccess={onSuccess}
            onUnrecoverableError={onUnrecoverableError}
            defaultValues={defaultValues}
            onCancel={() => setOpen(false)}
          />
        </Transition>
      </>
    </Modal>
  );
};
