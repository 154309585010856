import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import useApiClient from 'utils/apiClientHook';

export type SmsMessage = {
  patientId: string;
  appointmentDate: string | null;
  sentAt?: string;
  toNumber: string;
  body: string;
  errorMsg?: string;
  createdBy: string;
  status: string;
};

export const useSendSMSMessage = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<SmsMessage, AxiosError, SmsMessage>({
    mutationFn: request => client.post('/sms/message', request).then(response => response.data),
    onSuccess: appointment => {
      queryClient.refetchQueries(['sms-messages', patientId]);
    },
  });
};

export const useGetOneOffSMSMessages = (patientId: string) => {
  const { computeClient: client } = useApiClient();
  return useQuery(['sms-messages', patientId], () =>
    client.get<SmsMessage[]>(`/sms/patient/${patientId}`).then(response => response.data),
  );
};
