import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  FilterFn,
  ColumnFiltersState,
  getFilteredRowModel,
} from '@tanstack/react-table';
import { TreatmentCenter } from 'app/api/TreatmentCenterApi';
import { TreatmentCenterForm } from 'app/components/Auxillary/TreatmentCenterForm';
import { FormActionProps } from 'app/components/Forms/FormActionProps';
import { PagedItemList } from 'app/components/Forms/PagedItemList';
import React, { useMemo, useState } from 'react';
import { useFlexworxAnciallry } from 'utils/flexworx-ancillary';
import { rankItem } from '@tanstack/match-sorter-utils';
import { SimpleItemNewButton } from '../../components/Forms/SimpleItemNewButton';
import { SimpleItemEditModal } from '../../components/Forms/SimpleItemEditModal';

export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const TreatmentCenterList = ({
  onSelect,
  allowNew,
}: {
  onSelect?: (treatmentCenter: TreatmentCenter) => void;
  allowNew: boolean;
}) => {
  const { treatmentCenters, isError, isLoading } = useFlexworxAnciallry();

  const [sorting, setSorting] = useState<SortingState>([]);
  const columnHelper = createColumnHelper<TreatmentCenter>();

  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row.name, {
        id: 'name',
        cell: info => <div className="overflow-scroll">{info.getValue()}</div>,
        header: () => <span>Name</span>,
        enableSorting: true,
        sortingFn: 'text',
        filterFn: fuzzyFilter,
      }),
      columnHelper.accessor(row => row.phone, {
        id: 'phone',
        cell: info => <div className="w-24">{info.getValue()}</div>,
        header: () => <span>Phone</span>,
        enableSorting: true,
        sortingFn: 'text',
        enableColumnFilter: false,
      }),
      columnHelper.accessor(row => row.fax, {
        id: 'fax',
        cell: info => <div className="w-24">{info.getValue()}</div>,
        header: () => <span>Fax</span>,
        enableSorting: true,
        sortingFn: 'text',
        enableColumnFilter: false,
      }),
      columnHelper.accessor(row => row, {
        id: 'address',
        cell: info => {
          return (
            <>
              {info.getValue()?.addressLine1 && <div>{info.getValue()?.addressLine1}</div>}
              {info.getValue()?.addressLine2 && <div>{info.getValue()?.addressLine2}</div>}
            </>
          );
        },
        header: () => <span>Address</span>,
        enableSorting: false,
        enableColumnFilter: false,
      }),
      columnHelper.accessor(row => row.city, {
        id: 'city',
        cell: info => info.getValue(),
        header: () => <span>City</span>,
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor(row => row.state, {
        id: 'state',
        cell: info => info.getValue(),
        header: () => <span>State</span>,
        enableSorting: true,
        sortingFn: 'text',
      }),
      columnHelper.accessor(row => row.zip, {
        id: 'zip',
        cell: info => info.getValue(),
        header: () => <span>Zip</span>,
      }),
      columnHelper.accessor(row => row.notes, {
        id: 'notes',
        cell: info => info.getValue(),
        header: () => <span>Notes</span>,
        enableSorting: false,
        enableColumnFilter: false,
      }),
    ],
    [columnHelper],
  );

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);

  const table = useReactTable({
    data: treatmentCenters ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
      columnFilters,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
  });

  const createForm = (props: FormActionProps<TreatmentCenter>) => <TreatmentCenterForm {...props} />;
  const editForm = (item: TreatmentCenter, props: FormActionProps<TreatmentCenter>) => (
    <TreatmentCenterForm defaultValues={item} {...props} />
  );

  const [editModalIsVisible, setEditModalIsVisible] = useState(false);
  const [editModalItem, setEditModalItem] = useState<TreatmentCenter | null>(null);
  const onClick = (item: TreatmentCenter) => {
    if (onSelect) {
      onSelect(item);
      return;
    }
    setEditModalItem(item);
    setEditModalIsVisible(true);
  };

  return (
    <>
      {allowNew && (
        <SimpleItemNewButton
          children={createForm}
          headerSingular="Treatment Center"
          getName={(item: TreatmentCenter) => item.name}
        />
      )}

      <SimpleItemEditModal
        children={props => editForm(editModalItem!, props)}
        headerSingular="Treatment Center"
        getName={(item: TreatmentCenter) => item.name}
        closeModal={() => setEditModalIsVisible(false)}
        isVisible={editModalIsVisible}
      />

      <PagedItemList
        table={table}
        isError={isError}
        isLoading={isLoading}
        headerPlural="Treatment Center"
        onClick={onClick}
        wrapLines={true}
      />
    </>
  );
};
