import { format } from 'date-fns';
import { ChevronLeftIcon, ChevronRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import React, { Fragment } from 'react';
import { ScheduleHeaderProps } from './Header';
import { ScheduleDefinition } from '../../../api/ScheduleDefinitionApi';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';

const isValidDate = (dateToCheck: Date, schedule: ScheduleDefinition | null) => {
  if (!schedule) {
    return true;
  }
  return (
    (dateToCheck.getDay() === 0 && schedule.sunday.isOpen) ||
    (dateToCheck.getDay() === 1 && schedule.monday.isOpen) ||
    (dateToCheck.getDay() === 2 && schedule.tuesday.isOpen) ||
    (dateToCheck.getDay() === 3 && schedule.wednesday.isOpen) ||
    (dateToCheck.getDay() === 4 && schedule.thursday.isOpen) ||
    (dateToCheck.getDay() === 5 && schedule.friday.isOpen) ||
    (dateToCheck.getDay() === 6 && schedule.saturday.isOpen)
  );
};

const getNextDate = (date: Date, schedule: ScheduleDefinition | null) => {
  let dateToCheck = new Date(date);
  for (let i = 0; i < 7; i += 1) {
    dateToCheck = new Date(dateToCheck.getFullYear(), dateToCheck.getMonth(), dateToCheck.getDate() + 1);
    if (isValidDate(dateToCheck, schedule)) {
      return dateToCheck;
    }
  }
  return dateToCheck;
};

const getPreviousDate = (date: Date, schedule: ScheduleDefinition | null) => {
  let dateToCheck = new Date(date);
  for (let i = 0; i < 7; i += 1) {
    dateToCheck = new Date(dateToCheck.getFullYear(), dateToCheck.getMonth(), dateToCheck.getDate() - 1);
    if (isValidDate(dateToCheck, schedule)) {
      return dateToCheck;
    }
  }
  return dateToCheck;
};

export const parseDateString = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return new Date(Number(year), Number(month) - 1, Number(day));
};

export const DateNavigation = ({ scheduleDefinition, date, setDate, hideToday }: ScheduleHeaderProps) => {
  const today = format(new Date(), 'yyyy-MM-dd');
  const getSelectedDate = () => parseDateString(date);

  const goToNext = () => {
    const nextDate = getNextDate(getSelectedDate(), scheduleDefinition);
    setDate(format(nextDate, 'yyyy-MM-dd'));
  };

  const goToPrevious = () => {
    const previousDate = getPreviousDate(getSelectedDate(), scheduleDefinition);
    setDate(format(previousDate, 'yyyy-MM-dd'));
  };

  const goToToday = () => {
    setDate(today);
  };

  return (
    <>
      <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch">
        <div className="pointer-events-none absolute inset-0 rounded-md ring-1 ring-inset ring-gray-300" aria-hidden="true" />
        <button
          type="button"
          className="flex items-center justify-center rounded-l-md py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          onClick={goToPrevious}
        >
          <span className="sr-only">Previous</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        {!hideToday && (
          <button
            onClick={goToToday}
            type="button"
            className="hidden px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block"
          >
            Go to Today
          </button>
        )}
        <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
        <button
          type="button"
          className="flex items-center justify-center rounded-r-md py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          onClick={goToNext}
        >
          <span className="sr-only">Next</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <Menu as="div" className="relative ml-6 md:hidden">
        <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
          <span className="sr-only">Open menu</span>
          <EllipsisHorizontalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <>
                    {!hideToday && (
                      <button className="w-full text-left" onClick={goToToday}>
                        <div
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm',
                          )}
                        >
                          Go to today
                        </div>
                      </button>
                    )}
                  </>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};
