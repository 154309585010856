import React from 'react';
import { AppointmentSummary } from './AppointmentSummary';
import { Appointment, useDeleteAppointment } from 'app/api/AppointmentApi';
import { Bucket, ClosestBucket } from '../BucketOperations';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { TemporaryNote, useDeleteTemporaryNote } from 'app/api/TemporaryNoteApi';
import { TemporaryNoteSummary } from './TemporaryNoteSummary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { useCopyPaste } from 'utils/appointmentCopyPaste';
import { ClickableContextItem } from './ClickableContextItem';
import { Patient } from '../../../api/PatientApi';

export type ContextMenuLayoutProps = {
  selectedBucket: Bucket;
  nearestBucketForNewAppointment: ClosestBucket;
  onNewAppointmentClick: (time: string) => void;
  onEditAppointmentClick: (appointment: Appointment) => void;
  onNewNoteClick: (time: string) => void;
  onEditNoteClick: (note: TemporaryNote) => void;
  onPasteAppointment: (appointment: Appointment, patient: Patient) => void;
  onPasteNote: (note: TemporaryNote) => void;
  close: () => void;
};

export interface IContextMenuItem {}

class ContextMenuBreak implements IContextMenuItem {}

export class ContextMenuClickable implements IContextMenuItem {
  constructor(public description: string | JSX.Element, public onClick: () => void, public disabled: boolean) {}
}

export const ContextMenuLayout = ({
  selectedBucket,
  nearestBucketForNewAppointment,
  onNewAppointmentClick,
  onNewNoteClick,
  onEditAppointmentClick,
  onEditNoteClick,
  onPasteAppointment,
  onPasteNote,
  close,
}: ContextMenuLayoutProps) => {
  const newAppointmentTime = nearestBucketForNewAppointment.timeRepresentation.dateify();
  const newAppointmentTimeFormatted = format(newAppointmentTime, 'h:mm a');
  const deleteAppointment = useDeleteAppointment();
  const deleteTemporaryNote = useDeleteTemporaryNote();

  const bucketTimeFormatted = selectedBucket.formattedTime();

  const navigate = useNavigate();
  const copyPaste = useCopyPaste();

  const newAppointment = new ContextMenuClickable(
    `New Appointment at ${newAppointmentTimeFormatted}`,
    () => onNewAppointmentClick(format(newAppointmentTime!, 'HH:mm')),
    false,
  );

  const editAppointment = new ContextMenuClickable(
    'Edit this Appointment',
    () => onEditAppointmentClick(selectedBucket.asAppointment()),
    !selectedBucket.isAppointment(),
  );

  const newNote = new ContextMenuClickable(
    `New Note at ${bucketTimeFormatted}`,
    () => onNewNoteClick(selectedBucket.time()),
    false,
  );

  const editNote = new ContextMenuClickable(
    'Edit this Note',
    () => onEditNoteClick(selectedBucket.asTemporaryNote()),
    !selectedBucket.isTemporaryNote(),
  );

  const openInNewTab = (e: React.MouseEvent, url: string) => {
    e.stopPropagation();
    close();
    window.open(url, '_blank', 'noreferrer');
  };

  const goToPatient = new ContextMenuClickable(
    (
      <>
        Go to Patient{' '}
        <div
          onClick={e => openInNewTab(e, `/patients/${selectedBucket.asAppointment().patientId}`)}
          className="inline-block rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
        </div>
      </>
    ),
    () => navigate(`/patients/${selectedBucket.asAppointment().patientId}`),
    !selectedBucket.isAppointment(),
  );

  const copyAppointment = new ContextMenuClickable(
    'Copy Appointment',
    () => {
      const appointment = selectedBucket.asAppointment();
      copyPaste.copyAppointment(appointment);
      close();
    },
    !selectedBucket.isAppointment(),
  );
  const copyNote = new ContextMenuClickable(
    'Copy Note',
    () => {
      copyPaste.copyNote(selectedBucket.asTemporaryNote());
      close();
    },
    !selectedBucket.isTemporaryNote(),
  );

  const pasteAppointment = new ContextMenuClickable(
    'Paste Appointment',
    () => {
      const { appointment, patient } = copyPaste.pasteAppointment();
      onPasteAppointment(appointment, patient);
    },
    !copyPaste.appointmentInClipboard(),
  );

  const pasteNote = new ContextMenuClickable(
    'Paste Note',
    () => {
      const note = copyPaste.pasteNote();
      onPasteNote(note);
    },
    !copyPaste.noteInClipboard(),
  );

  const deleteAppointmentClickable = new ContextMenuClickable(
    'Delete Appointment',
    async () => {
      const appointment = selectedBucket.asAppointment();
      if (window.confirm('Are you sure? This cannot be undone.')) {
        await deleteAppointment.mutateAsync(appointment.id);
      }
      close();
    },
    !selectedBucket.isAppointment(),
  );

  const deleteTemporaryNoteClickable = new ContextMenuClickable(
    'Delete Note',
    async () => {
      const note = selectedBucket.asTemporaryNote();
      if (window.confirm('Are you sure? This cannot be undone.')) {
        await deleteTemporaryNote.mutateAsync(note.id);
      }
      close();
    },
    !selectedBucket.isTemporaryNote(),
  );

  const contextMenuItems: IContextMenuItem[] = [];
  contextMenuItems.push(goToPatient);
  contextMenuItems.push(new ContextMenuBreak());
  contextMenuItems.push(newAppointment);
  contextMenuItems.push(editAppointment);
  contextMenuItems.push(copyAppointment);
  contextMenuItems.push(pasteAppointment);
  contextMenuItems.push(new ContextMenuBreak());
  contextMenuItems.push(newNote);
  contextMenuItems.push(editNote);
  contextMenuItems.push(copyNote);
  contextMenuItems.push(pasteNote);
  contextMenuItems.push(new ContextMenuBreak());
  contextMenuItems.push(deleteAppointmentClickable);
  contextMenuItems.push(deleteTemporaryNoteClickable);

  const isClickable = (item: IContextMenuItem) => item instanceof ContextMenuClickable;

  return (
    <div className="flex ">
      <div className={'w-64'}>
        {contextMenuItems.map((item, index) => (
          <div key={index}>
            {!isClickable(item) && <div className="border-t-2 border-gray-200" />}
            {isClickable(item) && <ClickableContextItem item={item} />}
          </div>
        ))}
      </div>

      {selectedBucket.isAppointment() && (
        <div className="border-l-2 px-4 pb-8 bg-gray-50 w-full">
          <div>
            <AppointmentSummary appointment={selectedBucket.asAppointment()} />
          </div>
        </div>
      )}
      {selectedBucket.isTemporaryNote() && (
        <div className="border-l-2 px-4 pb-8 bg-gray-50 w-full">
          <div>
            <TemporaryNoteSummary temporaryNote={selectedBucket.asTemporaryNote()} />
          </div>
        </div>
      )}
    </div>
  );
};
