import React, { forwardRef, useEffect } from 'react';
import { GenericInputGroupProps, InputGroupProps } from '../InputGroup';
import { LawFirm } from '../../../api/LawFirmApi';
import { useFlexworxAnciallry } from '../../../../utils/flexworx-ancillary';
import { Modal } from '../../Modal';
import { LawFirmList } from '../../../pages/AncillaryDataPage/LawFirmList';
import { LawFirmInputTextField } from './LawFirmInputTextField';

export type LawFirmInputGroupProps = GenericInputGroupProps<HTMLInputElement, string> & {
  defaultModel?: LawFirm;
  lawOfficeId: string | null;
  attorneyId: string | null;
  paralegalId: string | null;
};

export const LawFirmInputGroup = forwardRef<HTMLInputElement, LawFirmInputGroupProps>(
  (
    {
      label,
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      tooltip,
      defaultModel,
      lawOfficeId,
      attorneyId,
      paralegalId,
    }: LawFirmInputGroupProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const args: InputGroupProps = {
      label,
      type: 'text',
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      tooltip,
      placeholder: '',
    };

    const [model, setModel] = React.useState<LawFirm | null>(defaultModel ?? null);
    const [showSelectionModal, setShowSelectionModal] = React.useState<boolean>(false);

    const onSelection = (lawFirm: LawFirm) => {
      clearErrors();
      setModel(lawFirm);
      setValue(lawFirm.id);
      setShowSelectionModal(false);
    };

    const clear = () => {
      setValue('');
      setModel(null);
    };

    const { lawFirmFromId, lawOfficeFromId, attorneysFromIds, paralegalsFromIds } = useFlexworxAnciallry();

    // watch the lawOfficeId
    useEffect(() => {
      if (!lawOfficeId) {
        return;
      }
      const lawOffice = lawOfficeFromId(lawOfficeId);
      if (!lawOffice) {
        return;
      }
      const lawFirm = lawFirmFromId(lawOffice.lawFirmId);
      if (!lawFirm) {
        return;
      }
      onSelection(lawFirm);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lawOfficeId]);

    // watch the attorneyId
    useEffect(() => {
      if (!attorneyId) {
        return;
      }
      const attorney = attorneysFromIds([attorneyId])[0];
      if (!attorney) {
        return;
      }
      const lawOffice = lawOfficeFromId(attorney.lawOfficeId);
      if (!lawOffice) {
        return;
      }
      const lawFirm = lawFirmFromId(lawOffice.lawFirmId);
      if (!lawFirm) {
        return;
      }
      onSelection(lawFirm);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attorneyId]);

    // watch the paralegalId
    useEffect(() => {
      if (!paralegalId) {
        return;
      }
      const paralegal = paralegalsFromIds([paralegalId])[0];
      if (!paralegal) {
        return;
      }
      const lawOffice = lawOfficeFromId(paralegal.lawOfficeId);
      if (!lawOffice) {
        return;
      }
      const lawFirm = lawFirmFromId(lawOffice.lawFirmId);
      if (!lawFirm) {
        return;
      }
      onSelection(lawFirm);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paralegalId]);

    return (
      <>
        <Modal
          width="sm:max-w-7xl"
          open={showSelectionModal}
          closeModal={() => setShowSelectionModal(false)}
          closeModalText="Cancel"
        >
          <LawFirmList onSelect={onSelection} allowNew={false} />
        </Modal>

        <div className="flex flex-col">
          <div className="w-full">
            <LawFirmInputTextField
              {...args}
              ref={ref}
              model={model}
              onClear={clear}
              onClick={() => setShowSelectionModal(true)}
            />
          </div>
        </div>
      </>
    );
  },
);
