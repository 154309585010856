import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export const AlertBox = ({
  primaryText,
  secondaryText,
  secondaryLinkPath,
  className,
}: {
  primaryText: string;
  secondaryText: string;
  secondaryLinkPath: string;
  className?: string;
}) => {
  return (
    <div
      className={classNames(className, 'bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md')}
      role="alert"
    >
      <div className="flex">
        <div className="pr-8 pl-4 pt-2">
          <FontAwesomeIcon className="h-8 w-8 text-red-500" icon={faTriangleExclamation} aria-hidden="true" />
        </div>
        <div>
          <p className="font-bold">{primaryText}</p>
          {secondaryLinkPath && (
            <p className="text-sm">
              <Link to={secondaryLinkPath} className="text-red-500 underline">
                {secondaryText}
              </Link>
            </p>
          )}
          {!secondaryLinkPath && <p className="text-sm">{secondaryText}</p>}
        </div>
      </div>
    </div>
  );
};
