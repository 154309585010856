import { forwardRef, Fragment, useId, useMemo } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import React from 'react';
import classNames from 'classnames';
import { Option } from './Data';
import { GenericInputGroupProps } from './InputGroup';
import { InfoTip } from '../Tooltip';

export type ListBoxGroupV2Props = GenericInputGroupProps<HTMLButtonElement, string> & {
  options: Option<string | null>[];
  value: string | null;
  initialValueOnly?: boolean;
  absolutePositioning?: boolean;
  tooltip?: string | React.ReactNode;
  hideErrorMessages?: boolean; // This is if you want the field box to turn red, etc, but not show the error messages.
  customSort?: (options: Option<string | null>[]) => Option<string | null>[];
};

export type ListBoxGroupV2RenderProps = GenericInputGroupProps<HTMLButtonElement, string> & {
  options: Option<string | null>[];
  value: string | null;
  selected: Option<string | null>;
  setSelected: (value: Option<string>) => void;
  absolutePositioning?: boolean;
  tooltip?: string | React.ReactNode;
  hideErrorMessages?: boolean; // This is if you want the field box to turn red, etc, but not show the error messages.
};

const sortOptions = (options: Option<string | null>[]) => {
  return options.sort((a, b) => {
    if (a.value === null || a.value === undefined) {
      return -1;
    }
    if (b.value === null || b.value === undefined) {
      return 1;
    }
    if (a.value === 'not-specified') {
      return -1;
    }
    if (b.value === 'not-specified') {
      return 1;
    }
    return a.description.localeCompare(b.description);
  });
};

export const ListBoxGroupV2 = forwardRef<HTMLButtonElement, ListBoxGroupV2Props>(
  (props: ListBoxGroupV2Props, ref: React.Ref<HTMLButtonElement>) =>
    props.initialValueOnly ? (
      <ListBoxGroupOneWay
        {...props}
        options={props.customSort ? props.customSort(props.options) : sortOptions(props.options)}
        ref={ref}
      />
    ) : (
      <ListBoxGroupTwoWay
        {...props}
        options={props.customSort ? props.customSort(props.options) : sortOptions(props.options)}
        ref={ref}
      />
    ),
);

// This is a listbox that can be updated from the dropdown or from the parent.
// If the parent value changes, this updates the dropdown.
const ListBoxGroupTwoWay = forwardRef<HTMLButtonElement, ListBoxGroupV2Props>(
  (props: ListBoxGroupV2Props, ref: React.Ref<HTMLButtonElement>) => {
    const setSelected = (val: Option<string>) => props.setValue(val.value!);
    const selected = props.options.find(x => x.value === props.value) || props.options[0];
    return <ListBoxGroupV2Render ref={ref} {...props} setSelected={setSelected} selected={selected} />;
  },
);

// This is a listbox that can only be updated from the dropdown.
// If the parent value changes, this does not update the dropdown.
const ListBoxGroupOneWay = forwardRef<HTMLButtonElement, ListBoxGroupV2Props>(
  (props: ListBoxGroupV2Props, ref: React.Ref<HTMLButtonElement>) => {
    const [selected, _setSelected] = React.useState<Option<string | null>>(
      props.options.find(x => x.value === props.value) || props.options[0],
    );
    const setSelected = (val: Option<string>) => {
      _setSelected(val);
      props.setValue(val.value!);
    };

    return <ListBoxGroupV2Render ref={ref} {...props} setSelected={setSelected} selected={selected} />;
  },
);

const ListBoxGroupV2Render = forwardRef<HTMLButtonElement, ListBoxGroupV2RenderProps>(
  (
    {
      options,
      label,
      clearErrors,
      setSelected: _setSelected,
      errors,
      selected,
      absolutePositioning,
      name,
      tooltip,
      hideErrorMessages,
    }: ListBoxGroupV2RenderProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const getClass = () => {
      const baseClass =
        'relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm';

      const classes = [baseClass];

      if (errors) {
        classes.push('border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500');
      }

      if (!selected || selected?.value === null || selected?.value === undefined) {
        classes.push('text-gray-400');
      }

      return classNames(...classes);
    };
    const id = useId();
    const setSelected = (val: Option<string>) => {
      clearErrors();
      _setSelected(val);
    };

    const iterableErrorMessages = useMemo(() => {
      if (errors?.message) {
        if (Array.isArray(errors.message)) {
          return errors.message;
        }
        return [errors.message];
      }
      return [];
    }, [errors]);

    return (
      <span id={name} ref={ref} className="w-full">
        <Listbox value={selected} onChange={setSelected}>
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                {label}
                {tooltip && <InfoTip content={tooltip} />}
              </Listbox.Label>
              <div className="relative">
                <Listbox.Button ref={ref} className={getClass()}>
                  <span className="block truncate">{selected?.description}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className={classNames(
                      'mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                      absolutePositioning ? 'absolute z-10' : '',
                    )}
                  >
                    {' '}
                    {options.map(option => (
                      <Listbox.Option
                        key={option.key}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-8 pr-4',
                          )
                        }
                        value={option}
                      >
                        {({ selected, active }) => (
                          <>
                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                              {option.description}
                            </span>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        {!hideErrorMessages && (
          <>
            {iterableErrorMessages.map((message, index) => (
              <p key={index} className="mt-2 text-sm text-red-600" id={`${id}-error`}>
                {message}
              </p>
            ))}
          </>
        )}
      </span>
    );
  },
);
