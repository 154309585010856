import {
  SortingState,
  Table,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Patient } from 'app/api/PatientApi';
import { createContext, useContext, useMemo, useState } from 'react';
import React from 'react';
import { useFlexworxAnciallry } from '../../../utils/flexworx-ancillary';
import { useFlexworxConfig } from '../../../utils/flexworx-config';

type PatientSearchResult = Patient & {
  lawFirm?: string;
  clinicLocation?: string;
};

export interface IPatientListTableContext {
  table: Table<PatientSearchResult>;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  patientCount: number;
  setPatients: (patients: Patient[]) => void;
}

const PatientListTableContext = createContext<IPatientListTableContext>({
  table: {} as Table<PatientSearchResult>,
  isLoading: false,
  setIsLoading: () => {},
  patientCount: 0,
  setPatients: () => {},
});

export function PatientListTableProvider({ children }: { children: React.ReactNode }) {
  const [patients, setPatients] = useState<Patient[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([]);

  const { lawFirmFromId, lawFirms } = useFlexworxAnciallry();
  const { clinicLocationFromId, clinicLocations } = useFlexworxConfig();

  const patientSearchResults: PatientSearchResult[] = useMemo(() => {
    return patients.map(patient => {
      return {
        ...patient,
        lawFirm: lawFirmFromId(patient.lawFirmId)?.name,
        clinicLocation: clinicLocationFromId(patient.clinicLocationId)?.name,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patients, lawFirms, clinicLocations]);

  const columnHelper = createColumnHelper<PatientSearchResult>();
  const columns = useMemo(
    () => [
      columnHelper.accessor(row => row.displayLongReverse, {
        id: 'patientDisplayLongReverse',
        cell: info => info.getValue(),
        header: () => <span>Name</span>,
      }),
      columnHelper.accessor(row => row.birthDate, {
        id: 'birthDate',
        cell: info => info.getValue(),
        header: () => <span>Birth Date</span>,
      }),
      columnHelper.accessor(row => row.phone1, {
        id: 'phone1',
        cell: info => info.getValue(),
        header: () => <span>Phone</span>,
      }),
      columnHelper.accessor(row => row.clinicLocation, {
        id: 'clinicLocation',
        cell: info => info.getValue(),
        header: () => <span>Clinic Location</span>,
      }),
      columnHelper.accessor(row => row.lawFirm, {
        id: 'lawFirm',
        cell: info => info.getValue(),
        header: () => <span>Law Firm</span>,
      }),
    ],
    [columnHelper],
  );

  const table = useReactTable({
    data: patientSearchResults ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <PatientListTableContext.Provider
      value={{
        table,
        patientCount: patients.length,
        isLoading,
        setIsLoading,
        setPatients,
      }}
    >
      {children}
    </PatientListTableContext.Provider>
  );
}

export const usePatientListTableProvider = () => {
  return useContext(PatientListTableContext);
};
