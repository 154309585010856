import React from 'react';
import { SimpleItemList, SimpleItemListParams } from './SimpleItemList';

export type PagedItemListParams<T> = SimpleItemListParams<T> & {};

export function PagedItemList<T>({ table, headerPlural, isLoading, isError, onClick, wrapLines }: PagedItemListParams<T>) {
  // todo: Add pagination
  return (
    <SimpleItemList
      table={table}
      isLoading={isLoading}
      isError={isError}
      headerPlural={headerPlural}
      onClick={onClick}
      wrapLines={wrapLines}
    />
  );
}
