import { NavBar } from 'app/components/NavBar';
import { NavBarLinksProvider } from 'app/components/NavBar/navBarLinks';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { Footer } from 'app/components/Footer';
import { AppRoutes } from 'app/components/AppRoutes';

export const MainLayout = () => {
  return (
    <div className="">
      <div className="h-screen flex flex-col">
        <Router>
          <NavBarLinksProvider>
            <div className="top-0 z-navbar w-full px-4 flex-none h-navbar">
              <NavBar />
            </div>
            <div className="min-h-0 flex-1 flex flex-col">
              <Helmet titleTemplate="%s - FlexWorx" defaultTitle="FlexWorx">
                <meta name="description" content="A FlexWorx Health application" />
              </Helmet>
              <div className="min-h-0 flex-1 overflow-y-auto top-navbar">
                <AppRoutes />
                <div className="h-16">
                  <Footer />
                </div>
              </div>
            </div>
          </NavBarLinksProvider>
        </Router>
      </div>
    </div>
  );
};
