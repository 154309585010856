import React from 'react';
import { InboxWithNumber } from './InboxWithNumber';
import { useScheduleQueueContext } from 'utils/scheduleQueueHook';
import { useTimeSlotOverflowApprovalContext } from 'utils/timeslotOverflowApprovalHook';

export const TaskQueueIcon = () => {
  const { initialAppointmentTasks } = useScheduleQueueContext();
  const { items: timeslotOverflowARequests } = useTimeSlotOverflowApprovalContext();
  return <InboxWithNumber number={(initialAppointmentTasks?.length ?? 0) + (timeslotOverflowARequests?.length ?? 0)} />;
};
