import React, { forwardRef, useEffect } from 'react';
import { GenericInputGroupProps, InputGroupProps } from '../InputGroup';
import { LawOffice } from '../../../api/LawOfficeApi';
import { LawOfficeInputTextField } from './LawOfficeInputTextField';
import { useFlexworxAnciallry } from '../../../../utils/flexworx-ancillary';
import { Modal } from '../../Modal';
import { LawOfficeList } from '../../../pages/AncillaryDataPage/LawOfficeList';

export type LawOfficeInputGroupProps = GenericInputGroupProps<HTMLInputElement, string> & {
  defaultModel?: LawOffice;
  lawFirmId: string | null;
  attorneyId: string | null;
  paralegalId: string | null;
};

export const LawOfficeInputGroup = forwardRef<HTMLInputElement, LawOfficeInputGroupProps>(
  (
    {
      label,
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      tooltip,
      defaultModel,
      paralegalId,
      lawFirmId,
      attorneyId,
    }: LawOfficeInputGroupProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const args: InputGroupProps = {
      label,
      type: 'text',
      name,
      errors,
      onBlur,
      clearErrors,
      setValue,
      tooltip,
      placeholder: '',
    };

    const [model, setModel] = React.useState<LawOffice | null>(defaultModel ?? null);
    const [showSelectionModal, setShowSelectionModal] = React.useState<boolean>(false);

    const onSelection = (lawOffice: LawOffice) => {
      clearErrors();
      setModel(lawOffice);
      setValue(lawOffice.id);
      setShowSelectionModal(false);
    };

    const clear = () => {
      setValue('');
      setModel(null);
    };

    const { lawFirmFromId, lawOfficeFromId, attorneysFromIds, paralegalsFromIds, lawOffices } = useFlexworxAnciallry();

    // watch the lawFirmId
    useEffect(() => {
      if (!lawFirmId) {
        return;
      }
      // we will only clear the value if applicable, not set it.
      const lawFirm = lawFirmFromId(lawFirmId);
      if (!lawFirm) {
        return;
      }
      if (!lawOffices) {
        return;
      }
      const allowedLawOffices = lawOffices.filter(x => x.lawFirmId === lawFirmId);
      if (allowedLawOffices.length === 0) {
        clear();
      }
      if (allowedLawOffices.length === 1) {
        onSelection(allowedLawOffices[0]);
      }
      return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lawFirmId]);

    // watch the attorneyId
    useEffect(() => {
      if (!attorneyId) {
        return;
      }
      const attorney = attorneysFromIds([attorneyId])[0];
      if (!attorney) {
        return;
      }
      const lawOffice = lawOfficeFromId(attorney.lawOfficeId);
      if (!lawOffice) {
        clear();
        return;
      }
      onSelection(lawOffice);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attorneyId]);

    // watch the paralegalId
    useEffect(() => {
      if (!paralegalId) {
        return;
      }
      const paralegal = paralegalsFromIds([paralegalId])[0];
      if (!paralegal) {
        return;
      }
      const lawOffice = lawOfficeFromId(paralegal.lawOfficeId);
      if (!lawOffice) {
        clear();
        return;
      }
      onSelection(lawOffice);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paralegalId]);

    return (
      <>
        <Modal
          width="sm:max-w-7xl"
          open={showSelectionModal}
          closeModal={() => setShowSelectionModal(false)}
          closeModalText="Cancel"
        >
          <LawOfficeList onSelect={onSelection} allowNew={false} initialLawFirmId={lawFirmId} />
        </Modal>

        <div className="flex flex-col">
          <div className="w-full">
            <LawOfficeInputTextField
              {...args}
              ref={ref}
              model={model}
              onClear={clear}
              onClick={() => setShowSelectionModal(true)}
            />
          </div>
        </div>
      </>
    );
  },
);
